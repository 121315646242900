import { Directive, Input, HostListener, OnInit, ViewContainerRef, TemplateRef, EmbeddedViewRef, ElementRef, Renderer2, OnDestroy } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';


@UntilDestroy()
@Directive({
	selector: 'img[onErrorTemplate]'
})
export class OnErrorTemplateDirective implements OnInit, OnDestroy {

	@Input('onErrorTemplate') set errorTemplate(templateRef: TemplateRef<any> | null) {
		this._errorTemplate = templateRef;
	}

	@Input() set resetOnEmptyValue(value: any) {
		if (!value) {
			this._imageLoadErrorSource$.next(false);
		}
	}

	private _elementDisplayStyle: string;
	private _errorTemplate: TemplateRef<any> | null;
	private _imageLoadErrorSource$ = new Subject<boolean>();
	private _errorTemplateRef: EmbeddedViewRef<any> | null = null;
	private _defaultRef: any;

	@HostListener('error')
	onLoadError() {
		this._imageLoadErrorSource$.next(true);
	}

	@HostListener('load')
	onLoad() {
		this._imageLoadErrorSource$.next(false);
	}

	constructor(
		private _viewContainer: ViewContainerRef,
		private _elementRef: ElementRef,
		private _renderer: Renderer2
	) {
	}

	ngOnInit() {
		this._elementDisplayStyle = (this._elementRef.nativeElement as HTMLElement).style.display;
		this._imageLoadErrorSource$.pipe(
			untilDestroyed(this)
		).subscribe(x => x ? this.showError() : this.hideError());
	}


	ngOnDestroy(): void {
		this.hideError();
	}

	private showError() {
		(this._elementRef.nativeElement as HTMLElement).style.display = 'none';
		if (this._errorTemplate) {
			if (!this._errorTemplateRef) {
				this._errorTemplateRef = this._viewContainer.createEmbeddedView(this._errorTemplate);
			}
		} else {
			this.renderDefault();
		}
	}

	private hideError() {
		(this._elementRef.nativeElement as HTMLElement).style.display = this._elementDisplayStyle;
		if (this._errorTemplateRef) {
			this._errorTemplateRef.destroy();
			this._errorTemplate = null;
		}
		if (this._defaultRef) {
			this._renderer.removeChild((this._elementRef.nativeElement as HTMLElement).parentNode, this._defaultRef);
		}

	}

	private renderDefault() {
		this._defaultRef = this._renderer.createElement('p');
		const text = this._renderer.createText('N/A');
		this._renderer.appendChild(this._defaultRef, text);
		this._renderer.appendChild(this._elementRef.nativeElement.parentNode, this._defaultRef);
	}
}
