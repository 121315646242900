import { Directive, Input, HostListener, OnInit, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, concat, of, merge, iif } from 'rxjs';
import { map, switchMap, take, skip, delay } from 'rxjs/operators';

import { CustomTooltipTemplateComponent } from '../components/custom-tooltip-template/custom-tooltip-template.component';

@UntilDestroy()
@Directive({
    selector: '[appCustomTooltip]'
})
export class CustomTooltipDirective implements OnInit {

    @Input('appCustomTooltip') text: string;
    @Input() timeout: number;

    private ref: ComponentRef<CustomTooltipTemplateComponent>;
    private readonly clickSource$ = new Subject<MouseEvent>();
    private readonly documentClickSource$ = new Subject<MouseEvent>();

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        this.clickSource$.next(event);
    }

    @HostListener('document:click', ['$event.target'])
    onDocumentClick(event: MouseEvent) {
        this.documentClickSource$.next(event);
    }

    private get position(): 'top' | 'right' | 'bottom' | 'left' {
        return 'right';
    }

    constructor(
        private viewContainerRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
    ) {
    }

    ngOnInit(): void {
        this.clickSource$.pipe(
            untilDestroyed(this),
            switchMap(() =>
                concat(
                    of(true),
                    iif(() => this.timeout > 0,
                        merge(of(false).pipe(delay(this.timeout)), this.documentClickSource$.pipe(skip(1))),
                        this.documentClickSource$.pipe(skip(1))
                    ).pipe(
                        take(1),
                        map(() => false)
                    )
                )
            )
        ).subscribe(isShow => isShow ? this.showTooltip() : this.hideTooltip());
    }

    private showTooltip() {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(CustomTooltipTemplateComponent);
            this.ref = this.viewContainerRef.createComponent(factory);
            this.ref.instance.position = this.position;
            this.ref.instance.text = this.text;
        } else {
            this.ref.instance.visible = true;
        }
    }

    private hideTooltip() {
        this.ref.instance.visible = false;
    }
}
