import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { notEmptyTextValidator } from '@shared/helpers/validators';
import { FormGroupConfig } from '@shared/models';

interface FormModel {
    text: string;
}

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'text-item',
    templateUrl: './text-item.component.html',
    styleUrls: ['./text-item.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextItemComponent),
            multi: true
        }
    ]
})
export class TextItemComponent implements ControlValueAccessor, OnInit {
    @Input() disabled = false;
    @Input() propertyToEdit: string;
    @Input() showDeleteButton = true;
    @Input() maxLength: number;
    @Input() hideValidation: boolean = false;
    @Input() isRequired: boolean = false;

    @Output() deleteClick: EventEmitter<any> = new EventEmitter();
    @Output() editMode: EventEmitter<boolean> = new EventEmitter();

    isEditMode: boolean = false;
    form: FormGroup;

    private savedText: string;
    private model: any;

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    //#region ControlValueAccessor

    writeValue(value: string | any) {
        if (typeof value === 'string') {
            this.patchValue(value);
        } else {
            if (!this.propertyToEdit) throw new Error(('Missed "propertyToEdit" input property'));
            this.patchValue(value ? value[this.propertyToEdit] : null);
            this.model = value;
        }

        this.savedText = this.form.get('text').value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    //#endregion

    onSaveClick() {
        this.savedText = this.form.value.text;
        this.isEditMode = false;
        this.editMode.emit(false);

        if (this.model) {
            this.model[this.propertyToEdit] = this.savedText;
            this.onChange(this.model);
        } else {
            this.onChange(this.savedText);
        }
    }

    onCancelClick() {
        this.patchValue(this.savedText);
        this.isEditMode = false;
        this.editMode.emit(false);
    }

    onEditClick() {
        this.isEditMode = true;
        this.editMode.emit(true);
    }

    onDeleteClick() {
        this.deleteClick.emit();
    }

    private initForm() {
        const validators: Array<ValidatorFn> = [];
        if (this.maxLength) {
            validators.push(Validators.maxLength(this.maxLength));
        }
        if (this.isRequired) {
            validators.push(Validators.required, notEmptyTextValidator);
        }

        const config: FormGroupConfig<FormModel> = {
            text: ['', validators]
        };
        this.form = this.formBuilder.group(config);
    }

    patchValue(text: string) {
        this.form.patchValue({ text });
    }
}
