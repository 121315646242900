export class ApiResult {
	ResultCode: WebApiResultCodes;
	ResultCodeName: string;
	ErrorMessage: string;
	ReturnValue: any;
}

export enum WebApiResultCodes {
	Success = 0,
	Exception = 1,
	EntityNotFound = 2,
	ArgumentValidationFailed = 3,
	FakeException = 99
}
