import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as UserAdmin from '../models/user-admin';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

//TODO: TEMP MOQ
import MoqData from 'src/moq';

@Injectable({
    providedIn: 'root'
})
export class UserAdminApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    getUsers(searchString?: string): Observable<UserAdmin.UserAdminListModel> {
        const args: UserAdmin.UserListAdminArgs = { Search: searchString ?? '' };
        return of(MoqData['GetUsers']).pipe(
            delay(300)
        );
        return this.http.get<UserAdmin.UserAdminListModel>(`api/UserAdmin/GetUsers?${this.encodeQueryData(args)}`);
    }

    getEdit(id: number): Observable<UserAdmin.UserEditModel> {
        return of(MoqData['GetEdit'][id.toString()]).pipe(
            delay(300)
        );
        return this.http.get<UserAdmin.UserEditModel>(`api/UserAdmin/Edit?id=${id}`);
    }

    edit(args: UserAdmin.UserEditModel): Observable<number> {
        return of(args.Id).pipe(delay(300));
        return this.http.post<number>('api/UserAdmin/Edit', args);
    }

    changePassword(args: UserAdmin.ChangePasswordModel): Observable<any> {
        return of(true).pipe(delay(300));
        return this.http.post<any>('api/UserAdmin/ChangePassword', args);
    }

    activate(id: number): Observable<any> {
        return of(true).pipe(delay(300));
        return this.http.post<any>(`api/UserAdmin/Activate?id=${id}`, null);
    }

    delete(id: number): Observable<any> {
        return of(true).pipe(delay(300));
        return this.http.post<any>(`api/UserAdmin/Delete?id=${id}`, null);
    }
}
