import { Injectable } from '@angular/core';
import { Inject, Optional } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie-service';
import { interval, Observable } from 'rxjs';
import { delay, distinctUntilChanged, map } from 'rxjs/operators';

import { AuthTokenStorageOptions, AUTH_TOKEN_STORAGE_OPTIONS } from '../models';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AuthTokenStorageService {

    get authTokenStorageKey(): string {
        return this._authTokenStorageKey;
    }

    authToken$: Observable<string>;

    private _tick$ = interval(1000);
    private _authTokenStorageKey: string = 'authorization-token';

    constructor(
        private readonly cookieService: CookieService,
        @Optional() @Inject(AUTH_TOKEN_STORAGE_OPTIONS) options: AuthTokenStorageOptions
    ) {
        if (options?.tokenStorageKey) {
            this._authTokenStorageKey = options?.tokenStorageKey;
        }

        this.authToken$ = this._tick$.pipe(
            delay(1000),
            map(() => this.getAuthToken()),
            distinctUntilChanged(),
        );
    }

    hasAuthToken(): boolean {
        return this.cookieService.check(this._authTokenStorageKey);
    }

    getAuthToken(): string {
        let token = this.cookieService.get(this._authTokenStorageKey);
        return token;
    }

    setAuthToken(token: string, expires: Date): void {
        this.cookieService.set(this._authTokenStorageKey, token, {
            expires,
            path: '/',
        });
    }

    removeAuthToken(): void {
        this.cookieService.delete(this._authTokenStorageKey);
        this.cookieService.delete(this._authTokenStorageKey, '/');
    }
}
