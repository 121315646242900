import { Injectable } from "@angular/core";

import { IntlService } from "@progress/kendo-angular-intl";

import { DateFormatterService } from "../models/data-formatter-service";

@Injectable({ providedIn: 'root' })
export class KendoIntlDateFormatterService implements DateFormatterService {
    constructor(
        private readonly intlService: IntlService
    ) { }

    format(date: Date, template?: string, locale?: any): string {
        return template ? this.intlService.formatDate(date, template, locale) : this.intlService.formatDate(date, null, locale);
    }
}
