export enum CustomToolbarTypes {
    CurrentPage = 'custom-toolbar__current-page',
    Fullscreen = 'custom-toolbar__fullscreen',
    Home = 'custom-toolbar__home',
    NextPage = 'custom-toolbar__next-page',
    Page = 'custom-toolbar__page',
    PreviousPage = 'custom-toolbar__previous-page',
    SeparatedWindow = 'custom-toolbar__separated-window',
    Spacer = 'custom-toolbar__spacer',
    TotalPages = 'custom-toolbar__total-pages',
}
