export function compare(a: Date, b: Date, ignoreTime: boolean = true): number {
    let from = new Date(a);
    let to = new Date(b);

    if (ignoreTime) {
        from.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
    }

    if (from > to) return 1;
    if (from < to) return -1;

    return 0;
}
