import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DialogRef } from '@progress/kendo-angular-dialog';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { GetPatientInformationModelItem } from '@api/models/annotation';

import { AuthFeature, AuthStateService, SharedFeature, SharedStateService } from '@shared/store';
import { LoggedUser } from '@shared/models';
import { ModalService } from '@shared/services';

import { CognitiveSearchRootPageName } from 'src/app/modules/cognitive-search/constants';
import { EditProfileDialogComponent } from '../edit-profile-dialog/edit-profile-dialog.component';
import { SearchesStateService } from 'src/app/modules/cognitive-search/store';

interface DropdownItemModel {
    id: number;
    text: string;
    path?: string;
    disabled?: boolean;
}

interface NavMenuItemModel {
    id: number;
    text: string;
    path?: string;
    items?: Array<NavMenuItemModel>;
    data?: any;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

    readonly dropdownItems: Array<DropdownItemModel> = [
        { id: 1, text: 'Edit Profile', disabled: true },
        { id: 1, text: 'Administration', path: 'administration', disabled: true },
        { id: 2, text: 'Logout' }
    ];

    readonly menuItems: Array<NavMenuItemModel> = [
        {
            id: 1,
            text: 'Overview',
            path: ''
        },
        {
            id: 2,
            text: 'Modules',
            items: [
                {
                    id: 21,
                    text: 'Cognitive Search',
                    path: 'search/cognitive'
                },
                {
                    id: 22,
                    text: 'PubMed Search',
                    path: 'search/pub-med'
                }
            ]
        }
    ];

    private editProfileModalRef: DialogRef;

    @Select(AuthStateService.user) user$: Observable<LoggedUser>;
    @Select(SharedStateService.isPageActive(CognitiveSearchRootPageName)) isCognitiveSearchRootActive$: Observable<boolean>;
    @Select(SearchesStateService.loadedDocumentId) loadedDocumentId$: Observable<string>;
    @Select(SearchesStateService.loadedPatientInformation) loadedPatientInformation$: Observable<Array<GetPatientInformationModelItem>>;
    @Select(SharedStateService.isSideMenuTogglerVisible) isSideMenuTogglerVisible$: Observable<boolean>;

    constructor(
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly store$: Store
    ) {
    }

    //#region HANDLERS

    ngOnInit(): void {
    }

    onSelect(event: MenuSelectEvent) {
        const item = event.item as NavMenuItemModel;
    }

    onAdminClick() {
        //this.tempData.setReturnUrl(this.router.url);
        this.router.navigate(['/administration']);
    }

    onClickToggler() {
        this.store$.dispatch(new SharedFeature.ToggleSideMenu());
    }

    onBackClick(event: Event) {
        event.preventDefault();
        this.router.navigateByUrl('');
        // this.router.navigate(['/']);
    }

    onItemClick(item: DropdownItemModel, user: LoggedUser) {
        switch (item.id) {
            case 1: this.openEditProfileModal(user); break;
            case 2: this.logout(); break;
        }
    }

    //#endregion

    //#region UI ACTIONS

    private openEditProfileModal(user: LoggedUser) {
        this.editProfileModalRef = this.modalService.open({ content: EditProfileDialogComponent });
        const instance = this.editProfileModalRef.content.instance as EditProfileDialogComponent;
        instance.user = user;
    }

    private logout() {
        this.store$.dispatch(new AuthFeature.UserLogout());
        this.router.navigate(['/login']);
    }

    //#endregion

    //#region HELPERS

    //#endregion
}
