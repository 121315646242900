import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';
import { IDocumentApiService } from 'src/app/modules/cognitive-search/models';

@Injectable({ providedIn: 'root' })
export class DocumentApiService extends BaseApiService implements IDocumentApiService {

    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    loadDocument(documentId: string, documentSubId: string, documentChunk: number, chunkCount: number): Observable<Blob> {
        let theTempFix = documentId;

        if (theTempFix.includes("ADJ123456_")) {
            theTempFix = theTempFix.replace("-", "_");
        }

        return this.http.get(`https://acruitycognitivesearch.blob.core.windows.net/%24web/120e18f4-0d12-4a15-8674-234cb861dfe5%2F${theTempFix}%2F${theTempFix}_${documentChunk}.pdf`, { responseType: 'blob' });
    }
}
