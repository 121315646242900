
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';

import { SharedFeature } from '../states/shared-state.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ActivePagesHandlerService {
    constructor(
        private readonly router: Router,
        private readonly store$: Store) {

        this.router.events.pipe(
            filter(x => x instanceof ActivationEnd),
            untilDestroyed(this)
        ).subscribe((event: ActivationEnd) => {
            const pages = this.traverseRouteData(event.snapshot);
            this.store$.dispatch(new SharedFeature.SetActivePages(pages));
        });
    }

    private traverseRouteData(route: ActivatedRouteSnapshot): Array<string> {
        const result: Array<string> = [];
        let currentRoute = route;
        do {
            const hasPageData: boolean = !!currentRoute.data?.page;
            if (hasPageData) {
                result.push(currentRoute.data.page);
            }
            currentRoute = currentRoute.firstChild;
        }
        while (currentRoute);

        return result;
    }
}
