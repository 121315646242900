import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-panel-loader',
    templateUrl: './panel-loader.component.html',
    styleUrls: ['./panel-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelLoaderComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }
}
