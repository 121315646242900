import { Injectable } from '@angular/core';
import { BuildInfoApiService } from '@api/services';

import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';


@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SharedService {

    constructor(

    ) {

    }

    // Sources

    private authTokenChangedSource = new BehaviorSubject<string>('');
    private userLogoutSource = new Subject<boolean>();

    // Streams

    authTokenChanged$ = this.authTokenChangedSource.asObservable();
    userLogoutChanged$ = this.userLogoutSource.asObservable();

    // Commands

    changeAuthToken(token: string) {
        this.authTokenChangedSource.next(token);
    }

    userLogout() {
        this.userLogoutSource.next(true);
    }

    // Streams Initialization
}
