import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationParameters } from 'msal';

import { FormGroupConfig } from '@shared/models';
import { AlertService } from '@shared/services';
import { AuthFeature, AuthStateService, SharedStateService } from '@shared/store';

interface FormValueModel {
    username: string;
    password: string;
}

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, AfterViewInit {
    form: FormGroup;

    @ViewChild('loginInputRef') loginInputRef: ElementRef;

    public serverError: string;

    isLoading$: Observable<boolean>;

    private _isMSLoggingIn$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly alertService: AlertService,
        private readonly broadcastService: BroadcastService,
        private readonly authService: MsalService,
        private readonly store$: Store
    ) {
    }

    //#region HANDLERS

    ngOnInit(): void {
        // INIT FIELDS
        this.initForm();
        this.isLoading$ = combineLatest([
            this.store$.select(SharedStateService.isApiLoading).pipe(map(fn => fn(AuthFeature.LoginWithPasswordAsync))),
            this._isMSLoggingIn$
        ]).pipe(
            map(([s1, s2]) => s1 || s2)
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.loginInputRef?.nativeElement) {
                this.loginInputRef.nativeElement.focus();
            }
        });
    }

    onSubmit() {
        if (!this.form.valid)
            return;

        this.setServerErrorMsg(null);
        const value: FormValueModel = this.form.value;
        const isDemo: boolean = value.password === 'Demo!';

        this.store$.dispatch([
            new AuthFeature.SetDemoMode(isDemo),
            new AuthFeature.LoginWithPasswordAsync(
                isDemo ? 'davidoberger@gmail.com' : value.username.trim(),
                isDemo ? 'smart900' : value.password)]).pipe(
                    untilDestroyed(this)
                ).subscribe(
                    () => {
                        let redirect = this.store$.selectSnapshot(AuthStateService.redirectUrl) || '';
                        this.store$.dispatch(new AuthFeature.SetRedirectUrl());
                        this.router.navigateByUrl(redirect);

                        // const user = this.alertService.getHighlightedText(`${this.store$.selectSnapshot(AuthStateService.user).Login}`);
                        const user = this.store$.selectSnapshot(AuthStateService.user);
                        this.alertService.success(`Welcome, ${user.FirstName || ''} ${user.LastName || ''}`);

                    },
                    (error: any) => {
                        // this.patchForm({ password: null });
                        this.setServerErrorMsg(error);
                    }
                );
    }

    onClickLoginMS() {
        const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

        const params: AuthenticationParameters = {
            extraScopesToConsent: ["user.read", "openid", "profile"]
        };

        if (isIE) {
            this.authService.loginRedirect(params);
        } else {
            this._isMSLoggingIn$.next(true);
            this.authService.loginPopup(params).finally(() => this._isMSLoggingIn$.next(false));
        }
    }

    //#endregion

    //#region  UI ACTIONS

    private setServerErrorMsg(error: any) {
        this.form.setErrors({ server: error ? error.ErrorMessage || error.message || error : null });
    }

    private initForm() {
        const config: FormGroupConfig<FormValueModel> = {
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        };

        this.form = this.formBuilder.group(config);
    }

    private patchForm(value?: Partial<FormValueModel>) {
        const _value: Partial<FormValueModel> = { ...(value || {}) };
        this.form.patchValue(_value);
        this.form.markAsPristine();
    }

    //#endregion
}
