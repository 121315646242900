import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    templateUrl: './dialog-content.component.html',
    styleUrls: ['./dialog-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContentComponent {

    @Input() set message(value: string) {
        this._safeMessage = this.sanitizer.bypassSecurityTrustHtml(value);
    }

    get sanitizedMessage(): SafeHtml {
        return this._safeMessage;
    }

    private _safeMessage: SafeHtml;

    constructor(
        private readonly sanitizer: DomSanitizer
    ) { }
}
