import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as NewSearch from '../models/new-search';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class NewSearchApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }
    execute(args: NewSearch.ExecuteArgsModel) {
        return this.http.post<NewSearch.ExecuteResultModel>('api/NewSearch/Execute', args);
    }

    executePubMed(args: NewSearch.ExecutePubMedArgsModel) {
        return this.http.post<NewSearch.ExecutePubMedResultModel>('api/NewSearch/ExecutePubMed', args);
    }

    fetchPubMedDocument(args: NewSearch.FetchPubMedDocumentArgsModel) {
        return this.http.post<NewSearch.FetchPubMedDocumentResultModel>('api/NewSearch/FetchPubMedDocument', args);
    }

    // TODO: #remove
    // getAvailableDocuments() {
    //     return this.http.get<NewSearch.GetAvailableDocumentsModel>('api/NewSearch/GetAvailableDocuments');
    // }

    // TODO: #remove
    // getReferences() {
    //     return this.http.get<NewSearch.GetReferencesModel>('api/NewSearch/GetReferences');
    // }

    // TODO: #remove
    // getSearches(caseNum: string) {
    //     return this.http.get<NewSearch.GetSearchesModel>('api/NewSearch/GetSearches?' + this.encodeQueryData({ caseNum }));
    // }

    // TODO: #remove
    // getSearchCategories() {
    //     return this.http.get<NewSearch.GetSearchCategoriesModel>('api/NewSearch/GetSearchCategories');
    // }
}
