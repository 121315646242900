import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-image-select',
	templateUrl: './image-select.component.html',
	styleUrls: ['./image-select.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImageSelectComponent), multi: true }]
})
export class ImageSelectComponent implements ControlValueAccessor {

	@Input() imagesPaths: Array<ImageSelectComponentImageModel>;

	@Input() disabled = false;

	selectedImageValue: string = null;

	onChange: any = () => { };
	onTouched: any = () => { };

	constructor() { }

	onImageClick(value: string) {
		if (this.disabled) {
			return;
		}
		if (this.selectedImageValue !== value) {
			this.selectedImageValue = value;
			this.onChange(value);
		}
	}

	//#region ControlValueAccessor

	writeValue(value: string | any) {
		if (typeof value === 'string') {
			this.selectedImageValue = value;
		}
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	//#endregion
}

export interface ImageSelectComponentImageModel {
	path: string;
	value: string;
}
