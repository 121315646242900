import {
    ChangeDetectionStrategy,
    Component,
    Input
} from '@angular/core';

@Component({
    selector: 'app-custom-tooltip-template',
    templateUrl: './custom-tooltip-template.component.html',
    styleUrls: ['./custom-tooltip-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTooltipTemplateComponent {
    @Input() position: 'top' | 'right' | 'bottom' | 'left';
    @Input() visible: boolean = true;
    @Input() text: string;

    constructor() { }
}
