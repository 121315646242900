import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { DOCUMENT_API_SERVICE, IDocumentApiService } from "../models";

interface CacheKeyModel {
    documentId: string;
    documentSubId: string;
    documentChunk: number;
    chunkCount: number;
}

@Injectable({ providedIn: 'root' })
export class DocumentCacheService {

    private cache: Map<CacheKeyModel, Observable<Blob>> = new Map();

    constructor(
        @Inject(DOCUMENT_API_SERVICE) private readonly documentApiService: IDocumentApiService
    ) {

    }

    retrieveFromWebOrCache(documentId: string, documentSubId: string, documentChunk: number, chunkCount: number): Observable<Blob> {
        const key = this.getOrCreateKey(documentId, documentSubId, documentChunk, chunkCount);

        if (this.cache.has(key)) {
            return this.cache.get(key);
        }

        const fromApi$ = this.retrieve$(key);
        this.cache.set(key, fromApi$);

        return fromApi$;
    }

    retrieveFromWeb(documentId: string, documentSubId: string, documentChunk: number, chunkCount: number): Observable<Blob> {
        const key = this.getOrCreateKey(documentId, documentSubId, documentChunk, chunkCount);

        if (this.cache.has(key)) {
            this.cache.delete(key);
        }

        const fromApi$ = this.retrieve$(key);
        this.cache.set(key, fromApi$);

        return fromApi$;
    }


    private retrieve$({ documentId, documentSubId, documentChunk, chunkCount }: CacheKeyModel): Observable<Blob> {
        return this.documentApiService.loadDocument(documentId, documentSubId, documentChunk, chunkCount).pipe(
            shareReplay(1)
        );
    }

    private isKeyEquals(key1: CacheKeyModel, key2: CacheKeyModel): boolean {
        return key1.documentId === key2.documentId &&
            key1.documentSubId === key2.documentSubId &&
            key1.documentChunk === key2.documentChunk &&
            key1.chunkCount === key2.chunkCount;
    }

    private getOrCreateKey(documentId: string, documentSubId: string, documentChunk: number, chunkCount: number) {
        let result: CacheKeyModel = { documentId, documentSubId, documentChunk, chunkCount };
        for (let key of this.cache.keys()) {
            if (this.isKeyEquals(key, result)) {
                result = key;
                break;
            }
        }

        return result;
    }
}
