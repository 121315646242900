import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Utilities } from '../helpers/utilities';
import { AlertMessage, NotificationType } from '../models';

@Injectable()
export class AlertService {
    private messages = new Subject<AlertMessage>();

    //#region custom messages

    default(summary: string): void {
        this.showMessage(summary, NotificationType.Default);
    }

    success(summary: string): void {
        this.showMessage(summary, NotificationType.Success);
    }

    error(summary: string): void {
        this.showMessage(summary, NotificationType.Error);
    }

    warning(summary: string): void {
        this.showMessage(summary, NotificationType.Warning);
    }

    info(summary: string): void {
        this.showMessage(summary, NotificationType.Info);
    }

    //#endregion

    private showMessage(summary: string, type: NotificationType = NotificationType.Default) {
        this.showMessageHelper(summary, type, false, 10000);
    }

    showStickyMessage(summary: string);
    showStickyMessage(summary: string, detail: string, type: NotificationType, error?: any);
    showStickyMessage(summaryAndDetails: string[], summaryAndDetailsSeparator: string, type: NotificationType);
    showStickyMessage(data: string | string[], separatorOrDetail?: string, type?: NotificationType, error?: any) {

        if (!type)
            type = NotificationType.Default;

        if (data instanceof Array) {
            for (let message of data) {
                let msgObject = Utilities.splitInTwo(message, separatorOrDetail);

                this.showMessageHelper(msgObject.firstPart, type, true, 6000);
            }
        } else {

            if (error) {

                let msg = `Type: "${NotificationType[type]}", Summary: "${data}", Detail: "${separatorOrDetail}", Error: "${Utilities.safeStringify(error)}"`;

                switch (type) {
                    case NotificationType.Default:
                        this.logInfo(msg);
                        break;
                    case NotificationType.Info:
                        this.logInfo(msg);
                        break;
                    case NotificationType.Success:
                        this.logMessage(msg);
                        break;
                    case NotificationType.Error:
                        this.logError(msg);
                        break;
                    case NotificationType.Warning:
                        this.logWarning(msg);
                        break;
                    default:
                        this.logInfo(msg);
                        break;
                }
            }

            this.showMessageHelper(data, type, true, 6000);
        }
    }

    private showMessageHelper(summary: string, type: NotificationType, isSticky: boolean = false, hideAfter?: number) {
        this.messages.next({ type: type, summary: summary, hideAfter });
    }

    logDebug<T>(msg: T) {
        // tslint:disable-next-line: no-console
        console.debug(msg);
    }

    logError<T>(msg: T) {
        console.error(msg);
    }

    logInfo<T>(msg: T) {
        // tslint:disable-next-line: no-console
        console.info(msg);
    }

    logMessage<T>(msg: T) {
        console.log(msg);
    }

    logTrace<T>(msg: T) {
        // tslint:disable-next-line: no-console
        console.trace(msg);
    }

    logWarning<T>(msg: T) {
        console.warn(msg);
    }

    getMessageEvent(): Observable<AlertMessage> {
        return this.messages.asObservable();
    }
}
