import { Directive, OnInit } from '@angular/core';
import { FocusableItem } from '../models';

@Directive({
	selector: 'form, [focusableHost]'
})
export class FocusableHostDirective implements OnInit {

	private items: Array<FocusableItem> = [];

	constructor() {
	}

	get firstErrorItem(): FocusableItem | null {
		return this.items.find(x => x.control.invalid);
	}

	ngOnInit(): void {
	}

	register(item: FocusableItem) {
		this.items.push(item);
	}

	unregister(item: FocusableItem) {
		const index = this.items.indexOf(item);
		if (index > -1) {
			this.items.splice(index, 1);
		}
	}
}
