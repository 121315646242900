import { ChangeDetectorRef, Directive, ElementRef, EmbeddedViewRef, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, ReplaySubject } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
    selector: '[blurredLoaderBase]'
})
export class BlurredLoaderDirective {

    @Input() blurredLoaderText: string | null = 'Loading...';

    @Input() templateRef: TemplateRef<any>;

    @Input() delay: number | null;

    @Input() set blurredLoader(state: boolean) {
        this._blurredLoaderSource.next(!!state);
    }

    private get hasTemplate() {
        return !!this.templateRef;
    }

    private _div: HTMLElement;
    private _embeddedViewRef: EmbeddedViewRef<any>;
    private _blurredLoaderSource = new ReplaySubject<boolean>(1);

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly _viewContainer: ViewContainerRef
    ) {
        this._blurredLoaderSource.pipe(
            distinctUntilChanged(),
            switchMap(show => of(show).pipe(
                delay(show ? this.delay || 0 : 0)
            )),
            untilDestroyed(this)
        ).subscribe(show => {
            if (show) {
                this.showLoader();
            } else {
                this.hideLoader();
            }
            this.changeDetector.markForCheck();
        });
    }

    private showLoader() {

        if (this.hasTemplate) {
            if (this._embeddedViewRef) {
                return;
            }

            this._embeddedViewRef = this._viewContainer.createEmbeddedView(this.templateRef);

        } else {

            if (this._div) return;

            let icon = this.renderer.createElement('i');
            this.renderer.addClass(icon, 'icon-spinner');

            let p = this.renderer.createElement('p');
            let pText = this.renderer.createText(this.blurredLoaderText);
            this.renderer.appendChild(p, pText);

            this._div = this.renderer.createElement('div');
            this.renderer.addClass(this._div, 'blurred-loader');
            this.renderer.appendChild(this._div, icon);
            this.renderer.appendChild(this._div, p);

            // this.renderer.addClass(this.el.nativeElement, 'position-relative');
            this.renderer.appendChild(this.el.nativeElement, this._div);
        }
    }

    private hideLoader() {
        if (this._div) {
            this.renderer.removeChild(this.el.nativeElement, this._div);
            this._div = null;
        }

        if (this._embeddedViewRef) {
            this._embeddedViewRef.destroy();
            this._embeddedViewRef = null;
        }
    }
}
