import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import Instance from 'pspdfkit/dist/types/typescript/Instance';
import PSPDFKit from 'pspdfkit';
import ViewState from 'pspdfkit/dist/types/typescript/models/ViewState';
import { Annotation } from 'pspdfkit/dist/types/typescript/models/annotations';
import { BehaviorSubject, combineLatest, concat, EMPTY, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Configuration } from 'pspdfkit/dist/types/typescript/Configuration';
import { InstantJSON } from 'pspdfkit/dist/types/typescript/lib/InstantJSON';
import { LayoutModeType } from 'pspdfkit/dist/types/typescript/enums/LayoutMode';
import { List } from 'pspdfkit/dist/types/immutable/dist/immutable-nonambient';
import { Store } from '@ngxs/store';
import { ToolbarItem } from 'pspdfkit/dist/types/typescript/models/ToolbarItem';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ZoomModeType } from 'pspdfkit/dist/types/typescript/enums/ZoomMode';
import { catchError, concatMap, distinctUntilChanged, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { ComponentHasPendingChanges } from '@shared/guards';

import { ContextMenuClickEventData, ContextMenuItem, PdfModuleConfig, PDF_MODULE_CONFIG } from '../../models';


export enum PDFViewerState {
    UnMounted,
    Loading,
    Loaded,
    Error
}

type AnnotationPartial = Pick<Annotation, 'id' | 'note'>;

type RemoveEventListenerFn = () => void;

interface ContextMenuItemExtended extends ContextMenuItem {
    clickCallback: (event: MouseEvent, item: ContextMenuItemExtended) => any;
}

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'pdf-view',
    templateUrl: './pdf-view.component.html',
    styleUrls: ['./pdf-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewComponent implements OnInit, AfterViewInit, OnDestroy, ComponentHasPendingChanges {

    @Input() set toolbarItems(value: Array<ToolbarItem>) {
        // console.log('set toolbarItems', value);
        this._toolbarSource.next(value || []);
    }

    @Input() set term(value: string) {
        // console.log('set term', value);
        this._termSource.next(value || '');
    }

    @Input() set document(value: { blob: Blob, instantJSON: InstantJSON }) {
        // console.log('set documentPath', value);
        this._pdfSource.next(value);
    }

    @Input() set pageIndex(value: number) {
        // console.log('set page', value);
        this._pageIndexSource.next(value);
    }

    @Input() set layoutMode(value: LayoutModeType) {
        // console.log('set layoutMode', value);
        this._layoutModeSource.next(value);
    }

    @Input() set instantJSON(value: InstantJSON) {
        // console.log('set instantJSON', value);
        this._instantJSONSource.next(value);
    }

    @Input() contextMenuItems: Array<ContextMenuItem>;

    @Output() layoutModeChange: EventEmitter<LayoutModeType> = new EventEmitter();
    @Output() pageIndexChange: EventEmitter<number> = new EventEmitter();
    @Output() annotationsSave: EventEmitter<InstantJSON> = new EventEmitter();
    @Output() scrollStart: EventEmitter<any> = new EventEmitter();
    @Output() scrollEnd: EventEmitter<any> = new EventEmitter();
    @Output() stateChange: EventEmitter<PDFViewerState> = new EventEmitter();
    @Output() linkPress: EventEmitter<{ text: string, pageIndex: number }> = new EventEmitter();
    @Output() contextMenuItemClick: EventEmitter<ContextMenuClickEventData> = new EventEmitter();
    @Output() instanceChange: EventEmitter<Instance> = new EventEmitter();

    private static _lastInstanceId: number = 0;

    readonly containerPrefix: string = 'pdfview';

    @ViewChild('pspdfkitContainer') containerRef: ElementRef<HTMLDivElement>;


    get instanceId(): number {
        return this._instanceId;
    }
    private _instanceId: number;
    private _annotationsToUpdate: Array<AnnotationPartial> = [];

    // STREAMS
    private readonly _outLayoutModeSource = new Subject<LayoutModeType>();
    private readonly _outPageIndexSource = new Subject<number>();

    private readonly _instantJSONSource = new BehaviorSubject<InstantJSON>(null);
    private readonly _layoutModeSource = new ReplaySubject<LayoutModeType>(1);
    private readonly _pdfSource = new ReplaySubject<{ blob: Blob, instantJSON: InstantJSON }>(1);
    private readonly _pageIndexSource = new BehaviorSubject<number>(1);
    private readonly _PSPDFInstanceSource = new ReplaySubject<Instance>(1);
    private readonly _termSource = new BehaviorSubject<string>('');
    private readonly _toolbarSource = new BehaviorSubject<Array<ToolbarItem>>([]);


    // SNAPSHOTS
    private _PSPDFInstanceSnapshot: Instance;
    private _toolbarItemsSnapshot: Array<ToolbarItem> = [];
    private _instantJSONSnapshot: InstantJSON;
    private _layoutModeSnapshot: LayoutModeType;
    private _zoomSnapshot: ZoomModeType | number;
    private _pageIndexSnapshot: number;
    private _eventListenersSnapshot: Array<RemoveEventListenerFn> = [];
    private _contextMenuRefSnapshot: HTMLElement;


    private bottomScrollCount: number = 0;
    private autoLoadScrollThreshold: number = 12;
    private topScrollCount: number = 0;
    constructor(
        private readonly store: Store,
        @Inject(PDF_MODULE_CONFIG) private readonly config: PdfModuleConfig
    ) {
        this._instanceId = PdfViewComponent.generateNextInstanceId();
    }

    private static generateNextInstanceId(): number {
        return ++PdfViewComponent._lastInstanceId;

    }

    //#region HANDLERS

    ngOnInit(): void {

        // INIT SUBSCRIPTIONS
        this._outLayoutModeSource
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(mode => {
                this.layoutModeChange.emit(mode);
                this._layoutModeSnapshot = mode;
            });

        // this._outZoomSource.pipe(distinctUntilChanged(), untilDestroyed(this))
        //     .subscribe(zoom => {
        //         if (this._zoomSnapshot !== zoom) {
        //             this.zoomChange.emit(zoom);
        //             this._zoomSnapshot = zoom;
        //         }
        //     });

        this._outPageIndexSource.pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(index => {
                this.pageIndexChange.emit(index);
                this._pageIndexSnapshot = index;
            });

        this._toolbarSource.pipe(
            switchMap(toolbars => this._PSPDFInstanceSource.pipe(filter(x => !!x), map(instance => [instance, toolbars] as [Instance, Array<ToolbarItem>]))),
            untilDestroyed(this)
        ).subscribe(([instance, toolbars]) => {
            // console.log('handleToolbarItemsChange',);
            this.handleToolbarItemsChange(instance, toolbars);
        });

        this._layoutModeSource.pipe(
            switchMap(layout => this._PSPDFInstanceSource.pipe(filter(x => !!x), map(instance => [instance, layout] as [Instance, LayoutModeType]))),
            untilDestroyed(this)
        ).subscribe(([instance, layout]) => {
            // console.log('handleLayoutModeChange',);
            this.handleLayoutModeChange(instance, layout);
        });

        this._PSPDFInstanceSource.pipe(
            switchMap(instance =>
                (instance ? combineLatest([this._pageIndexSource, this._termSource]) : EMPTY)
                    .pipe(
                        mergeMap(([page, term]) => this.handleInstanceConfigChange$(instance, page, term)))),
            untilDestroyed(this)
        ).subscribe();

        this._PSPDFInstanceSource.pipe(
            untilDestroyed(this)
        ).subscribe(x => this.instanceChange.emit(x));
    }

    ngOnDestroy() {
        if (this._PSPDFInstanceSnapshot) {
            this.destroyPSPDFKitInstance$();
        }
    }

    ngAfterViewInit() {
        if (this.containerRef?.nativeElement) {

            this._pdfSource.pipe(
                distinctUntilChanged((a, b) => a.blob === b.blob && a.instantJSON === b.instantJSON),
                concatMap(x => this.initPSPDFKitInstance(x.blob, x.instantJSON)),
                untilDestroyed(this)
            ).subscribe(instance => {
                this._eventListenersSnapshot = this.bindEventListeners(instance);
                this._PSPDFInstanceSource.next(instance);
                this._PSPDFInstanceSnapshot = instance;
                // console.log({ instance });
            });
        }
    }

    hasPendingChanges: () => boolean = () => !!this._PSPDFInstanceSnapshot?.hasUnsavedChanges();


    //#endregion


    //#region UI ACTIONS


    private initPSPDFKitInstance(pdf: Blob, instantJSON: InstantJSON = null): Observable<Instance> {
        // console.log(pdf);
        if (this._PSPDFInstanceSnapshot) {
            this.destroyPSPDFKitInstance$();
            // TODO: close here
        }

        if (!pdf) {
            this.stateChange.emit(PDFViewerState.UnMounted);
            return of(null);
        }

        this.stateChange.emit(PDFViewerState.Loading);

        const config: Configuration = {
            initialViewState: new PSPDFKit.ViewState({
                layoutMode: this._layoutModeSnapshot || 'DOUBLE',
                zoom: this._zoomSnapshot || 'AUTO'
            }),
            toolbarItems: this._toolbarItemsSnapshot || [],
            instantJSON: instantJSON,
            baseUrl: this.config.baseUrl,
            document: null,
            container: '#app' + this.instanceId,
            licenseKey: this.config.licenseKey,
            styleSheets: this.config.styleSheets || [],
            editableAnnotationTypes: [PSPDFKit.Annotations.HighlightAnnotation, PSPDFKit.Annotations.NoteAnnotation],
            annotationPresets: this.getAnnotationPresets(),
            disableTextSelection: false,
            disableWebAssemblyStreaming: true,
        };

        return from(pdf.arrayBuffer()).pipe(
            mergeMap(arrayBuffer => {

                return from(PSPDFKit.load({ ...config, document: arrayBuffer })).pipe(
                    tap(() => this.stateChange.emit(PDFViewerState.Loaded)),
                    catchError(error => {
                        this.stateChange.emit(PDFViewerState.Error);
                        throw error;
                    })
                );
            })
        );
    }

    private destroyPSPDFKitInstance$() {
        this.removeEventListeners();
        PSPDFKit.unload(this._PSPDFInstanceSnapshot || '.pdfholder');
        this.topScrollCount = 0;
        this.bottomScrollCount = 0;
        this._PSPDFInstanceSource.next(null);
        this._PSPDFInstanceSnapshot = null;
        this.stateChange.emit(PDFViewerState.UnMounted);
    }

    //#endregion


    //#region HELPERS


    private handleZoomChange(instance: Instance, zoom: ZoomModeType | number) {
        this._zoomSnapshot = zoom;
        const state = instance.viewState.set('zoom', zoom);
        instance.setViewState(state);
    }

    private handleToolbarItemsChange(instance: Instance, items: Array<ToolbarItem>) {
        instance.setToolbarItems(items);
        this._toolbarItemsSnapshot = items;
    }

    private handleLayoutModeChange(instance: Instance, layoutMode: LayoutModeType) {
        this._layoutModeSnapshot = layoutMode;
        const state = instance.viewState.set('layoutMode', layoutMode || 'DOUBLE');
        instance.setViewState(state);
    }

    private bindEventListeners(instance: Instance): Array<RemoveEventListenerFn> {
        const result: Array<RemoveEventListenerFn> = [];

        const contextMenuHandler = (event: MouseEvent) => {
            event.preventDefault();

            if (event.button === 2) {
                let selection = instance.getTextSelection() as any;

                const clickCallback = (mEvent: MouseEvent, item: ContextMenuItemExtended) => {
                    let selection2 = instance.getTextSelection() as any;

                    selection2?.getText().then((text: string) => {
                        const _text = text.trim();

                        if (_text) {
                            const _item = { ...item };
                            delete _item['clickCallback'];
                            this.contextMenuItemClick.emit({
                                event: mEvent,
                                text: this.clearSelectedText(_text),
                                pristineText: _text,
                                pageIndex: selection2.startPageIndex,
                                type: _item.type,
                                menuItem: _item
                            });
                        }
                    });
                };

                if (selection && this.contextMenuItems?.length) {
                    let items: Array<ContextMenuItemExtended> = this.contextMenuItems.map(x => ({ ...x, clickCallback }));
                    const bodyElement = (this.containerRef.nativeElement.querySelector('iframe') as HTMLIFrameElement)?.contentDocument?.querySelector('body') as HTMLElement;
                    if (bodyElement) {
                        let menu = this.createContextMenu(bodyElement, items);
                        this.showContextMenu(menu, event.pageX, event.pageY);
                    }
                }
            } else {
                if (this._contextMenuRefSnapshot) {
                    this.hideContextMenu(this._contextMenuRefSnapshot);
                }
            }

        };
        instance.contentDocument.addEventListener('contextmenu', contextMenuHandler);
        result.push(() => instance.contentDocument.removeEventListener('contextmenu', contextMenuHandler));

        const getDispose = this.createBindAndGetDisposeFn(instance);
        result.push(getDispose('annotations.create', (createdAnnotations: List<Annotation>) => this.updateAnnotationFromSelection(createdAnnotations.toArray())));
        result.push(getDispose('annotations.update', (updatedAnnotations: List<Annotation>) => this.updateAnnotationFromSelection(updatedAnnotations.toArray())));
        result.push(getDispose('annotations.didSave', () => this.saveAnnotations()));
        result.push(getDispose('viewState.change', (viewState: ViewState) => { this._outLayoutModeSource.next(viewState.layoutMode); }));
        result.push(getDispose('viewState.zoom.change', (zoom: ZoomModeType | number) => { this._zoomSnapshot = zoom; }));
        result.push(getDispose('viewState.currentPageIndex.change', (pageIndex: number) => { this._outPageIndexSource.next(pageIndex); }));
        result.push(getDispose('annotations.press', (event: { annotation: Annotation, nativeEvent: Event, preventDefault: () => void, selected: boolean, }
        ) => {
            if (event.annotation instanceof PSPDFKit.Annotations.LinkAnnotation) {
                event.preventDefault();
                this.linkPress.emit({ text: event.annotation.name, pageIndex: event.annotation.pageIndex });
                // console.log(`event name: ${event.annotation.name}, event page index: ${event.annotation.pageIndex}`);
            }
        }));


        const container = this.containerRef.nativeElement;
        const scrollContainer = (container.querySelector('iframe') as HTMLIFrameElement)?.contentDocument?.querySelector('div.PSPDFKit-Viewport > div.PSPDFKit-Scroll') as HTMLElement;

        if (scrollContainer) {
            // console.log({ scrollContainer: scrollContainer });
            //change the scroll script
            // scrollContainer.removeEventListener('scroll', any);


            const scrollHandler = () => {
                if (scrollContainer.offsetHeight + scrollContainer.scrollTop >= scrollContainer.scrollHeight - 2) {
                    scrollContainer.scrollTop -= 1;
                    this.bottomScrollCount++;
                    // console.log("#############scroll", this.bottomScrollCount, this.autoLoadScrollThreshold);
                    if (this.bottomScrollCount >= this.autoLoadScrollThreshold) {
                        this.bottomScrollCount = 0;
                        this.scrollEnd.emit();
                        return;
                    }
                }
                if (scrollContainer.scrollTop <= 0) {
                    scrollContainer.scrollTop = 1;
                    this.topScrollCount++;
                    if (this.topScrollCount >= this.autoLoadScrollThreshold) {
                        this.topScrollCount = 0;
                        this.scrollStart.emit();
                        // handleCurrentChunkScrollStart();
                        return;
                    }
                }
                if (this._contextMenuRefSnapshot) {
                    this.hideContextMenu(this._contextMenuRefSnapshot);
                }
            };
            scrollContainer.addEventListener('scroll', scrollHandler);
            result.push(() => scrollContainer.removeEventListener('scroll', scrollHandler));

        }
        return result;
    }


    private removeEventListeners() {
        for (let fn of this._eventListenersSnapshot) {
            fn();
        }
        this._eventListenersSnapshot = [];
    }



    private async updateAnnotationFromSelection(annotations: Array<Annotation>) {
        const textSelection = this._PSPDFInstanceSnapshot.getTextSelection() as any;
        if (textSelection) {
            const text = await textSelection.getText();

            for (let annotation of annotations) {
                const existedAnnotations = this._annotationsToUpdate.filter(x => x.id === annotation.id);
                if (existedAnnotations.length) {
                    // UPDATE
                    existedAnnotations.forEach(x => x.note = text);
                } else {
                    // CREATE
                    this._annotationsToUpdate.push({ id: annotation.id, note: text });
                }
            }
        }
    }

    private async saveAnnotations() {
        let instantJSON = await this._PSPDFInstanceSnapshot.exportInstantJSON() as InstantJSON;

        if (instantJSON && instantJSON.annotations) {
            for (let annotation of instantJSON.annotations as Array<Annotation>) {
                const updatedAnnotation = this._annotationsToUpdate.find(x => x.id === annotation.id);
                if (updatedAnnotation) {
                    annotation.note = updatedAnnotation.note;
                }
            }
        }

        this.annotationsSave.emit(instantJSON);
    }

    private handleInstanceConfigChange$(instance: Instance, pageIndex: number, term: string) {
        // console.log('handleInstanceConfigChange$', { instance, pageIndex, term });
        const state = instance.viewState;

        if (pageIndex !== state.currentPageIndex) {
            const newState = state.set("currentPageIndex", pageIndex); // currentPageIndex is zero-based
            instance.setViewState(newState);
        }

        let _term = term;
        if (!_term) {
            _term = "XXXX";
        }
        const regExPattern = this.genRegExSearch(_term);
        const options = {
            startPageIndex: pageIndex,
            endPageIndex: pageIndex,
            searchType: "regex"
        };

        return from(instance.search(regExPattern, options)).pipe(
            tap(results => {
                instance.setSearchState(instance.searchState.set("results", results));
            })
        );
    }

    private genRegExSearch(term: string): string {
        const termNormalized = (term || '').replace(/[.*+?^${}()/|[\]\\]/g, ' ');
        const words = termNormalized.split(" ").filter(x => !!x);
        let result = words.reduce((agg, next) => agg += next + '[\\s\\S]*', '');
        result += words[words.length - 1];
        // console.log("genRegExSearch", { words, term, termNormalized, result });
        return result;

        // OLD
        // return words.join("|");
    }

    private getAnnotationPresets() {
        let annotationPresets = PSPDFKit.defaultAnnotationPresets;
        annotationPresets['text-highlighter'] = {
            color: PSPDFKit.Color.LIGHT_BLUE
        };
        annotationPresets.highlight = {
            color: PSPDFKit.Color.LIGHT_BLUE
        };
        annotationPresets.note = {
            color: new PSPDFKit.Color({ r: 114, g: 255, b: 0 })
        };
        return annotationPresets;
    }

    private createContextMenu(container: HTMLElement, items: Array<ContextMenuItemExtended>) {
        let menu = container.querySelector('#context-menu') as HTMLElement;

        if (!menu) {
            menu = document.createElement('ul');
            menu.setAttribute('id', 'context-menu');
            menu.setAttribute('tabindex', '-1');
            menu.classList.add('context-menu');

            items.forEach(item => {
                const li = document.createElement('li');
                li.classList.add('context-menu__item');
                li.innerHTML = item.text;
                li.setAttribute('data-item-id', item.id.toString());
                li.addEventListener('click', (event: MouseEvent) => {
                    item.clickCallback(event, item);
                    this.hideContextMenu(menu);
                });

                menu.append(li);
            });

            container.append(menu);
            this._contextMenuRefSnapshot = menu;

            container.addEventListener('click', () => {
                this.hideContextMenu(menu);
            });
        }

        return menu;
    }

    private showContextMenu(menu: HTMLElement, x: number, y: number) {
        menu.style.top = `${y}px`;
        menu.style.left = `${x}px`;
        menu.classList.add('is--open');
    }

    private hideContextMenu(menu: HTMLElement) {
        menu.classList.remove('is--open');
    }

    private createBindAndGetDisposeFn<A1, A2>(source: { addEventListener: (action: A1, handler: A2) => any, removeEventListener: (action: A1, handler: A2) => any }) {
        return (action: A1, handler: A2) => {
            source.addEventListener(action, handler);
            return () => source.removeEventListener(action, handler);
        };
    }

    private clearSelectedText(text: string): string {
        // TODO: implement better
        text = text.replace(/\|/gm, ' ');
        text = text.replace(/\s\s+/g, ' ');
        return text;
    }

    //#endregion
}
