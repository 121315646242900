import { addDays } from "@progress/kendo-date-math";
import { equal } from "./equal";
import { setTimeFromDate } from "./set-time-from-date";


export function isInRange(date: Date, start: Date, end: Date, ignoreDate: boolean = false, ignoreTime: boolean = true): boolean {
    if (end <= start)
        return false;

    if (!ignoreTime && ignoreDate) {
        let timeStartDate = setTimeFromDate(start, date);
        let timeEndDate = setTimeFromDate(end, date);

        if (timeStartDate < start)
            timeStartDate = addDays(timeStartDate, 1);

        if (timeEndDate > end)
            timeEndDate = addDays(timeEndDate, -1);

        return timeStartDate >= start && timeEndDate <= end;

    } else {
        if (date >= start && date <= end)
            return true;

        if (ignoreTime)
            return equal(date, start, ignoreTime) || equal(date, end, ignoreTime);
    }
    return false;
}
