import { Injectable } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

import { Selector, State } from "@ngxs/store";

import { SearchFormValueModel } from "../../components/cognitive-search-search-form/cognitive-search-search-form.component";
import { FilterFormValueModel } from "../../components/cognitive-search-timeline-filter-form/cognitive-search-timeline-filter-form.component";
import { PubMedSearchFormValueModel } from "../../components/cognitive-search-pub-med-search-form/cognitive-search-pub-med-search-form.component";

export namespace FormsFeature {
    export const FeatureKey = 'forms';

    //#region TYPES

    export interface StateModel {
        searchForm: {
            model: SearchFormValueModel;
            dirty: boolean;
            status: string;
            errors: ValidationErrors;
        };
        timelineFilterForm: {
            model: FilterFormValueModel;
            dirty: boolean;
            status: string;
            errors: ValidationErrors;
        };
        pubMedSearchForm: {
            model: PubMedSearchFormValueModel;
            dirty: boolean;
            status: string;
            errors: ValidationErrors;
        };
    }

    //#endregion


    //#region ACTIONS

    // TODO: #remove
    // export class SetFilterFormValue {
    //     public static type: string = `@${FeatureKey}/SetFilterFormValue`;
    //     constructor(
    //         public readonly value: Partial<FilterFormValueModel>
    //     ) {

    //     }
    // }

    //#endregion
}

@State<FormsFeature.StateModel>({
    name: FormsFeature.FeatureKey,
    defaults: {
        searchForm: {
            model: { caseName: '', searchTerms: '' },
            dirty: false,
            status: '',
            errors: {}
        },
        timelineFilterForm: {
            model: {
                fromDateVal: null,
                toDateVal: null,
                generalRecord: true,
                medicalReports: true,
                laboratoryReports: true,
                miscellaneous: true,
                sortDescending: true,
                includeMissingDates: true
            },
            dirty: false,
            status: '',
            errors: {}
        },
        pubMedSearchForm: {
            model: {
                searchTerms: ''
            },
            dirty: false,
            status: '',
            errors: {}
        }
    }
})
@Injectable()
export class FormsStateService {
    //#region SELECTORS

    //#region SearchForm

    @Selector([FormsStateService])
    public static searchForm(state: FormsFeature.StateModel) {
        return state.searchForm;
    }

    //#endregion

    //#region  FilterForm

    @Selector([FormsStateService])
    public static fromDateVal(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.fromDateVal;
    }

    @Selector([FormsStateService])
    public static toDateVal(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.toDateVal;
    }

    @Selector([FormsStateService])
    public static generalRecord(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.generalRecord;
    }

    @Selector([FormsStateService])
    public static medicalReports(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.medicalReports;
    }

    @Selector([FormsStateService])
    public static laboratoryReports(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.laboratoryReports;
    }

    @Selector([FormsStateService])
    public static miscellaneous(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.miscellaneous;
    }

    @Selector([FormsStateService])
    public static sortDescending(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.sortDescending;
    }

    @Selector([FormsStateService])
    public static includeMissingDates(state: FormsFeature.StateModel) {
        return state.timelineFilterForm?.model?.includeMissingDates;
    }

    //#endregion

    //#endregion
}
