import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';

import { PdfModuleConfig, PDF_MODULE_CONFIG } from './models';
import { PdfViewComponent, CommonPdfViewerComponent } from './components';

@NgModule({
    declarations: [
        PdfViewComponent,
        CommonPdfViewerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PdfViewComponent,
        CommonPdfViewerComponent
    ]
})
export class PdfModule {
    public static forChild(config: PdfModuleConfig): ModuleWithProviders<PdfModule> {
        return {
            ngModule: PdfModule,
            providers: [{ provide: PDF_MODULE_CONFIG, useValue: config }]
        };
    }
}

