import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';

import { AuthTokenStorageService } from './services';
import { AuthStateService } from './store';
import { AuthHandlerService } from './store/handlers';

@NgModule({
    imports: [
        NgxsModule.forFeature([AuthStateService])
    ]
})
export class AuthenticationModule {
    constructor(
        _1: AuthHandlerService,
        @Optional() @SkipSelf() parentModule?: AuthenticationModule) {
        if (parentModule) {
            throw new Error('AuthenticationModule is already loaded. Import it in the AppModule only');
        }
    }

    public static forRoot(): ModuleWithProviders<AuthenticationModule> {
        return {
            ngModule: AuthenticationModule,
            providers: [
                CookieService,
                AuthTokenStorageService,
                AuthHandlerService
            ]
        };
    }
}
