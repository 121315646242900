import { ContextMenuItem } from "src/app/modules/pdf/models";

export const CopyTextContextMenuItem: ContextMenuItem = {
    id: 1,
    text: 'Copy Text',
    type: 'copy'
};

export const DepoBuilderContextMenuItem: ContextMenuItem = {
    id: 2,
    text: 'Depo Builder™',
    type: 'depo'
};


