const Moq = {
    "GetUsers": {
        "Args": {
            "Search": null
        },
        "Items": [
            {
                "Id": 1,
                "Login": "admin",
                "FirstName": "Admin",
                "LastName": "User1",
                "FullName": "Admin User1",
                "Email": "admin@example.com",
                "IconPath": "http://boardchat.staturedev.com/attachments/image_fba16393af9b460cbfb6d8a6429115ae.png",
                "CreatedDate": "2020-10-13T14:52:16.263",
                "UpdatedDate": "2020-10-13T14:52:16.263",
                "UserRoles": [
                    {
                        "UserId": 1,
                        "RoleId": 1,
                        "RoleName": "admin"
                    }
                ]
            },
            {
                "Id": 2,
                "Login": "demouser",
                "FirstName": "Demo",
                "LastName": "User",
                "FullName": "Demo User",
                "Email": "demouser@example.com",
                "IconPath": "http://boardchat.staturedev.com/attachments/image_c0351bf36f9746299de74588174f2f85.png",
                "CreatedDate": "2020-10-19T08:52:08.3511624",
                "UpdatedDate": "2020-11-03T13:00:01.1728788",
                "UserRoles": [
                    {
                        "UserId": 2,
                        "RoleId": 2,
                        "RoleName": "user"
                    }
                ]
            }
        ]
    },
    "GetLoggedUser": {
        "admin": {
            "UserID": 1,
            "FirstName": "Admin",
            "LastName": "User",
            "FullName": "Admin User",
            "Email": "admin@example.com",
            "IconPath": "http://boardchat.staturedev.com/attachments/image_fba16393af9b460cbfb6d8a6429115ae.png",
            "IsAdmin": true
        },
        "demouser": {
            "UserID": 2,
            "FirstName": "Demo",
            "LastName": "User",
            "FullName": "Demo User",
            "Email": "demouser@example.com",
            "IconPath": "http://boardchat.staturedev.com/attachments/image_c0351bf36f9746299de74588174f2f85.png",
            "IsAdmin": false
        }
    },
    "GetBuildInfoString": "2020-12-16T11:19:33 UTC",
    "GetEdit": {
        "1": {
            "Id": 1,
            "Login": "admin",
            "FirstName": "Admin",
            "LastName": "User1",
            "FullName": "Admin User1",
            "Email": "admin@example.com",
            "IconPath": "http://boardchat.staturedev.com/attachments/image_fba16393af9b460cbfb6d8a6429115ae.png",
            "CreatedDate": "2020-10-13T14:52:16.263",
            "UpdatedDate": "2020-10-13T14:52:16.263",
            "UserRoles": [
                {
                    "UserId": 1,
                    "RoleId": 1,
                    "RoleName": "admin"
                }
            ]
        },
        "2": {
            "Id": 2,
            "Login": "demouser",
            "FirstName": "Demo",
            "LastName": "User",
            "FullName": "Demo User",
            "Email": "demouser@example.com",
            "IconPath": "http://boardchat.staturedev.com/attachments/image_c0351bf36f9746299de74588174f2f85.png",
            "CreatedDate": "2020-10-19T08:52:08.3511624",
            "UpdatedDate": "2020-11-03T13:00:01.1728788",
            "UserRoles": [
                {
                    "UserId": 2,
                    "RoleId": 2,
                    "RoleName": "user"
                }
            ]
        }
    },
    "GetRoles": {
        "Items": [
            {
                "Id": 1,
                "Name": "admin"
            },
            {
                "Id": 2,
                "Name": "user"
            }
        ]
    },
    "SaveAvatar": {
        "IconPath": "http://localhost:4200/image.png"
    },
    "GetUserProfile": {
        "1": {
            "UserID": 1,
            "FirstName": "Admin",
            "LastName": "User1",
            "Email": "admin@example.com",
            "IconPath": "http://boardchat.staturedev.com/attachments/image_fba16393af9b460cbfb6d8a6429115ae.png",
            "IsAdmin": false
        }
    },
    "2": {
        "UserID": 2,
        "FirstName": "Demo",
        "LastName": "User",
        "Email": "demouser@example.com",
        "IconPath": "http://boardchat.staturedev.com/attachments/image_c0351bf36f9746299de74588174f2f85.png",
        "IsAdmin": false
    }
}

export default Moq;
