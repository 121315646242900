import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomTooltipTemplateComponent } from './components';

import {
    ClickOutsideDirective,
    CustomTooltipDirective,
    OnErrorTemplateDirective,
    BlurredLoaderDirective,
    CharacterCountDirective,
} from './directives';

@NgModule({
    declarations: [
        ClickOutsideDirective,
        CustomTooltipDirective,
        CustomTooltipTemplateComponent,
        OnErrorTemplateDirective,
        BlurredLoaderDirective,
        CharacterCountDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ClickOutsideDirective,
        CustomTooltipDirective,
        OnErrorTemplateDirective,
        BlurredLoaderDirective,
        CharacterCountDirective
    ]
})
export class DirectivesModule { }
