import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentModel } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-environment-config',
    templateUrl: './environment-config.component.html',
    styleUrls: ['./environment-config.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentConfigComponent {
    readonly data: EnvironmentModel = environment;
}
