import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthFeature } from '@shared/store';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(
        private readonly store$: Store
    ) { }

    ngOnInit(): void {
        this.store$.dispatch(new AuthFeature.UserLogout());
    }
}
