import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { NewLoginReturnModel } from '@api/models/new-login';
import { NewLoginApiService } from '@api/services/new-login-api.service';

import { ApiActionBase } from '@shared/models';

import { LoggedUser } from '../models/logged-user';

export namespace AuthFeature {
    export const FeatureKey: string = 'auth';

    //#region TYPES

    export enum UserLoggedStatus {
        LoggedOut,
        LoggedIn,
        Logging
    }

    export enum UserLogoutReason {
        None
    }

    export interface StateModel {
        user: LoggedUser;
        status: UserLoggedStatus;
        authTicket: string;
        authTicketExpiresAt: Date;
        redirectUrl?: string;
        isDemoMode?: boolean;
    }

    //#endregion

    //#region ACTIONS

    export class SetUserStatus {
        public static readonly type = `@${FeatureKey}/SetUserStatus`;
        constructor(
            public readonly status: UserLoggedStatus
        ) { }
    }

    export class SetRedirectUrl {
        public static readonly type = `@${FeatureKey}/SetRedirectUrl`;
        constructor(
            public readonly redirectUrl: string = null
        ) { }
    }

    export class SetAuthToken {
        public static readonly type = `@${FeatureKey}/SetAuthToken`;
        constructor(
            public readonly token: string,
            public readonly expiration: Date
        ) { }
    }

    export class UserLogout {
        public static readonly type = `@${FeatureKey}/UserLogout`;
        constructor(
            public readonly reason: UserLogoutReason.None = UserLogoutReason.None
        ) { }
    }

    export class SetDemoMode {
        public static readonly type = `@${FeatureKey}/SetDemoMode`;
        constructor(
            public readonly isDemo: boolean = true
        ) { }
    }

    //#endregion

    //#region ASYNC ACTIONS

    export class LoginWithMSTokenAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/LoginWithMSTokenAsync`;
        constructor(
            public readonly accessToken: string
        ) {
            super();
        }
    }

    export class LoginWithTokenAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/LoginWithTokenAsync`;
        constructor(
            public readonly token: string
        ) {
            super();
        }
    }

    export class LoginWithPasswordAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/LoginWithPasswordAsync`;
        constructor(
            public readonly login: string,
            public readonly password: string
        ) {
            super();
        }
    }

    //#endregion
}

@State<AuthFeature.StateModel>({
    name: AuthFeature.FeatureKey,
    defaults: {
        user: null,
        authTicket: null,
        authTicketExpiresAt: null,
        status: AuthFeature.UserLoggedStatus.LoggedIn,
        isDemoMode: false
    }
})
@Injectable()
export class AuthStateService {

    //#region SELECTORS

    @Selector([AuthStateService])
    public static status(state: AuthFeature.StateModel) {
        return state.status;
    }

    @Selector([AuthStateService])
    public static token(state: AuthFeature.StateModel) {
        return state.authTicket;
    }

    @Selector([AuthStateService])
    public static ticket(state: AuthFeature.StateModel) {
        return state.authTicket;
    }

    @Selector([AuthStateService])
    public static user(state: AuthFeature.StateModel) {
        if (state.status === AuthFeature.UserLoggedStatus.LoggedIn) {
            return state.user;
        }
        return null;
    }

    @Selector([AuthStateService])
    public static redirectUrl(state: AuthFeature.StateModel) {
        return state.redirectUrl;
    }

    @Selector([AuthStateService])
    public static isDemoMode(state: AuthFeature.StateModel) {
        return !!state.isDemoMode;
    }

    //#endregion

    constructor(
        private readonly newLoginApiService: NewLoginApiService,
        private readonly router: Router,
        private readonly ngZone: NgZone
    ) {
    }

    //#region EFFECTS

    @Action(AuthFeature.LoginWithMSTokenAsync)
    public loginWithMSTokenEffect(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.LoginWithMSTokenAsync) {
        return this.newLoginApiService.msLogin({ AccessToken: action.accessToken }).pipe(
            this.handleLogin(ctx),
            tap(() => {
                const returnUrl = AuthStateService.redirectUrl(ctx.getState()) || '';
                this.ngZone.run(() => this.router.navigateByUrl(returnUrl));
            })
        );
    }

    @Action(AuthFeature.LoginWithPasswordAsync)
    public loginWithPasswordEffect(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.LoginWithPasswordAsync) {
        return this.newLoginApiService.vueLogin({ Login: action.login, Password: action.password }).pipe(
            this.handleLogin(ctx)
        );
    }

    @Action(AuthFeature.LoginWithTokenAsync)
    public loginWithTokenEffect(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.LoginWithTokenAsync) {
        return ctx.dispatch(new AuthFeature.SetAuthToken(action.token, null)).pipe(
            mergeMap(() => this.newLoginApiService.getLoginInfo()),
            this.handleLogin(ctx)
        );
    }

    //#endregion

    //#region REDUCERS

    @Action(AuthFeature.SetRedirectUrl)
    public setRedirectUrl(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.SetRedirectUrl) {
        ctx.setState({ ...ctx.getState(), redirectUrl: action.redirectUrl });
    }

    @Action(AuthFeature.SetAuthToken)
    public setAuthToken(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.SetAuthToken) {
        ctx.setState({ ...ctx.getState(), authTicket: action.token, authTicketExpiresAt: action.expiration });
    }

    @Action(AuthFeature.SetUserStatus)
    public setUserStatus(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.SetUserStatus) {
        ctx.setState({ ...ctx.getState(), status: action.status });
    }

    @Action(AuthFeature.UserLogout)
    public userLogout(ctx: StateContext<AuthFeature.StateModel>) {
        ctx.setState({
            ...ctx.getState(),
            user: null,
            status: AuthFeature.UserLoggedStatus.LoggedOut,
            authTicket: null
        });
        this.ngZone.run(() => this.router.navigateByUrl('/login'));
    }

    @Action(AuthFeature.SetDemoMode)
    public setDemoMode(ctx: StateContext<AuthFeature.StateModel>, action: AuthFeature.SetDemoMode) {
        const state = ctx.getState();
        ctx.setState({ ...state, isDemoMode: action.isDemo });
    }

    //#endregion

    //#region HELPERS

    private handleLogin(ctx: StateContext<AuthFeature.StateModel>) {
        return (source: Observable<NewLoginReturnModel>) => {
            return source.pipe(
                tap(response => {
                    const loggedUser: LoggedUser = { ...response, Login: response.Email };
                    ctx.setState({
                        ...ctx.getState(),
                        user: loggedUser,
                        status: loggedUser ? AuthFeature.UserLoggedStatus.LoggedIn : AuthFeature.UserLoggedStatus.LoggedOut,
                        authTicket: loggedUser.AuthTicket,
                    });
                    // NOTE: keep this separated so handlers can store token to local storage
                    ctx.dispatch(new AuthFeature.SetAuthToken(loggedUser.AuthTicket, loggedUser.AuthTicketExpiresAt));
                }),
                catchError(error => {
                    ctx.dispatch(new AuthFeature.UserLogout());
                    return throwError(error);
                })
            );
        };
    }

    //#endregion
}
