import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { GetPatientInformationModelItem } from '@api/models/annotation';

@Component({
    selector: 'app-case-info',
    templateUrl: './case-info.component.html',
    styleUrls: ['./case-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseInfoComponent implements OnInit {

    @Input() loadedPatientInformation: Array<GetPatientInformationModelItem> = [{ Key: 'Key', Value: 'Value' }];

    @Input() loadedDocumentId: string = 'DOCUMENT ID';

    constructor() { }

    ngOnInit(): void {
    }

}
