import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthFeature, AuthStateService } from '@shared/store';

@NgModule()
@Injectable()
export class AdminGuard implements CanActivate {

    @Select(AuthStateService.status) status$: Observable<AuthFeature.UserLoggedStatus>;

    constructor(
        private readonly router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.status$.pipe(
            filter(status => status !== AuthFeature.UserLoggedStatus.Logging),
            map(status => {
                if (status === AuthFeature.UserLoggedStatus.LoggedOut) {
                    return this.router.parseUrl('');
                }

                //TODO: implement
                return true;
            })
        );
    }
}
