import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ControlErrorComponent } from './components';
import {
    ControlErrorContainerDirective,
    ControlErrorsDirective,
    FormSubmitDirective,
    FocusableDirective,
    FocusableHostDirective,
} from './directives';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    declarations: [
        ControlErrorComponent,
        ControlErrorContainerDirective,
        ControlErrorsDirective,
        FormSubmitDirective,
        FocusableDirective,
        FocusableHostDirective
    ],
    providers: [

    ],
    exports: [
        ControlErrorComponent,
        ControlErrorContainerDirective,
        ControlErrorsDirective,
        FormSubmitDirective,
        FocusableDirective,
        FocusableHostDirective
    ],
    entryComponents: [
        ControlErrorComponent
    ]
})
export class FormValidationModule {
}
