import { AbstractControl } from '@angular/forms';

export function notEmptyListValidator(control: AbstractControl): { [key: string]: any } {
    let value = control.value;

    if (value && value.length) {
        return null;
    } else {
        return {
            'notEmptyListValidator': {}
        }
    }
}
