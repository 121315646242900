import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

import PSPDFKit from 'pspdfkit';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { LayoutModeType } from 'pspdfkit/dist/types/typescript/enums/LayoutMode';
import { BuiltInToolbarItemType, ToolbarItem } from 'pspdfkit/dist/types/typescript/models/ToolbarItem';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ZoomModeType } from 'pspdfkit/dist/types/typescript/enums/ZoomMode';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { ContextMenuClickEventData } from '../../models/context-menu-click-event-data';
import { ContextMenuItem } from '../../models/context-menu-item';
import { ContextMenuItemType, CustomToolbarTypes } from '../../models';
import { DefaultContextMenuItems } from '../../constants';
import { InstantJSON } from 'pspdfkit/dist/types/typescript/lib/InstantJSON';

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'common-pdf-viewer',
    templateUrl: './common-pdf-viewer.component.html',
    styleUrls: ['./common-pdf-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonPdfViewerComponent implements OnInit {

    @Input() document: { blob: Blob, annotations: any };

    @Input() zoom: ZoomModeType | number;

    @Input() pageIndex: number;

    @Input() layoutMode: LayoutModeType;

    @Input() term: string;

    @Input() set toolbarTypes(value: Array<CustomToolbarTypes | BuiltInToolbarItemType>) {
        this._toolbarTypesSource.next(value);
    }

    @Input() set contextMenuItems(value: Array<ContextMenuItem>) {
        this._customContextMenuItems.next(value || []);
    }

    @Input() set displayPage(value: number) {
        this._displayPageSnapshot = value;
        this._displayPageSource.next(value || 0);
    }

    @Input() set totalPages(value: number) {
        this._loadedDocPageCountSnapshot = value;
        this._totalPagesSource.next(value >= 0 ? value : 0);
    }

    @Input() set isFullScreen(value: boolean) {
        this._isFullScreenSource.next(value);
    }

    @Input() set contextMenuTypes(value: Array<ContextMenuItemType>) {
        this._contextMenuItemTypesSource.next(value || []);
    }

    @Output() prevPageClick: EventEmitter<any> = new EventEmitter();
    @Output() nextPageClick: EventEmitter<any> = new EventEmitter();
    @Output() pageRequested: EventEmitter<number> = new EventEmitter();
    @Output() pageIndexChange: EventEmitter<number> = new EventEmitter();
    @Output() fullscreenChange: EventEmitter<boolean> = new EventEmitter();
    @Output() separatedWindowClick: EventEmitter<any> = new EventEmitter();
    @Output() annotationChange: EventEmitter<any> = new EventEmitter();
    @Output() zoomChange: EventEmitter<ZoomModeType | number> = new EventEmitter();
    @Output() layoutModeChange: EventEmitter<LayoutModeType> = new EventEmitter();
    @Output() scrollEnd: EventEmitter<any> = new EventEmitter();
    @Output() scrollStart: EventEmitter<any> = new EventEmitter();
    @Output() linkPress: EventEmitter<{ text: string, pageIndex: number }> = new EventEmitter();
    @Output() contextMenuItemClick: EventEmitter<ContextMenuClickEventData> = new EventEmitter();
    @Output() instanceChange: EventEmitter<any> = new EventEmitter();

    get documentInternal(): { blob: Blob, instantJSON: InstantJSON } {
        return this.document ? { blob: this.document.blob, instantJSON: this.document.annotations } : null;
    }

    // STREAMS
    toolbarItems$: Observable<Array<ToolbarItem>>;
    isFullscreen$: Observable<boolean>;
    contextMenuItems$: Observable<Array<ContextMenuItem>>;
    private readonly _manualToolbarItemsSource = new Subject<Array<ToolbarItem>>();
    private readonly _toolbarTypesSource = new BehaviorSubject<Array<CustomToolbarTypes | BuiltInToolbarItemType>>(null);
    private readonly _isFullScreenSource = new BehaviorSubject<boolean>(false);
    private readonly _totalPagesSource = new BehaviorSubject<number>(0);
    private readonly _displayPageSource = new BehaviorSubject<number>(1);
    private readonly _contextMenuItemTypesSource = new BehaviorSubject<Array<ContextMenuItemType>>(['copy']);
    private readonly _customContextMenuItems = new BehaviorSubject<Array<ContextMenuItem>>([]);
    // SNAPSHOTS
    private _loadedDocPageCountSnapshot: number = 0;
    private _displayPageSnapshot: number = 1;
    private _toolbarTypesSnapshot: Array<CustomToolbarTypes | BuiltInToolbarItemType>;

    constructor(
        private readonly ngZone: NgZone
    ) { }

    //#region  HANDLERS

    ngOnInit(): void {
        // INIT FIELDS
        this.toolbarItems$ = merge(
            combineLatest([this._displayPageSource, this._totalPagesSource, this._toolbarTypesSource]).pipe(
                map(([page, count, types]) => this.getToolbarItems(page, count, types))
            ),
            this._manualToolbarItemsSource
        );
        this.isFullscreen$ = this._isFullScreenSource.asObservable();

        this.contextMenuItems$ = combineLatest([
            this._contextMenuItemTypesSource.pipe(map(types => {
                const typeMap = types.reduce((agg, next) => { agg[next] = true; return agg; }, {});
                return DefaultContextMenuItems.filter(x => typeMap[x.type]);
            })),
            this._customContextMenuItems.pipe(
                startWith([])
            )
        ]).pipe(map(([x1, x2]) => [...x1, ...x2]));


        // INIT SUBSCRIPTIONS
        this._isFullScreenSource.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(x => this.fullscreenChange.emit(x));
        this._toolbarTypesSource.pipe(untilDestroyed(this)).subscribe(x => this._toolbarTypesSnapshot = x);
    }

    onStateChange() {

    }

    //#endregion


    //#region HELPERS

    private getToolbarItems(page: number, total: number, types?: Array<CustomToolbarTypes | BuiltInToolbarItemType>): Array<ToolbarItem> {
        const toolbarItems: Array<ToolbarItem> = [];
        const typeMap = types?.length ? types.reduce((agg, next) => {
            agg[next] = true;
            return agg;
        }, {} as any) : null;

        if (!typeMap || typeMap[CustomToolbarTypes.Page]) {
            const titleToolbarItem: ToolbarItem = {
                id: CustomToolbarTypes.Page,
                type: 'custom',
                title: 'Page',
                className: CustomToolbarTypes.Page
            };
            toolbarItems.push(titleToolbarItem);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.PreviousPage]) {
            const previousPageToolbarItem: ToolbarItem = {
                id: CustomToolbarTypes.PreviousPage,
                type: 'custom',
                title: '<',
                icon: '/assets/pictures/left_triangle.png',
                className: CustomToolbarTypes.PreviousPage,
                disabled: page <= 1,
                onPress: (event: MouseEvent) => this.ngZone.run(() => this.prevPageClick.emit(null))
            };
            toolbarItems.push(previousPageToolbarItem);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.CurrentPage]) {
            const currentPageToolbarItem: ToolbarItem = {
                id: CustomToolbarTypes.CurrentPage,
                type: 'custom',
                node: this.createToolbarPageNode(page),
                title: (page || 0).toString(),
                className: CustomToolbarTypes.CurrentPage
            };
            toolbarItems.push(currentPageToolbarItem);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.NextPage]) {
            const nextPageToolbarItem: ToolbarItem = {
                id: CustomToolbarTypes.NextPage,
                type: 'custom',
                title: '>',
                disabled: page >= total,
                icon: '/assets/pictures/right_triangle.png',
                onPress: (event: MouseEvent) => this.ngZone.run(() => this.nextPageClick.emit(null)),
                className: CustomToolbarTypes.NextPage,
            };
            toolbarItems.push(nextPageToolbarItem);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.TotalPages]) {

            const totalPagesToolbarItem: ToolbarItem = {
                id: CustomToolbarTypes.TotalPages,
                type: 'custom',
                title: `of ${total}`,
                className: CustomToolbarTypes.TotalPages,
            };
            toolbarItems.push(totalPagesToolbarItem);
        }

        const defaultToolbarItemTypesMap: { [key in PropertyType<ToolbarItem, 'type'>]?: boolean } = {
            ['pan']: true,
            ['zoom-out']: true,
            ['zoom-in']: true,
            ['zoom-mode']: true,
            ['layout-config']: true,
            ['highlighter']: true,
        };

        toolbarItems.push(...PSPDFKit.defaultToolbarItems.filter(({ type }) => defaultToolbarItemTypesMap[type] || typeMap && typeMap[type]));

        if (defaultToolbarItemTypesMap['layout-config']) {
            toolbarItems.push({ type: 'layout-config' });
        }

        if (!typeMap || typeMap[CustomToolbarTypes.Spacer]) {
            const spacer: ToolbarItem = {
                type: 'spacer',
                className: CustomToolbarTypes.Spacer,
            };

            toolbarItems.push(spacer);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.Fullscreen]) {

            const fullscreenButton: ToolbarItem = {
                id: CustomToolbarTypes.Fullscreen,
                type: 'custom',
                title: 'Toggle fullscreen mode',
                icon: '/assets/pictures/toolbar_fullscreen.png',
                className: CustomToolbarTypes.Fullscreen,
                onPress: (event: MouseEvent) => this.ngZone.run(() => this._isFullScreenSource.next(!this._isFullScreenSource.getValue()))
            };
            toolbarItems.push(fullscreenButton);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.Home]) {
            const homeButton: ToolbarItem = {
                id: CustomToolbarTypes.Home,
                type: 'custom',
                title: 'Go to the first page',
                icon: '/assets/pictures/toolbar_home.png',
                className: CustomToolbarTypes.Home,
                onPress: (event: MouseEvent) => this.ngZone.run(() => this.pageRequested.emit(1))
            };
            toolbarItems.push(homeButton);
        }

        if (!typeMap || typeMap[CustomToolbarTypes.SeparatedWindow]) {
            const separatedWindow: ToolbarItem = {
                id: CustomToolbarTypes.SeparatedWindow,
                type: 'custom',
                title: 'Open in separated window',
                icon: '/assets/pictures/window-popup.png',
                className: CustomToolbarTypes.SeparatedWindow,
                onPress: (event: MouseEvent) => this.ngZone.run(() => this.separatedWindowClick.emit(true))
            };
            toolbarItems.push(separatedWindow);
        }

        return toolbarItems;
    }

    private createToolbarPageNode(page: number): Node {
        const node = document.createElement('input');

        node.addEventListener('blur', (event: FocusEvent) => {
            this.ngZone.run(() => this.onBlurToolbarPage(event));
        });
        node.addEventListener('keydown', (event: KeyboardEvent) => {
            this.ngZone.run(() => this.onKeyDownToolbarPage(event));
        });
        node.value = page.toString();
        node.setAttribute('type', 'number');

        return node;
    }

    private onBlurToolbarPage(event: FocusEvent) {
        const value = (event.target as HTMLInputElement)?.value;
        const valueInt = parseInt(value, 10);
        if (valueInt > 0 && valueInt <= this._loadedDocPageCountSnapshot) {
            //(event.target as HTMLInputElement).disabled = true;
            this.pageRequested.emit(valueInt);
        } else {
            this._manualToolbarItemsSource.next(this.getToolbarItems(this._displayPageSnapshot, this._loadedDocPageCountSnapshot, this._toolbarTypesSnapshot));
        }
    }

    private onKeyDownToolbarPage(event: KeyboardEvent) {
        if (event.which !== 13) {
            return;
        }

        const value = (event.target as HTMLInputElement)?.value;
        const valueInt = parseInt(value, 10);

        if (valueInt > 0 && valueInt <= this._loadedDocPageCountSnapshot) {
            //(event.target as HTMLInputElement).disabled = true;
            this.pageRequested.emit(valueInt);
        } else {
            this._manualToolbarItemsSource.next(this.getToolbarItems(this._displayPageSnapshot, this._loadedDocPageCountSnapshot, this._toolbarTypesSnapshot));
        }
    }

    //#endregion
}
