import { Directive, EventEmitter, ElementRef, DoCheck, AfterViewInit, Input, TemplateRef, ViewContainerRef, Renderer2, OnInit, EmbeddedViewRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[formControl][lengthTemplate], [formControlName][lengthTemplate]' })
export class CharacterCountDirective implements OnInit {

	@Input('lengthTemplate') set templateRef(templateRef: TemplateRef<any> | null) {
		this._lengthTemplate = templateRef;
	}

	@Input() set maxDisplayLength(value: number) {
		this._maxLength = value;
		this.updateView(this._length);
	}

	lengthChanged: EventEmitter<number> = new EventEmitter();

	private _lengthTemplate: TemplateRef<any> | null;
	private _defaultRef: any;
	private _maxLength: number | undefined;
	private _length: number = 0;
	private _lengthView: EmbeddedViewRef<any> | null = null;

	constructor(
		private _elementRef: ElementRef<HTMLElement>,
		private _control: NgControl,
		private _viewContainer: ViewContainerRef,
		private _renderer: Renderer2
	) {
	}

	ngOnInit(): void {
		if (this._control) {
			this._control.valueChanges.subscribe(value => {
				const length = this.countLength(value);
				this._length = length;
				this.lengthChanged.next(length);
				this.updateView(length);
			});
			const initialLength = this.countLength(this._control.value);
			this._length = length;
			this.updateView(initialLength);
		} else {
			this.updateView(0);
		}
	}

	private countLength(value: string | undefined): number {
		return (value || '').length;
	}

	private updateView(length: number) {
		if (this._lengthTemplate) {
			if (this._lengthView) {
				this._lengthView.destroy();
			}
			this._lengthView = this._viewContainer.createEmbeddedView(this._lengthTemplate, { $implicit: { length, max: this._maxLength } });
			// update custom tempalte here
		} else {
			// udpate default tempalte here
			if (this._defaultRef) {
				this.updateDefaultTemplate(length, this._maxLength);
			} else {
				this.renderDefaultTemplate(length, this._maxLength);
			}

		}
		// render
	}

	private renderDefaultTemplate(length: number, max?: number) {
		this._defaultRef = this._renderer.createElement('p');
		this._renderer.addClass(this._defaultRef, 'length-counter-wrapper');
		const text = max ? this._renderer.createText(`${length}/${max}`) : this._renderer.createText(length.toString());
		this._renderer.appendChild(this._defaultRef, text);
		this._renderer.appendChild(this._elementRef.nativeElement.parentNode, this._defaultRef);
	}

	private updateDefaultTemplate(length: number, max?: number) {
		const text = max ? `${length}/${max}` : length.toString();
		(this._defaultRef as HTMLElement).innerText = text;
		const isInvalid = length >= max;
		if (isInvalid) {
			if (!(this._defaultRef as HTMLElement).classList.contains('invalid')) {
				(this._defaultRef as HTMLElement).classList.add('invalid');
			}
		} else {
			(this._defaultRef as HTMLElement).classList.remove('invalid');
		}
	}

	private removeDefaultTemplate() {
		this._renderer.removeChild((this._elementRef.nativeElement as HTMLElement).parentNode, this._defaultRef);
	}
}
