
import { Injectable } from '@angular/core';

import { State, Action, StateContext, Selector, ActionType, createSelector } from '@ngxs/store';

import { PDFPreferencesFeature, PDFViewerPreferencesStateService } from './pdf-viewer-preferences-state.service';

export namespace SharedFeature {
    export const FeatureKey: string = 'shared';

    //#region TYPES

    export interface ActivePageModel {
        page: string;
    }

    export interface StateModel {
        moduleLoadingMap: { [key in string]: boolean };
        apiLoadingMap: { [key in string]: number };
        searchTerm: string;
        isPendingChangesModalOpen: boolean;
        isSideMenuExpanded: boolean;
        isSideMenuTogglerVisible: boolean;
        activePages: Array<string>;
        pdfPreferences: PDFPreferencesFeature.StateModel;
    }

    //#endregion

    //#region ACTIONS

    export class SetModuleIsLoading {
        public static readonly type = `@${FeatureKey}/SetModuleIsLoading`;
        readonly moduleName: string;
        readonly isLoading: boolean;
        constructor(config: { moduleName: string, isLoading: boolean }) {
            this.moduleName = config.moduleName;
            this.isLoading = config.isLoading;
        }
    }

    export class SetApiLoading {
        public static readonly type = `@${FeatureKey}/SetApiLoading`;
        public readonly actionTypeSets: Array<{ actionTypes: Array<string>, isLoading: boolean }>;
        constructor(...actionTypeSets: Array<{ actionTypes: Array<string>, isLoading: boolean }>) {
            this.actionTypeSets = actionTypeSets;
        }
    }

    export class SetSearchTerm {
        public static readonly type = `@${FeatureKey}/SetSearchTerm`;
        constructor(public readonly searchTerm?: string) { }
    }

    export class SetPendingChangeModalOpenState {
        public static readonly type = `@${FeatureKey}/SetPendingChangeModalOpenState`;
        constructor(public readonly isOpen: boolean) {

        }
    }

    export class ToggleSideMenu {
        public static readonly type = `@${FeatureKey}/ToggleSideMenu`;
        constructor() { }
    }

    export class SetSideMenuTogglerVisibility {
        public static readonly type = `@${FeatureKey}/SetSideMenuTogglerVisibility`;
        constructor(
            public readonly isVisible: boolean
        ) { }
    }

    export class SetActivePages {
        public static readonly type = `@${FeatureKey}/SetActivePages`;
        constructor(
            public readonly pages: Array<string> = []
        ) { }
    }

    //#endregion

}

@State<SharedFeature.StateModel>({
    name: SharedFeature.FeatureKey,
    defaults: {
        moduleLoadingMap: {},
        apiLoadingMap: {},
        searchTerm: '',
        isPendingChangesModalOpen: false,
        isSideMenuExpanded: true,
        isSideMenuTogglerVisible: false,
        activePages: [],
        pdfPreferences: null
    },
    children: [PDFViewerPreferencesStateService]
})
@Injectable()
export class SharedStateService {

    //#region SELECTORS

    @Selector([SharedStateService])
    public static modulesLoading(state: SharedFeature.StateModel) {
        return !!Object.keys(state.moduleLoadingMap).length;
    }

    @Selector([SharedStateService])
    public static apiLoadingMap(state: SharedFeature.StateModel) {
        return state.apiLoadingMap;
    }

    @Selector([SharedStateService.apiLoadingMap])
    public static isApiLoading(isLoadingMap: { [key in string]: number }) {
        return (...types: Array<ActionType>) => {
            const actionTypeMap: { [key in string]: boolean } = {};
            for (let actionType of (types || [])) {
                actionTypeMap[actionType.type] = true;
            }
            return Object.keys(isLoadingMap).filter(key => key in actionTypeMap).some(key => isLoadingMap[key] > 0);
        };
    }

    @Selector([SharedStateService])
    public static searchTerm(state: SharedFeature.StateModel) {
        return state.searchTerm;
    }

    @Selector([SharedStateService])
    public static isPendingChangesModalOpen(state: SharedFeature.StateModel): boolean {
        return state.isPendingChangesModalOpen;
    }

    @Selector([SharedStateService])
    public static isSideMenuExpanded(state: SharedFeature.StateModel) {
        return state.isSideMenuExpanded;
    }

    @Selector([SharedStateService])
    public static isSideMenuTogglerVisible(state: SharedFeature.StateModel) {
        return state.isSideMenuTogglerVisible;
    }

    @Selector([SharedStateService])
    public static activePagesInternal(state: SharedFeature.StateModel) {
        return state.activePages;
    }

    public static isPageActive(pageName: string) {
        return createSelector([SharedStateService.activePagesInternal], (activePages: Array<string>) => {
            return (activePages || []).some(x => x === pageName);
        });
    }

    //#endregion

    //#region REDUCERS

    @Action(SharedFeature.SetModuleIsLoading)
    public setModuleLoading(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.SetModuleIsLoading) {
        const state = ctx.getState();
        const moduleIsLoadingMap = { ...state.moduleLoadingMap };
        if (action.isLoading) {
            moduleIsLoadingMap[action.moduleName] = true;
        } else {
            delete moduleIsLoadingMap[action.moduleName];
        }

        ctx.setState({ ...state, moduleLoadingMap: moduleIsLoadingMap });
    }

    @Action(SharedFeature.SetApiLoading)
    public setApiLoading(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.SetApiLoading) {
        const state = { ...ctx.getState() };
        state.apiLoadingMap = { ...state.apiLoadingMap };
        for (const actionTypeSet of action.actionTypeSets) {
            for (const actionType of actionTypeSet.actionTypes) {
                if (actionTypeSet.isLoading) {
                    if (!state.apiLoadingMap[actionType]) {
                        state.apiLoadingMap[actionType] = 0;
                    }
                    state.apiLoadingMap[actionType]++;
                } else {
                    if (state.apiLoadingMap[actionType]) {
                        state.apiLoadingMap[actionType]--;
                    }
                    if (!state.apiLoadingMap[actionType]) {
                        delete state.apiLoadingMap[actionType];
                    }
                }
            }

        }
        ctx.setState(state);
    }

    @Action(SharedFeature.SetSearchTerm)
    public setSearchTerm(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.SetSearchTerm) {
        ctx.setState({ ...ctx.getState(), searchTerm: action.searchTerm });
    }

    @Action(SharedFeature.SetPendingChangeModalOpenState)
    public setPendingChangeModalOpenState(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.SetPendingChangeModalOpenState) {
        ctx.setState({ ...ctx.getState(), isPendingChangesModalOpen: action.isOpen });
    }

    @Action(SharedFeature.ToggleSideMenu)
    public toggleSideMenu(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.ToggleSideMenu) {
        const state = ctx.getState();
        ctx.setState({ ...state, isSideMenuExpanded: !state.isSideMenuExpanded });
    }

    @Action(SharedFeature.SetSideMenuTogglerVisibility)
    public setSideMenuTogglerVisibility(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.SetSideMenuTogglerVisibility) {
        ctx.setState({ ...ctx.getState(), isSideMenuTogglerVisible: action.isVisible });
    }

    @Action(SharedFeature.SetActivePages)
    public setActivePages(ctx: StateContext<SharedFeature.StateModel>, action: SharedFeature.SetActivePages) {
        const state = ctx.getState();
        const isChanged: boolean = true;
        if (isChanged) {
            ctx.setState({ ...state, activePages: action.pages || [] });
        }
    }

    //#endregion
}
