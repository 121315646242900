import { UserProfileModel } from './UserProfileModel';


export class UserUpdateProfileModel extends UserProfileModel {
    OldPassword: string;
    NewPassword: string;
    ConfirmNewPassword: string;

    constructor(userProfile?: UserProfileModel) {
        super();

        if (userProfile) {
            this.UserID = userProfile.UserID;
            this.FirstName = userProfile.FirstName;
            this.LastName = userProfile.LastName;
            this.Email = userProfile.Email;
            this.Description = userProfile.Description;
            this.IconPath = userProfile.IconPath;
        }
    }
}
