import { compare } from "./compare";

export function min(dates: Array<Date>) {
    let _minDate: Date = null;

    for (let date of dates) {
        if (!_minDate) {
            _minDate = date;
        } else if (compare(date, _minDate) < 0) {
            _minDate = date;
        }
    }

    return _minDate;
}
