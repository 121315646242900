import { Pipe, PipeTransform } from '@angular/core';

const vowels = 'aeiou';
const consonants = 'bcdfghjklmnpqrstvwxyz';

@Pipe({ name: 'shorten' })
export class ShortenPipe implements PipeTransform {

    public transform(value: string | Array<string> | unknown, length: number = 2, split: boolean = false, mode?: 'vowel' | 'consonant' | null): string {
        let source: Array<string> = [];

        if (typeof value === 'string') {
            source.push(value);
        } else if (value instanceof Array) {
            source.push(...value.filter(x => typeof x === 'string'));
        } else {
            return value?.toString().slice(0, length);
        }

        let result: Array<string> = source.map(_ => '');
        let totalLength: number = source.reduce((agg, next) => agg += next.length, 0);

        if (totalLength <= length) {
            result = source;
        } else {
            let nextIndexes: Array<number> = source.map(x => 0);
            let currentArrayIndex: number = 0;

            while (result.reduce((agg, next) => agg += next.length, 0) < length && nextIndexes.reduce((agg, next) => agg += next, 0) < totalLength) {
                if (source[currentArrayIndex].length > nextIndexes[currentArrayIndex]) {
                    result[currentArrayIndex] += source[currentArrayIndex][nextIndexes[currentArrayIndex]];
                    nextIndexes[currentArrayIndex]++;
                }
                currentArrayIndex = currentArrayIndex === source.length - 1 ? 0 : currentArrayIndex + 1;
            }
        }

        return result.reduce((agg, next) => agg += split ? ' ' + next : next, "").trim();
    }

    private getType(char: string): 'vowel' | 'consonant' | 'unknown' {
        if (char.length === 1) {
            const isVowel = vowels.indexOf(char) >= 0 ? true : false;
            if (isVowel) {
                return 'vowel';
            }

            const isConsonant = consonants.indexOf(char) >= 0 ? true : false;

            if (isConsonant) {
                return 'consonant';
            }

            return 'unknown';
        }
    }
}
