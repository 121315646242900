// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentModel } from "src/app/models";

export const environment: EnvironmentModel = {
    production: false,
    staging:  false,
    psPDFKitLicenceKey: 'jHrjXK6YJ4QWCuYNLZ3beU4b7xDIpV4L3zEyU4J86bvaPxfuBx8oI4uCxB5o2nVwoZh3uG4F9UHTPG3qFFXebLoiyZ4whtc6VnACRymAsBowux_Lmr9GFC6LC3Jmqre2PKXft5eje3C379IwEZ6oCCrw6b9oVUu_L4KSU_onfxI-d6-qPmvub5BSJJZIaS1IkHMismcytF8F2C6AeHZYcHKf4owa0Oe4KTpnghRT4u41Ox4eETwGD8drTyMDLIS4oKDLQgQcIj8H78oAakx8JOXl9_qvnVzzfPA9w9gfpKOKafys5MYP2vsV5wsNWA-AYppDOgag4KexP1z8XaoH-Spu0FvV98ZiCBHgQ0D8DvSveETAk2oMaJWK1ZHAZmL_ZHuyHUR6YRwKmRHvyCcH-N_jnUHzRSYewNLv1WD_xnsTj9l7KVOsRWcNRBxE-L8eAVNbd8x67gBWjHI1-8cL6BN70jQ9IkkYS3oFpF7qpRVbM4TD9ocSTJvQoD-llEbUA04iLq9RLRJAFUUcz4jvMtxlSIdGB1W84cNldlskZHLgFa2gmnlzyCF2tsXF81Wd',
    apiUrl: 'http://localhost:58652',
    edexCloudUrl: 'https://arcuityai.edexcloud.com/',
    oAuth: {
        clientId: 'bf4e5947-4bfb-43e2-8bfb-23b7a22ca212', // This is your client ID
        authority: 'https://login.microsoftonline.com/cb4a93b9-7b44-4a5e-ae66-8eef5c07b812', //'Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here', // This is your tenant ID
        redirectUri: 'http://localhost:4200/',// This is your redirect URI,
        consentScopes: ['user.read', 'openid', 'profile'],
        protectedResourceMap: <[string, string[]][] | Map<string, Array<string>>>[
            ['https://graph.microsoft.com/v1.0/me', ['user.read']]
        ]
    },
    // NOTE: auto computed
    clientBuildInfo: { version: 'DEV', buildDate: (new Date()).toString() }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
