import { API_DATE_FORMAT } from "src/app/constants";
import { DateFormatterService } from "src/app/models";

export class BaseApiService {

    constructor(
        private readonly dateFormatterService: DateFormatterService
    ) { }

    public getFileNameFromHttpResponse(httpResponse: any): string {
        let contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        return result.replace(/"/g, '');
    }

    public encodeQueryData(data: any, prefix?: string) {
        let ret: any[] = [];
        this.encodeQueryDataInner(ret, data, prefix);
        return ret.join('&');
    }

    private encodeQueryDataInner(ret: string[], data: any, prefix?: string) {
        let getParamName = (name: string): string => (prefix || '').trim() !== '' ? prefix + '.' + encodeURIComponent(name) : encodeURIComponent(name);

        for (let d in data) {
            if (data[d] instanceof Date) {
                ret.push(getParamName(d) + '=' + this.dateFormatterService.format(data[d], API_DATE_FORMAT));
            } else if (Array.isArray(data[d])) {
                data[d].forEach((x: any, index: number) => {
                    ret.push(`${getParamName(d)}[${index}]=${x}`);
                });
            } else if (typeof data[d] === 'object') {
                this.encodeQueryDataInner(ret, data[d], d);
            } else if (data[d] !== undefined) {
                ret.push(getParamName(d) + '=' + data[d]);
            }
        }
    }
}
