import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { map } from 'rxjs/operators';

import { ModalService, RouterHistoryTrackerService } from '../services';

export interface ComponentHasPendingChanges {
    hasPendingChanges: () => boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentHasPendingChanges> {

    constructor(
        private readonly historyService: RouterHistoryTrackerService,
        private readonly modalService: ModalService,
    ) {
    }

    canDeactivate(component: ComponentHasPendingChanges, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        // CanDeactivate Guard breaks navigation history, used to prevent this
        this.historyService.setGuardInvoked(true);

        if (!component?.hasPendingChanges) {
            return true;
        }

        const hasUnsaved = component.hasPendingChanges();
        if (!hasUnsaved) {
            return true;
        }

        const [result$] = this.modalService.unsavedChanges();

        return result$;
    }
}
