import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class ClientSideAPIInterceptor implements HttpInterceptor {

    constructor(private readonly router: Router) {


    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            url: this.getFullUrl(request.url),
            //withCredentials: true
        });

        return next.handle(request);
    }

    private getFullUrl(url: string) {
        // console.log('getFullUrl', this.router.parseUrl(url));
        const isDevServer = !('ENV_CONFIG' in window);

        if (isDevServer) {
            const isApi = url.toUpperCase().startsWith('API');
            return isApi ? `${environment.apiUrl}/${url}` : url;
        } else {
            const isFull = url.toUpperCase().startsWith('HTTP');
            return isFull ? url : `${window.location.origin}/${url}`;
        }
    }
}
