import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgModule } from '@angular/core';

import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { filter, map } from 'rxjs/operators';

import { AuthFeature, AuthStateService } from '@shared/store';

@NgModule()
@Injectable()
export class LoginGuard implements CanActivate {

    @Select(AuthStateService.status) status$: Observable<AuthFeature.UserLoggedStatus>;

    constructor(
        private readonly router: Router,
        private readonly store$: Store) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.status$.pipe(
            filter(x => x !== AuthFeature.UserLoggedStatus.Logging),
            map(status => {
                if (status === AuthFeature.UserLoggedStatus.LoggedIn) {
                    const redirectUrl = this.store$.selectSnapshot(AuthStateService.redirectUrl);
                    this.router.navigateByUrl(redirectUrl || '');
                }
                return (status !== AuthFeature.UserLoggedStatus.LoggedIn);
            })
        );
    }
}
