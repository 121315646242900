import { InstantJSON } from 'pspdfkit/dist/types/typescript/lib/InstantJSON';
import { Annotation } from 'pspdfkit/dist/types/typescript/models/annotations';

import { AnnotationJsonEntry, NotesTreeItem } from '@api/models/annotation';
import { TimelineItemModel } from '@api/models/timeline';

import { compare, min } from '@shared/helpers/dates';

import { SerializedAnnotation } from '../../pdf/models';

import { AnnotationData, DocumentNotesModel, PageModel, PageNoteModel, TreeNodeDataItem } from '../models';
import { SearchesFeature } from '../store';

export class CognitiveSearchDataHelper {

    public static compareAnnotationsFn(a: Pick<Annotation, 'pageIndex'>, b: Pick<Annotation, 'pageIndex'>): number {
        let comparison = 0;
        if (a.pageIndex > b.pageIndex) {
            comparison = 1;
        } else if (a.pageIndex < b.pageIndex) {
            comparison = -1;
        }
        return comparison;
    }

    public static parseNotes(annotationMap: { [key in string]: string }, loadedDocumentRecordOffset: number): Array<DocumentNotesModel> {
        let theID: number = 0;
        const result: Array<DocumentNotesModel> = [];

        let pageNotes: Array<PageNoteModel> = [];
        let pages: Array<PageModel> = [];
        let doc: string = '';
        let offset: number = 0;
        for (const [key, value] of Object.entries(annotationMap)) {

            //  if (doc==''){
            let kArray = key.split('_');
            offset = 20 * (parseInt(kArray[kArray.length - 1], 10) - 1);
            let dArray = key.split('_');
            dArray.pop();
            doc = dArray.join('_');

            //  }

            const instantJSON: InstantJSON = JSON.parse(value);

            //res=res.concat(a['annotations']);
            // console.log(`${key}:`,a);

            const annotations = (instantJSON.annotations || []) as Array<SerializedAnnotation>;
            annotations.sort(this.compareAnnotationsFn);
            let currentPage: number = 0;
            let currentDocumentPage: number = 0;
            let note: string = '';
            let createdAt = '';
            pageNotes = [];
            for (let annotation of annotations) {
                note = annotation.note;

                createdAt = annotation.createdAt;
                if (currentPage === 0) {
                    currentPage = offset + annotation.pageIndex;
                    // continue;
                }
                let post = '';
                if (currentPage > loadedDocumentRecordOffset) {
                    currentDocumentPage = currentPage - loadedDocumentRecordOffset;
                    post = 'xxx';
                } else {
                    currentDocumentPage = currentPage;
                    post = 'yyy';
                }
                theID++;
                let pageRef: string = doc + ':' + (1 + currentPage) + ':' + post;
                if (offset + annotation.pageIndex === currentPage) {
                    pageNotes.push({
                        id: theID,
                        name: `${note}\n${createdAt}`,
                        pageRef: pageRef
                    });
                } else {
                    theID++;
                    pages.push({
                        id: theID,
                        page: currentPage,
                        name: `Page ${currentDocumentPage}`,
                        children: pageNotes
                    });

                    currentPage = offset + annotation.pageIndex;
                    pageNotes = [];
                    pageNotes.push({
                        id: theID,
                        name: `${note}\n${createdAt}`,
                        pageRef: pageRef
                    });
                }
            }

            theID++;
            pages.push({
                id: theID,
                page: currentPage,
                name: `Page ${currentPage}`,
                children: pageNotes
            });
        }
        theID++;

        pages.sort(function (a, b) {
            const pA = a.page;
            const pB = b.page;
            let comparison = 0;
            if (pA > pB) {
                comparison = 1;
            } else if (pA < pB) {
                comparison = -1;
            }
            return comparison;

        });
        result.push({ id: theID, name: doc, children: pages });

        return result;
    }

    public static getGlobalPage(chunk: number, chunkSize: number, chunkPage: number): number {
        return chunk * chunkSize - chunkSize + chunkPage;
    }

    public static mapInputIntoTreeNode(instanceJSONMap: Array<AnnotationJsonEntry>, documentOffsets: { [key: string]: number }): [Array<TreeNodeDataItem<AnnotationData, string>>, number] {
        let resultCount: number = 0;
        const result: Array<TreeNodeDataItem<AnnotationData, string>> = [];

        let nodeId: number = 1;
        const documentMap: Map<string, TreeNodeDataItem<AnnotationData, string>> = new Map();

        if (!Object.keys(instanceJSONMap).length) {
            return [result, resultCount];
        }

        for (const item of Object.values(instanceJSONMap)) {
            const { Key: key, InstantJSON: instantJSON } = item;
            const parsedKey = CognitiveSearchDataHelper.parseDocumentKey(key, documentOffsets);

            if (!parsedKey) {
                continue;
            }

            if (!documentMap.has(parsedKey.documentSubId)) {
                documentMap.set(parsedKey.documentSubId,
                    {
                        id: parsedKey.documentSubId,
                        text: parsedKey.documentSubId,
                        data: { ...parsedKey, createdAt: null },
                        children: []
                    }
                );
            }

            const documentNode = documentMap.get(parsedKey.documentSubId);
            const docInstanceJSONItem: InstantJSON = JSON.parse(instantJSON);
            const annotationItems: Array<Pick<Annotation, 'note' | 'pageIndex' | 'id'> & { createdAt: string }>
                = docInstanceJSONItem['annotations'] as any;
            annotationItems.sort(CognitiveSearchDataHelper.compareAnnotationsFn);

            const docPageMap: Map<number, TreeNodeDataItem<AnnotationData, string>> = new Map();

            for (let annotationItem of annotationItems) {
                const chunkPage = annotationItem.pageIndex + 1;
                const globalPage = CognitiveSearchDataHelper.getGlobalPage(parsedKey.documentChunk, 20, chunkPage);
                const displayPage = globalPage - parsedKey.documentOffset;
                const createdAt = new Date(annotationItem.createdAt);

                if (!docPageMap.has(annotationItem.pageIndex)) {
                    docPageMap.set(annotationItem.pageIndex, {
                        id: `${documentNode.id}_${globalPage}`,
                        text: globalPage <= parsedKey.documentOffset ? 'Summary ' + globalPage : 'Page ' + displayPage,
                        data: {
                            ...documentNode.data,
                            globalPage,
                            chunkPage
                        },
                        children: []
                    });
                }

                const docPageNode = docPageMap.get(annotationItem.pageIndex);

                docPageNode.children.push({
                    id: `${docPageNode.id}_${annotationItem.id}`,
                    text: annotationItem.note,
                    data: {
                        ...docPageNode.data,
                        createdAt,
                        annotationId: annotationItem.id,
                        annotationNote: annotationItem.note
                    },
                    children: []
                });

                resultCount++;
            }

            for (let pageNode of docPageMap.values()) {
                pageNode.data.createdAt = min(pageNode.children.map(x => x.data.createdAt));
                documentNode.children.push(pageNode);
            }

            documentNode.children.sort((a, b) => {
                const pA = a.data.globalPage;
                const pB = b.data.globalPage;
                let comparison = 0;
                if (pA > pB) {
                    comparison = 1;
                } else if (pA < pB) {
                    comparison = -1;
                }
                return comparison;
            });
        }

        for (let docNode of documentMap.values()) {
            docNode.data.createdAt = min(docNode.children.map(x => x.data.createdAt));
            result.push(docNode);
        }

        return [result, resultCount];
    }

    public static parseDocumentKey(key: string, documentOffsets: { [key: string]: number }) {
        const match = (/^(.*)_(\d+)$/gm).exec(key);
        let documentSubId = match[1];
        if (!documentSubId) {
            return null;
            // throw new Error('Document Sub Id is not defined');
        }

        const documentChunk = parseInt(match[2], 10);

        if (!documentChunk || documentChunk <= 0) {
            return null;
            // throw new Error('Document Chunk is not defined');
        }

        // TODO: clarify
        const documentOffset = documentOffsets[documentSubId];

        if (!documentOffset || documentOffset < 0) {
            return null;
            // throw new Error('Document Offset is not defined');
        }

        return { documentSubId, documentChunk, documentOffset };
    }

    public static createNotesTree(instanceJSONMap: Array<AnnotationJsonEntry>, documentOffsets: { [key: string]: number }, dataUserName: string): Array<NotesTreeItem> {
        const [notesTree] = CognitiveSearchDataHelper.mapInputIntoTreeNode(instanceJSONMap, documentOffsets);

        return [].concat(...notesTree.map(x => x.children)).map(pageNode => (
            {
                DataCreatedAt: pageNode.data.createdAt,
                DataUserName: dataUserName,
                Text: 'Page ' + pageNode.data.globalPage,
                Nodes: pageNode.children.map(annotationNode => (
                    {
                        DataCreatedAt: annotationNode.data.createdAt,
                        DataUserName: dataUserName,
                        Text: annotationNode.data.annotationNote,
                        Nodes: []
                    }))
            }));
    }

    public static isInChunkBounds(currentChunk: number, chunkSize: number, globalPage: number): boolean {
        const chunkStartPage = (currentChunk - 1) * chunkSize + 1;
        const chunkEndPage = chunkStartPage + chunkSize - 1;

        return globalPage >= chunkStartPage && globalPage <= chunkEndPage;
    }

    public static getChunkAndChunkPage(globalPage: number, chunkSize: number): [number, number] {
        let chunk = Math.trunc(globalPage / chunkSize);
        let chunkPage = globalPage % chunkSize;
        if (chunkPage > 0) {
            chunk++;
        } else {
            chunkPage = chunkSize;
        }
        return [chunk, chunkPage];
    }

    public static getDateRange(timeLineItems: Array<TimelineItemModel>): SearchesFeature.DateRange<Date> {
        let startDate: Date = new Date('3000-12-10T00:00:00');
        let endDate: Date = new Date('1900-12-10T00:00:00');

        for (let item of timeLineItems) {
            if (item.startDate) {
                if (compare(item.startDate, endDate) > 0) {
                    endDate = item.startDate;
                }
                if (compare(item.startDate, startDate) < 0) {
                    startDate = item.startDate;
                }
            }
        }

        return [startDate, endDate];
    }

    public static getChunkCount(pChunkSize: number, pPageCount: number) {
        let chunkCount = Math.trunc(pPageCount / pChunkSize);
        const page = pPageCount % pChunkSize;
        if (page > 0) {
            chunkCount++;
        }
        return chunkCount;
    }

}
