import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, of, Subscription } from 'rxjs';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter, finalize } from 'rxjs/operators';

import { UserApiService } from '@api/services';

import { AlertService, ModalService } from '@shared/services';
import { ComponentHasPendingChanges } from '@shared/guards';
import { FocusableHostDirective } from '@shared/directives';
import { FormGroupConfig, LoggedUser } from '@shared/models';
import { UserAvatarEditorComponent, ChangePasswordDialogComponent } from '@shared/components';

type FormValueModel = Pick<LoggedUser, 'Email' | 'FirstName' | 'LastName'>;

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'edit-profile-dialog',
    templateUrl: './edit-profile-dialog.component.html',
    styleUrls: ['./edit-profile-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProfileDialogComponent extends DialogContentBase implements OnInit, AfterViewInit, ComponentHasPendingChanges {
    @Input() user: LoggedUser;

    form: FormGroup;

    isCloseAfterSave: boolean = false;

    @ViewChild('firstName')
    private firstNameRef: ElementRef;

    @ViewChild('userAvatarEditor')
    private userAvatarEditor: UserAvatarEditorComponent;

    @ViewChild(FocusableHostDirective) focusableHost: FocusableHostDirective;

    public profileImagePath: string = null;

    isLoading$ = new BehaviorSubject<boolean>(false);
    isSaving$ = new BehaviorSubject<boolean>(false);

    private userProfileModel: LoggedUser;
    private originalUserModel: LoggedUser;
    private isPasswordChanged: boolean = false;

    userIconPath: string = null;

    constructor(
        dialogRef: DialogRef,
        private readonly formBuilder: FormBuilder,
        private readonly alertService: AlertService,
        private readonly router: Router,
        private readonly userApiService: UserApiService,
        private readonly modalService: ModalService,

    ) {
        super(dialogRef);
    }

    //#region HANDLERS

    ngOnInit() {
        // INIT FIELDS
        this.initForm({ Email: this.user.Email, FirstName: this.user.FirstName, LastName: this.user.LastName });

        // INIT SUBSCRIPTIONS
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            untilDestroyed(this)
        ).subscribe((event) => {
            this.close();
        });

        // LOAD DATA
        this.getUserProfile();
    }

    ngAfterViewInit() {
        if (this.firstNameRef) {
            this.firstNameRef.nativeElement?.focus();
        }
        super.ngAfterViewInit();
    }

    onClickChangePassword() {
        this.openChangePasswordModal();
    }

    onSubmit() {

        if (this.form.invalid) {
            return;
        }

        const value: FormValueModel = this.form.value;

        const model: LoggedUser = { ...this.originalUserModel, ...value };

        // this.isSaving$.next(true);
        // of(null).pipe(
        //     delay(3000),
        //     untilDestroyed(this),
        //     finalize(() => this.isSaving$.next(false))
        // ).subscribe(() => {
        //     this.alertService.success(`Profile was updated successfully!`);
        //     this.patchForm(value);
        //     if (this.isCloseAfterSave) {
        //         this.close();
        //     }
        // });
    }

    onClickSave(isClose: boolean = false) {
        this.isCloseAfterSave = isClose;
    }

    onClickClose() {
        this.close();
    }

    onClose(event: Event) {
        event.preventDefault();
        this.close();
    }

    onAvatarUpdated() {
        // TODO: implement
    }

    hasPendingChanges(): boolean {
        return this.form.dirty;
    }

    //#endregion


    //#region API ACTIONS

    private getUserProfile() {
        this.userProfileModel = { ...this.user };
        this.originalUserModel = { ...this.user };
        this.userIconPath = null;
    }

    //#endregion

    //#region UI ACTIONS

    private initForm(value?: FormValueModel) {
        const config: FormGroupConfig<FormValueModel> = {
            Email: [value?.Email || '', [Validators.required, Validators.email]],
            FirstName: [value?.FirstName || '', [Validators.required]],
            LastName: [value?.LastName || '', [Validators.required]],
        };

        this.form = this.formBuilder.group(config);
    }

    private patchForm(value?: Partial<FormValueModel>) {
        this.form.patchValue(value);
        this.form.markAsPristine();
    }

    private openChangePasswordModal() {
        const modalRef = this.modalService.open({ content: ChangePasswordDialogComponent });
        const instance = modalRef.content.instance as ChangePasswordDialogComponent;
        // instance.userId = this.user;
        // modalRef.result.then((isChanged) => {
        //     if (isChanged) {
        //         this.isPasswordChanged = true;
        //     }
        // }).catch(() => { });
    }

    private close() {
        if (this.hasPendingChanges()) {
            const [result$] = this.modalService.unsavedChanges();

            result$.pipe(
                untilDestroyed(this)
            ).subscribe(isDiscard => {
                if (isDiscard) {
                    this.dialog.close();
                }
            });
        } else {
            this.dialog.close();
        }
    }

    //#endregion
}
