const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { Logger, LogLevel } from 'msal';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { MenusModule } from '@progress/kendo-angular-menu';


import { DocumentApiService, UserApiService } from '@api/services';

import { SharedModule } from '@shared';
import { AdminGuard, AuthenticationGuard, LoginGuard } from '@shared/guards';
import { ModalModule } from '@shared/modules';
import { AlertService } from '@shared/services';
import { AuthenticationModule } from '@shared/modules/authentication';
import { IntlService } from '@progress/kendo-angular-intl';

import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { CustomErrorHandler } from './services/handlers';
import { ClientSideAPIInterceptor, HeadersInterceptor, ResponseInterceptor } from './services/interceptors';
import { AppStates } from './store';
import {
    HomeComponent,
    LoginComponent,
    NoContentComponent,
    LogoutComponent,
    EnvironmentConfigComponent
} from './pages';
import {
    EditProfileDialogComponent,
    HeaderComponent,
    CaseInfoComponent
} from './components';
import { PdfModule } from './modules/pdf/pdf.module';
import { DOCUMENT_API_SERVICE } from './modules/cognitive-search/models';
import { DATE_FORMATTER } from './constants';
import { KendoIntlDateFormatterService } from './services';

@NgModule({
    declarations: [
        //ROOT
        AppComponent,
        //PAGES
        HomeComponent,
        LoginComponent,
        NoContentComponent,
        LogoutComponent,
        //COMPONENTS
        HeaderComponent,
        EditProfileDialogComponent,
        CaseInfoComponent,
        EnvironmentConfigComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxsModule.forRoot(AppStates, {
            developmentMode: !environment.production || environment.staging, selectorOptions: { injectContainerState: false }
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            name: 'Arcuity AI Master App',
            disabled: environment.production && !environment.staging,
            maxAge: 100
        }),
        NgxsFormPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        AuthenticationModule.forRoot(),
        MsalModule.forRoot({
            auth: { clientId: environment.oAuth.clientId, authority: environment.oAuth.authority, redirectUri: environment.oAuth.redirectUri },
            cache: { cacheLocation: 'localStorage', storeAuthStateInCookie: isIE },
            system: {
                logger: new Logger((_, message) => console.log('MSASL:Logger', message), {
                    correlationId: '1234',
                    level: !environment.production || environment.staging ? LogLevel.Error : LogLevel.Verbose,
                    piiLoggingEnabled: true,
                }),
            }
        }, {
            popUp: !isIE,
            consentScopes: environment.oAuth.consentScopes,
            unprotectedResources: environment.oAuth.unprotectedResources || [],
            protectedResourceMap: environment.oAuth.protectedResourceMap,
            extraQueryParameters: {},

        }),
        RouterModule.forRoot(ROUTES, { useHash: true, relativeLinkResolution: 'legacy' }),
        SharedModule,
        ModalModule,
        AuthenticationGuard,
        AdminGuard,
        LoginGuard,
        PdfModule,
        NotificationModule,
        MenusModule
    ],
    providers: [
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: (handler: ApiActionHandlerService) => function () { },
        //     deps: [ApiActionHandlerService],
        //     multi: true
        // },
        {
            provide: DATE_FORMATTER,
            useClass: KendoIntlDateFormatterService
        },
        { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ClientSideAPIInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            deps: [Router, AlertService, Store, IntlService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        UserApiService,
        AlertService,
        {
            provide: DOCUMENT_API_SERVICE,
            useExisting: DocumentApiService
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
