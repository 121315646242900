import { Annotation } from "pspdfkit/dist/types/typescript/models/annotations";

export * from './pdf-module-config.model';
export * from './pdf-module-config.token';
export * from './context-menu-item';
export * from './context-menu-click-event-data';
export * from './context-menu-item-type';
export * from './custom-toolbar-types';

export type SerializedAnnotation = Exclude<Annotation, 'createdAt' | 'updatedAt' | 'Rect'> & {
    createdAt: string;
    updatedAt: string;
    boundingBox: {
        left: number;
        top: number;
        width: number;
        height: number;
    };
    [x: string]: any
};
