import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export interface PageNoteModel {
    id: number;
    name: string;
    pageRef: string;
}

export interface PageModel {
    id: number;
    page: number;
    name: string;
    children: Array<PageNoteModel>;
}

export interface DocumentNotesModel {
    id: number;
    name: string;
    children: Array<PageModel>;
}

export interface TreeNodeDataItem<T, ID = number> {
    id: ID;
    text: string;
    data?: T;
    children?: Array<TreeNodeDataItem<T, ID>>;
}

export interface TermRefModel {
    documentSubId: string;
    page: number;
    displayTerm: string;
}

export interface AnnotationData {
    caseId?: string;
    documentSubId: string;
    documentOffset: number;
    createdAt: Date;
    documentChunk?: number;
    globalPage?: number;
    chunkPage?: number;
    annotationId?: string;
    annotationNote?: string;
}

export interface IDocumentApiService {
    loadDocument(documentId: string, documentSubId: string, documentChunk: number, chunkCount: number): Observable<Blob>;
}


export const DOCUMENT_API_SERVICE = new InjectionToken<IDocumentApiService>('DOCUMENT_API_SERVICE');
