import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe, BaseUrlPipe, ShortenPipe } from './pipes';

@NgModule({
    declarations: [
        SafePipe,
        BaseUrlPipe,
        ShortenPipe
    ],
    imports: [
        CommonModule
    ],
    providers: [
        SafePipe,
        BaseUrlPipe,
        ShortenPipe
    ],
    exports: [
        SafePipe,
        BaseUrlPipe,
        ShortenPipe
    ]
})
export class PipesModule { }
