import { Routes } from '@angular/router';

import {
    AdminGuard,
    AuthenticationGuard,
    LoginGuard
} from '@shared/guards';

import {
    EnvironmentConfigComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    NoContentComponent,
} from './pages';

export const ROUTES: Routes = [
    {
        path: '',
        canActivate: [AuthenticationGuard],
        component: HomeComponent,
        pathMatch: 'full',
        data: { defaultTitle: 'Home' }
    },

    {
        path: 'login',
        canActivate: [LoginGuard],
        component: LoginComponent,
        data: { defaultTitle: 'Login' }
    },
    {
        path: 'logout',
        canActivate: [AuthenticationGuard],
        component: LogoutComponent,
        data: { defaultTitle: 'Logout' }
    },
    {
        path: 'administration',
        canActivate: [AuthenticationGuard, AdminGuard],
        loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./modules/cognitive-search/cognitive-search.module').then(m => m.CognitiveSearchModule),
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'xpFMoBLdtqwpZnLeCIQa',
        component: EnvironmentConfigComponent
    },
    {
        path: 'self',
        loadChildren: () => import('./modules/self-contained-pdf-viewer/self-contained-pdf-viewer.module').then(m => m.SelfContainerPdfViewerModule)
    },
    {
        path: '**',
        component: NoContentComponent
    }
];
