import { AbstractControl } from '@angular/forms';

export function notEmptyTextValidator(control: AbstractControl): { [key: string]: any } {
	const value = control.value;
	if (typeof value !== 'string') {
		return null;
	}
	const _cutValue = value.replace(/[\s\n\r]/gm, '');
	if (_cutValue === '') {
		return { 'notEmptyTextValidator': {} };
	}

	return null;
}
