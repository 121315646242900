import { Injectable } from '@angular/core';

@Injectable()
export class GUIDHelper {
    isGuid(stringToTest) {
        if (typeof stringToTest === 'undefined' || stringToTest === null || stringToTest === '') return false;

        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        let regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return stringToTest != '00000000-0000-0000-0000-000000000000' && regexGuid.test(stringToTest);
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
