import { ValidationErrors } from "@angular/forms";

export interface FileValidationErrors extends ValidationErrors {
	['size']?: string;
	['format']?: string;
}

export class FileValidationHelper {
	public static validate(file: File): FileValidationErrors {
		const errors: FileValidationErrors = {};

		if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/jpg' && file.type !== 'image/svg+xml') {
			errors.format = `File type "${file.type}" not allowed`;
		}

		const fileSize = ((file.size / 1024) / 1024); // MB
		if (fileSize > 5) {
			errors.size = `File size exceeds maximum limit 5 MB. Please choose another image.`;
		}

		return errors;
	}
}
