import { Directive, ElementRef, Optional, Host, Self, Inject, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FOUCUSABLE_TOKEN, IFocusable, FocusableItem } from '../models';

import { FocusableHostDirective } from './focusable-host.directive';

@Directive({
    selector: '[formControl], [formControlName]'
})
export class FocusableDirective implements OnInit, OnDestroy {
    private instance: FocusableItem;

    constructor(
        @Self() @Inject(FOUCUSABLE_TOKEN) @Optional() private focusService: IFocusable,
        @Optional() @Host() private form: FocusableHostDirective,
        private elementRef: ElementRef<HTMLElement>,
        private _view: ViewContainerRef,
        ngControl: NgControl,

    ) {
        this.instance = { focus: () => this.focus(), control: ngControl };
    }

    ngOnDestroy(): void {
        this.form.unregister(this.instance);
    }

    ngOnInit(): void {
        this.form.register(this.instance);
    }

    focus() {
        if (this.focusService) {
            this.focusService.focus();
        } else if (this.elementRef?.nativeElement.focus) {
            setTimeout(() => {
                this.elementRef.nativeElement.focus();
            });
            // TODO: HACK:
        } else if (typeof (<any>this._view)._element.component?.focus === 'function') {
            (<any>this._view)._element.component.focus();
        }
    }
}

