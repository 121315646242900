import { Directive, ElementRef } from '@angular/core';

import { fromEvent, Subject, merge } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Directive({
    selector: 'form'
})
export class FormSubmitDirective {
    private readonly manualSubmitSource$ = new Subject();

    submit$ = merge(fromEvent(this.element, 'submit'), this.manualSubmitSource$).pipe(
        tap(() => {
            if (this.element.classList.contains('submitted') === false) {
                this.element.classList.add('submitted');
            }
        }),
        shareReplay(1)
    );

    reset$ = merge(fromEvent(this.element, 'reset').pipe(
        tap(() => {
            if (this.element.classList.contains('submitted') === true) {
                this.element.classList.remove('submitted');
            }
        }),
    ));

    constructor(private host: ElementRef<HTMLFormElement>) { }

    get element() {
        return this.host.nativeElement;
    }

    manualSubmit() {
        this.manualSubmitSource$.next();
    }
}
