import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'baseUrl' })
export class BaseUrlPipe implements PipeTransform {

    public transform(value: string): string {
        return `${environment.apiUrl}/${value}`;
    }
}
