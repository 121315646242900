import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy {
    @Input() term: string = '';
    @Output() termChange: EventEmitter<string> = new EventEmitter();

    constructor() { }

    //#region HANDLERS

    ngOnInit() {
        this.onNgModelChange(this.term);
    }

    ngOnDestroy(): void {
        this.clear();
    }

    onNgModelChange(value: string) {
        this.termChange.emit(value);
    }

    onClickClear() {
        this.clear();
    }

    //#endregion

    //#region  UI ACTIONS

    private clear() {
        this.term = '';
        this.termChange.emit('');
    }

    //#endregion
}
