import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as User from '../models/user';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

//TODO: TEMP MOQ
import MoqData from 'src/moq';

@Injectable()
export class UserApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    getLoggedUser(): Observable<User.UserModel> {
        return of(MoqData['GetLoggedUser']['admin']).pipe(
            delay(500)
        );

        return this.http.get<User.UserModel>('api/User/GetLoggedUser');
    }

    getUserProfile(userId: number): Observable<User.UserProfileModel> {
        return of(MoqData['GetUserProfile'][userId.toString()]).pipe(
            delay(300)
        );
        return this.http.get<User.UserProfileModel>(`api/User/GetUserProfile?${this.encodeQueryData({ UserId: userId })}`);
    }

    updateUserProfile(userProfile: User.UserProfileModel): Observable<any> {
        return of(true).pipe(delay(300));
        return this.http.post<any>('api/User/UpdateUserProfile', userProfile);
    }

    saveAvatar(userId: number, imageFile: User.FileUploaded, clear: boolean = false): Observable<User.UserSaveAvatarResultModel> {
        return of(MoqData['SaveAvatar']).pipe(
            delay(500)
        );
        return this.http.post<User.UserSaveAvatarResultModel>('api/User/SaveAvatar', { UserID: userId, AvatarFile: imageFile, Clear: clear });
    }

    getUsers(searchString?: string): Observable<User.UserListModel> {
        const args: User.GetUsersArgs = { Search: searchString ?? '' };
        return of(MoqData['GetUsers']).pipe(
            delay(400)
        );
        return this.http.get<User.UserListModel>(`api/User/GetUsers?${this.encodeQueryData(args)}`);
    }

    changePassword(args: User.ChangePasswordUserModel): Observable<any> {
        return of(true).pipe(delay(300));
        return this.http.post<any>('api/User/ChangePassword', args);
    }
}
