import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Host, Input, OnInit, Optional, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, merge, NEVER, Observable, of } from 'rxjs';
import { skip } from 'rxjs/operators';

import { ControlErrorComponent } from '../components';
import { getErrorText } from '../models';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';


@UntilDestroy()
@Directive({
    selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnInit {
    @Input() customErrors = {};
    @Input() controlDisplayName: string;
    @Input() hideValidation: boolean = false;

    // Use to avoid triggering validation error when control emits value already after setValue() | pathcValue() functions
    @Input() skipFirstValueChanges: boolean = false;

    ref: ComponentRef<ControlErrorComponent>;
    container: ViewContainerRef;
    submit$: Observable<any>;
    reset$: Observable<any>;

    constructor(
        @Optional() controlErrorContainer: ControlErrorContainerDirective,
        @Optional() @Host() private form: FormSubmitDirective,
        private viewContainerRef: ViewContainerRef,
        private elementRef: ElementRef,
        private resolver: ComponentFactoryResolver,
        private controlDir: NgControl
    ) {
        this.container = controlErrorContainer ? controlErrorContainer.viewContainerRef : viewContainerRef;
        this.submit$ = this.form ? this.form.submit$ : EMPTY;
        this.reset$ = this.form ? this.form.reset$ : EMPTY;
    }

    ngOnInit() {
        if (this.hideValidation) return;

        const isControlDirty$ = this.control.dirty ? of(true) : NEVER;
        const controlValueChanges$ = this.control.valueChanges.pipe(
            skip(this.skipFirstValueChanges ? 1 : 0)
        );

        merge(this.submit$, isControlDirty$, controlValueChanges$).pipe(
            untilDestroyed(this)
        ).subscribe(() => {
            const controlErrors = this.control.errors;
            if (controlErrors) {
                const firstKey = Object.keys(controlErrors)[0];
                const text = this.customErrors[firstKey] || getErrorText(firstKey, controlErrors[firstKey], this.controlDisplayName);

                this.setError(text);
            } else if (this.ref) {
                this.setError(null);
            }
        });

        this.reset$.pipe(
            untilDestroyed(this)
        ).subscribe(() => {
            this.resetError();
        });
    }

    get control() {
        return this.controlDir.control;
    }

    setError(text: string) {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.container.createComponent(factory);
        }

        this.ref.instance.text = text;
    }

    resetError() {
        if (this.ref) {
            this.ref.destroy();
            this.ref = null;
        }
    }

}
