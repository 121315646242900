import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';

import { UserAdminApiService, UserApiService } from '@api/services';

import { AlertService, ModalService } from '@shared/services';
import { ComponentHasPendingChanges } from '@shared/guards';
import { FocusableHostDirective } from '@shared/directives';
import { FormGroupConfig } from '@shared/models';

interface FormValueModel {
    OldPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
}

export interface ChangePasswordDialogResult {
    isChanged: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-change-password-dialog',
    templateUrl: 'change-password-dialog.component.html',
    styleUrls: ['change-password-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordDialogComponent extends DialogContentBase implements OnInit, AfterViewInit, ComponentHasPendingChanges {

    @Input() userId: number;

    @Input() currentUserId: number;

    @ViewChild('oldPasswordInput') oldPasswordInputRef: ElementRef<HTMLInputElement>;

    @ViewChild('newPasswordInput') newPasswordInputRef: ElementRef<HTMLInputElement>;

    @ViewChild(FocusableHostDirective) focusableHostRef: FocusableHostDirective;

    get isCurrentUser() {
        return this.userId === this.currentUserId;
    }
    form: FormGroup;
    isSaving$ = new BehaviorSubject<boolean>(false);
    private isChanged: boolean = false;
    private isCloseAfterSave: boolean = true;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly userAdminApiService: UserAdminApiService,
        private readonly userApiService: UserApiService,
        private readonly alertService: AlertService,
        private readonly modalService: ModalService,
        dialogRef: DialogRef
    ) {
        super(dialogRef);
    }

    //#region HANDLERS

    hasPendingChanges: () => boolean = () => !!this.form.dirty;

    ngOnInit() {
        // INIT FIELDS
        this.initForm();
    }

    ngAfterViewInit() {
        if (this.oldPasswordInputRef?.nativeElement) {
            this.oldPasswordInputRef.nativeElement.focus();
        } else if (this.newPasswordInputRef?.nativeElement) {
            this.newPasswordInputRef.nativeElement.focus();
        }

        super.ngAfterViewInit();
    }

    onSubmit() {
        if (this.form.invalid) {
            this.focusableHostRef?.firstErrorItem?.focus();
            return;
        }

        const value: FormValueModel = this.form.value;
        const model: any = { ...value };

        this.isSaving$.next(true);

        (this.isCurrentUser ? this.userApiService.changePassword({ UserId: this.userId, ...value }) : this.userAdminApiService.changePassword({
            UserId: this.userId,
            NewPassword: value.NewPassword,
            ConfirmPassword: value.ConfirmPassword
        })).pipe(
            untilDestroyed(this),
            finalize(() => this.isSaving$.next(false))
        ).subscribe(() => {
            this.isChanged = true;
            this.alertService.success('Password changed successfully');
            this.patchForm({ OldPassword: '', ConfirmPassword: '', NewPassword: '' });

            if (this.isCloseAfterSave) {
                this.close();
            }
        });
    }

    onClickCancel() {
        this.close();
    }

    onClickSave(isClose: boolean = false) {
        this.isCloseAfterSave = isClose;
    }

    onClose(event: Event) {
        event.preventDefault();
        this.close();
    }

    //#endregion

    //#region UI ACTIONS

    private initForm() {
        const isCurrentUser: boolean = false;
        const config: FormGroupConfig<FormValueModel> = {
            OldPassword: ['', isCurrentUser ? [Validators.required] : []],
            NewPassword: ['', [Validators.required]],
            ConfirmPassword: ['', [Validators.required]]
        };
        const options: AbstractControlOptions = {
            validators: [this.createMatchValidator('NewPassword', 'ConfirmPassword')]
        };
        this.form = this.formBuilder.group(config, options);
    }

    private patchForm(value?: FormValueModel) {
        this.form.patchValue(value);
        this.form.markAsPristine();
    }

    private close() {
        if (this.hasPendingChanges()) {
            const [result$] = this.modalService.unsavedChanges();

            result$.pipe(
                untilDestroyed(this)
            ).subscribe(isDiscard => {
                if (isDiscard) {
                    const result: ChangePasswordDialogResult = { isChanged: this.isChanged };
                    this.dialog.close(result);
                }
            });
        } else {
            const result: ChangePasswordDialogResult = { isChanged: this.isChanged };
            this.dialog.close(result);
        }
    }

    //#endregion


    //#region HELPERS

    private createMatchValidator(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.same) {
                return null;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ same: true });
            } else {
                matchingControl.setErrors(null);
            }
            return null;
        };
    }

    //#endregion
}
