import { Injectable } from "@angular/core";

import { Action, Selector, State, StateContext } from "@ngxs/store";

export namespace PDFPreferencesFeature {
    export const FeatureKey = 'preferences';

    export const SettablePreferencesKeys: ReadonlyArray<keyof SettableStateModel> = ['layoutMode', 'zoom'];

    //#region TYPES

    export interface StateModel {
        // NOTE: keep type as any to allow pdf viewer swap
        zoom: any;
        // NOTE: keep type as any to allow pdf viewer swap
        layoutMode: any;
    }

    export type SettableStateModel = Partial<Pick<StateModel, 'layoutMode' | 'zoom'>>;

    //#endregion


    //#region ACTIONS

    export class SetPreferences {
        public static readonly type = `@${PDFPreferencesFeature.FeatureKey}/SetPreferences`;
        constructor(
            public readonly preferences: SettableStateModel
        ) { }
    }

    export class SetZoom {
        public static readonly type = `@${PDFPreferencesFeature.FeatureKey}/SetZoom`;
        constructor(
            // NOTE: keep type as any to allow pdf viewer swap
            public readonly zoom: any
        ) { }
    }

    export class SetLayoutMode {
        public static readonly type = `@${PDFPreferencesFeature.FeatureKey}/SetLayoutMode`;
        constructor(
            // NOTE: keep type as any to allow pdf viewer swap
            public readonly layoutMode: any
        ) { }
    }

    export class Reset {
        public static readonly type = `@${PDFPreferencesFeature.FeatureKey}/Reset`;
        constructor() { }
    }

    //#endregion
}

@State<PDFPreferencesFeature.StateModel>({
    name: PDFPreferencesFeature.FeatureKey,
    defaults: {
        zoom: null,
        layoutMode: null
    }
})
@Injectable()
export class PDFViewerPreferencesStateService {
    //#region SELECTORS

    @Selector([PDFViewerPreferencesStateService])
    public static zoom<T = any>(state: PDFPreferencesFeature.StateModel) {
        return state.zoom as T;
    }

    @Selector([PDFViewerPreferencesStateService])
    public static layoutMode<T = any>(state: PDFPreferencesFeature.StateModel) {
        return state.layoutMode as T;
    }

    @Selector([PDFViewerPreferencesStateService])
    public static settablePreferences(state: PDFPreferencesFeature.StateModel): PDFPreferencesFeature.SettableStateModel {
        const result: PDFPreferencesFeature.SettableStateModel = {};
        for (let x of PDFPreferencesFeature.SettablePreferencesKeys) {
            if (state[x]) {
                result[x] = state[x];
            }
        }

        return result;
    }

    //#endregion

    //#region REDUCERS

    @Action(PDFPreferencesFeature.SetPreferences)
    public setPreferences(ctx: StateContext<PDFPreferencesFeature.StateModel>, action: PDFPreferencesFeature.SetPreferences) {
        const nextPreferences: Partial<PDFPreferencesFeature.StateModel> = {};

        for (let prop of PDFPreferencesFeature.SettablePreferencesKeys) {
            if (prop in action.preferences) {
                nextPreferences[prop] = action.preferences[prop];
            }
        }

        ctx.setState({ ...ctx.getState(), ...nextPreferences });
    }

    @Action(PDFPreferencesFeature.SetZoom)
    public setZoom(ctx: StateContext<PDFPreferencesFeature.StateModel>, action: PDFPreferencesFeature.SetZoom) {
        ctx.setState({ ...ctx.getState(), zoom: action.zoom });
    }

    @Action(PDFPreferencesFeature.SetLayoutMode)
    public setLayoutMode(ctx: StateContext<PDFPreferencesFeature.StateModel>, action: PDFPreferencesFeature.SetLayoutMode) {
        ctx.setState({ ...ctx.getState(), layoutMode: action.layoutMode });
    }

    @Action(PDFPreferencesFeature.Reset)
    public reset(ctx: StateContext<PDFPreferencesFeature.StateModel>) {
        ctx.setState({
            layoutMode: null,
            zoom: null
        });
    }

    //#endregion
}
