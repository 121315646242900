import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CanComponentDeactivate } from '../models/can-component-deactivate';
import { RouterHistoryTrackerService } from '../services/router-history-tracker.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor(private readonly history: RouterHistoryTrackerService) { }

    canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot) {
        this.history.setGuardInvoked(true);
        if (component?.canDeactivate === undefined) {
            return true;
        } else {
            return component.canDeactivate();
        }
    }
}



