import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, NgZone, ViewChild } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { finalize, first, map } from 'rxjs/operators';

import { FileValidationHelper } from '@shared/helpers';
import { AlertService } from '@shared/services';

import { CropperComponent } from '../cropper/cropper.component';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@UntilDestroy()
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'crop-image-modal',
    templateUrl: './crop-image-modal.component.html',
    styleUrls: ['./crop-image-modal.component.scss']
})
export class CropImageModalComponent extends DialogContentBase {
    @Input() cropOnly: boolean = false;

    onCrop: any;
    onClear: any;
    onClose: any;

    readonly sliderStep: number = 0.5;

    imageUrl: string;

    cropperOptions: any = null;

    @ViewChild(CropperComponent)
    angularCropper: CropperComponent;

    isReady: boolean = false;

    zoomStep: number = 0.1;
    scrollZoomStep: number = 0.1;

    set minZoom(value: number) {
        this._minZoom = value;
    }
    get minZoom(): number {
        return this._minZoom;
    }
    private _minZoom: number;


    set maxZoom(value: number) {
        this._maxZoom = value;
    }
    get maxZoom(): number {
        return this._maxZoom;
    }

    private _maxZoom: number;

    currentZoom: number;

    public loading: boolean = true;
    public isError: boolean = false;

    constructor(
        dialogRef: DialogRef,
        @Inject(DOCUMENT) private document: Document,
        private alertService: AlertService,
        private readonly ngZone: NgZone
    ) {
        super(dialogRef);
        this.onCrop = () => { };
        this.onClear = () => { };
        this.onClose = () => { };
    }

    init(cropperOptions: any = null) {
        let options = {
            dragMode: 'move',
            aspectRatio: NaN,
            restore: false,
            guides: false,
            center: false,
            highlight: false,
            cropBoxMovable: true,
            cropBoxResizable: true,
            movable: true,
            scalable: true,
            zoomable: true,
            toggleDragModeOnDblclick: false,
            minContainerWidth: 600,
            minContainerHeight: 500,
            //minCropBoxWidth: 300,
            //minCropBoxHeight: 300,
            zoomOnWheel: true,
            zoomOnTouch: true
        };

        if (cropperOptions)
            Object.assign(options, cropperOptions);

        this.cropperOptions = options;

        if (!this.imageUrl) {
            this.loading = false;
            this.onUploadImageClick();
        }
    }

    ready() {
        let imgData = this.angularCropper.cropper.getImageData();
        let canvasData = this.angularCropper.cropper.getCanvasData();
        let cropBoxData = this.angularCropper.cropper.getCropBoxData();

        let arW = cropBoxData.width / imgData.naturalWidth;
        let arH = cropBoxData.height / imgData.naturalHeight;

        this.minZoom = arW > arH ? arW : arH;
        this.maxZoom = this.minZoom * 10;
        this.currentZoom = canvasData.width / canvasData.naturalWidth;
        this.zoomStep = (this.maxZoom - this.minZoom) / 10;
        this.scrollZoomStep = this.zoomStep / 10;

        this.angularCropper.image.nativeElement.addEventListener('zoom', (event) => {
            if (event.detail.ratio > this.maxZoom || event.detail.ratio < this.minZoom) {
                event.preventDefault();

                this.currentZoom = event.detail.ratio > this.maxZoom ? this.maxZoom : this.minZoom;
                this.zoomTo(this.currentZoom);

                return;
            }

            this.currentZoom = event.detail.ratio;
        });

        this.zoomTo(this.minZoom);

        this.isReady = true;

        this.loading = false;
        this.isError = false;
    }

    zoomIn() {
        this.currentZoom += this.zoomStep;
        this.zoomTo(this.currentZoom);
    }

    zoomOut() {
        this.currentZoom -= this.zoomStep;
        this.zoomTo(this.currentZoom);
    }

    zoomTo(value: number) {
        this.angularCropper.cropper?.zoomTo(value ? value : this.minZoom);
    }

    onSaveClick() {
        this.loading = true;

        let mimeType = this.dataURLtoMimeType(this.angularCropper.imageDataUrl);
        this.onCrop(this.angularCropper.cropper.getCroppedCanvas().toDataURL(mimeType));
    }

    onCancelClick(): boolean {
        this.dialog.close();
        this.onClose();

        this.loading = false;

        return false;
    }

    onClearClick() {
        // this.loading = true;
        this.imageUrl = null;
        this.onClear();
    }

    onCloseTitleBar(event: Event) {
        event.preventDefault();
        this.dialog.close();
        this.onClose();

        this.loading = false;

        return false;
    }

    onUploadImageClick() {
        let fileInput = this.document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/gif, image/jpeg, image/png, image/svg+xml';

        fromEvent(fileInput, 'change').pipe(
            first(),
            map(event => {
                const target = event.target as HTMLInputElement;
                if (!target) return null;
                if (!target.files) return null;
                if (target.files.length !== 1) return null;

                const file = target.files[0];
                const errors = FileValidationHelper.validate(file);

                if (errors.format) {
                    this.alertService.info(errors.format);
                    return null;
                }

                if (errors.size) {
                    this.alertService.info(errors.size);
                    return null;
                }

                return file;
            }),
            finalize(() => fileInput = null),
            untilDestroyed(this)
        ).subscribe(file => {
            if (!file) return;

            const fr = new FileReader();
            fr.readAsDataURL(file);

            fr.onload = () => {
                this.destroyCropper();
                this.loading = true;
                setTimeout(() => {
                    this.imageUrl = fr.result as string;

                    if (file.type === 'image/svg+xml') {
                        this.onCrop(this.imageUrl);
                        return;
                    }
                });
            };
        });

        fileInput.click();
    }

    onLoadError(event: any) {
        this.loading = false;
        this.isError = true;
    }

    // https://github.com/fengyuanchen/cropper/issues/542#issuecomment-164915609
    private dataURLtoMimeType(dataURL): string {
        let BASE64_MARKER = ';base64,';
        let data;

        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            let parts = dataURL.split(',');
            let contentType = parts[0].split(':')[1];
            data = decodeURIComponent(parts[1]);
        } else {
            let parts = dataURL.split(BASE64_MARKER);
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;

            data = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                data[i] = raw.charCodeAt(i);
            }
        }

        let arr = data.subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
        }

        let mimeType = '';

        switch (header) {
            case "89504e47":
                mimeType = "image/png";
                break;
            case "47494638":
                mimeType = "image/gif";
                break;
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
                mimeType = "image/jpeg";
                break;
            default:
                mimeType = ""; // Or you can use the blob.type as fallback
                break;
        }

        return mimeType;
    }

    private destroyCropper() {
        this.isReady = false;
        this.imageUrl = null;
        this.angularCropper?.cropper?.destroy();
    }
}
