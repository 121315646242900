
export class UrlFormatHelper {
	private static allowedProtocols: string[] = ['mailto:', 'http://', 'https://', 'tel:'];

	public static ensureExternal(url: string): string {
		if (url) {
			for (let i = 0; i < UrlFormatHelper.allowedProtocols.length; i++)
				if (url.startsWith(UrlFormatHelper.allowedProtocols[i]))
					return url;

			if (/^\S+@\S+\.\S+$/.test(url)) {
				return `mailto:${url}`;
			} else if (/^[+]?[0-9]+$/.test(url)) {
				return 'tel:' + url;
			}
			return `http://${url}`;
		}
		return url;
	}
}
