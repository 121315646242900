import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

import { NotificationService, NotificationSettings } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, mergeMap } from 'rxjs/operators';

import { SharedFeature, SharedStateService } from '@shared/store';
import { AlertService, RouterHistoryTrackerService } from '@shared/services';
import { AlertMessage, NotificationType } from '@shared/models';

import { environment } from 'src/environments/environment';



@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

    readonly buildInfo = environment.clientBuildInfo;

    loading: boolean = true;
    @Select(SharedStateService.modulesLoading) isModulesLoading$: Observable<boolean>;

    @ViewChild('notificationSuccess', { read: 'template' }) notificationTemplate: TemplateRef<any>;

    private readonly _messageTypeMap: {
        [key in NotificationType]: 'none' | 'info' | 'success' | 'error' | 'warning'
    } = {
            [NotificationType.Default]: 'none',
            [NotificationType.Info]: 'info',
            [NotificationType.Success]: 'success',
            [NotificationType.Error]: 'error',
            [NotificationType.Warning]: 'warning'
        };

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly titleService: Title,
        private readonly alertService: AlertService,
        private readonly changeDetector: ChangeDetectorRef,
        // NOTE: keep injection
        private readonly historyService: RouterHistoryTrackerService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly store: Store,
        private readonly notificationService: NotificationService
    ) {
        this.router.events.pipe(
            filter(x => x instanceof RouteConfigLoadStart || x instanceof RouteConfigLoadEnd),
            untilDestroyed(this)
        ).subscribe((event: RouteConfigLoadStart | RouteConfigLoadEnd) => {
            this.store.dispatch(new SharedFeature.SetModuleIsLoading({ moduleName: event.route.path, isLoading: event instanceof RouteConfigLoadStart }));
        });
    }

    ngOnInit() {
        this.initServices();
    }

    ngOnDestroy() {

    }

    private initServices() {

        this.alertService.getMessageEvent().pipe(untilDestroyed(this)).subscribe(message => this.showNotification(message, false));

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe((event) => {
            this.titleService.setTitle('Arcuity'/*event['defaultTitle']*/);
        });
    }

    //#region UI ACTIONS

    private showNotification(message: AlertMessage, isSticky: boolean) {
        const config: NotificationSettings = {
            content: message.summary,
            type: { style: this._messageTypeMap[message.type], icon: true },
            // closable: true,
            hideAfter: message.hideAfter || 5000
        };

        const notificationRef = this.notificationService.show(config);
    }

    //#endregion
}
