import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import * as Annotation from '../models/annotation';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class AnnotationApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    save(args: Annotation.SaveAnnotationsModel) {
        return this.http.post<any>('api/Annotation/Save', args);
    }

    load(args: Annotation.LoadAnnotationsModel) {
        return this.http.post<string>('api/Annotation/Load', args);
    }

    loadAll(args: Annotation.LoadAnnotationsModel) {
        return this.http.post<Array<Annotation.AnnotationJsonEntry>>('api/Annotation/LoadAll', args);
    }

    getPatientInformationNew(caseNum: string) {
        return this.http.get<Annotation.GetPatientInformationModel>('api/Annotation/GetPatientInformationNew?' + this.encodeQueryData({ caseNum }));
    }

    createNotesFileNew(args: Annotation.CreateNotesFileArgs): Observable<Blob> {
        return this.http.post('api/Annotation/CreateNotesFileNew', args, { responseType: 'blob' });
    }

    // TODO: #remove
    // getPatientInformation(caseNum: string, authTicket: string) {
    //     return this.http.get<Annotation.GetPatientInformationModel>('api/Annotation/GetPatientInformation?' + this.encodeQueryData({ caseNum, authTicket }));
    // }

    // TODO: #remove
    // createNotesFile(args: Annotation.CreateNotesFileArgs) {
    //     return this.http.post<any>('api/Annotation/CreateNotesFile', args);
    // }

    // TODO: #remove
    // getNotesDocuments() {
    //     return this.http.get<Annotation.GetNotesDocumentsModel>('api/Annotation/GetNotesDocuments');
    // }
}
