import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Timeline from '../models/timeline';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class TimelineApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }
    getAll(caseId: string) {
        return this.http.get<Array<Timeline.TimelineItemModel>>('api/Timeline/GetAll?' + this.encodeQueryData({ caseId }));
    }

    import(args: Array<Timeline.TempTimelineArgs>) {
        return this.http.post<any>('api/Timeline/Import', args);
    }

    createMany(args: Array<Timeline.TimelineArgs>) {
        return this.http.post<any>('api/Timeline/CreateMany', args);
    }

    create(args: Timeline.TimelineArgs) {
        return this.http.post<any>('api/Timeline/Create', args);
    }
}
