import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as Dictionary from '../models/dictionary';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

// TODO: TEMP MOQ
import MoqData from 'src/moq';

@Injectable({ providedIn: 'root' })
export class DictionaryApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    getUserRoles(): Observable<Dictionary.SystemDictionaryModel> {
        return of(MoqData['GetRoles']).pipe(
            delay(300)
        );
        return this.http.get<Dictionary.SystemDictionaryModel>('api/Dictionary/GetRoles');
    }
}
