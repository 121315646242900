import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { SharedStateService, SharedFeature } from '@shared/store';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-redux-search',
    templateUrl: './redux-search.component.html',
    styleUrls: ['./redux-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReduxSearchComponent implements OnInit {

    @Select(SharedStateService.searchTerm) term$: Observable<string>;

    constructor(
        private readonly store: Store
    ) { }

    //#region HANDLERS

    ngOnInit(): void {

    }

    onTermChange(term: string) {
        this.store.dispatch(new SharedFeature.SetSearchTerm(term));
    }

    //#endregion
}
