
import { Injectable, OnDestroy } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { AuthResponse } from 'msal';
import { Observable, Subscription } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AuthTokenStorageService } from '../../services';
import { AuthFeature, AuthStateService } from '../auth-state.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AuthHandlerService implements OnDestroy {

    @Select(AuthStateService.status) private _status$: Observable<AuthFeature.UserLoggedStatus>;

    private readonly _subscriptions: Array<Subscription> = [];

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly authTokenStorageService: AuthTokenStorageService,
        private readonly broadcastService: BroadcastService,
        private readonly authService: MsalService) {
        // console.log('AuthTokenStorageHandlerService');
        // KEEP TOKEN SYNC
        this.actions$.pipe(
            ofActionDispatched(AuthFeature.SetAuthToken),
            untilDestroyed(this)
        ).subscribe((action: AuthFeature.SetAuthToken) => {
            if (action.token) {
                this.authTokenStorageService.setAuthToken(action.token, action.expiration);
            } else {
                this.authTokenStorageService.removeAuthToken();
            }
        });

        const loginSuccessSubscription = broadcastService.subscribe('msal:loginSuccess', (payload) => {
            const requestObj = {
                scopes: ["user.read"]
            };
            this.authService.acquireTokenSilent(requestObj);
        });

        const acquireTokenSuccessSubscription = this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload: AuthResponse) => {
            this.store$.dispatch(new AuthFeature.LoginWithMSTokenAsync(payload.accessToken));
        });

        this._subscriptions.push(loginSuccessSubscription, acquireTokenSuccessSubscription);

        // CLEAR STORAGE ON LOGOUT
        this.actions$.pipe(
            ofActionDispatched(AuthFeature.UserLogout),
            untilDestroyed(this)
        ).subscribe(() => {
            this.authTokenStorageService.removeAuthToken();
        });

        const token = this.authTokenStorageService.getAuthToken();
        const init$ = (token ? this.store$.dispatch(new AuthFeature.LoginWithTokenAsync(token)) : this.store$.dispatch(new AuthFeature.UserLogout()));
        init$.pipe(
            mergeMap(() => this.authTokenStorageService.authToken$),
            withLatestFrom(this._status$.pipe(filter(x => x !== AuthFeature.UserLoggedStatus.Logging))),
            untilDestroyed(this)
        ).subscribe(([_token, status]) => {
            if (_token) {
                if (status === AuthFeature.UserLoggedStatus.LoggedOut) {
                   this.store$.dispatch(new AuthFeature.LoginWithTokenAsync(_token));
                }
            } else {
                if (status === AuthFeature.UserLoggedStatus.LoggedIn) {
                    this.store$.dispatch(new AuthFeature.UserLogout());
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }
}
