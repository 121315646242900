const errors = {
	default: (error, controlName) => `${getControlName(controlName)} is invalid`,

	required: (error, controlName) => `${getControlName(controlName)} is required`,

	min: ({ min, actual }) => `Expected min value ${min} but got ${actual}`,

	max: ({ max, actual }) => `Expected max value ${max} but got ${actual}`,

	maxlength: ({ requiredLength, actualLength }, controlName) => `${getControlName(controlName)} can be max ${requiredLength} characters long`,

	minlength: ({ requiredLength, actualLength }, controlName) => `${getControlName(controlName)} must be at least ${requiredLength} characters long`,

	email: (error, controlName) => `${getControlName(controlName)} is not a valid e-mail address`,

	pattern: ({ requiredPattern, value }) => `Required pattern is "${requiredPattern}"`,

	notEmptyTextValidator: (error, controlName) => `${getControlName(controlName)} is required`
};


export function getErrorText(errorName: string, error: any, controlName?: string): string {
	let getError = errors[errorName] || errors.default;
	return getError(error, controlName);
}

function getControlName(controlName?: string): string {
	return controlName ? controlName : 'This field';
}
