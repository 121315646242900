import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LocalStorageService {
    
    get<T>(key: string): T {
        try {
            const data = localStorage.getItem(key);
            return JSON.parse(data) as T;
        } catch {
            return undefined;
        }
    }

    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    has(key: string): boolean {
        return !!localStorage.getItem(key);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

}

export enum LocalStorageKeysEnum {
    People = 'people',
    Specialties = 'specialties',
    FieldComments = 'fieldComments'
}
