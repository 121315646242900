import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IntlService } from '@progress/kendo-angular-intl';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { catchError, map } from 'rxjs/operators';

import { ApiResult, ErrorApiResult, WebApiResultCodes } from '@api/models';

import * as DateHelpers from '@shared/helpers/dates';
import { AlertService } from '@shared/services';
import { AuthFeature } from '@shared/store';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly alertSvc: AlertService,
        private readonly store$: Store,
        private readonly intlService: IntlService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && this.isNeedToHandleResponse(event)) {
                    let data = this.handleResponse(event.body);
                    return event.clone({
                        body: data
                    });
                }

                return event;
            }),
            catchError(x => this.handleError(x))
        );
    }

    private isNeedToHandleResponse(event: HttpResponse<any>) {
        if (!event.body) return false; // response of "Home/ClientLogError" request

        let header = event.headers.get('Content-Type');
        if (header && header.indexOf('application/json') === -1) return false; // don't need to handle not 'application/json' responses

        return true;
    }

    handleResponse<T>(response: any): Observable<T> | T {
        let result = new ApiResult();
        Object.assign(result, response);

        this.replaceStringToDate(result);

        if (result.ResultCode === WebApiResultCodes.Success) {
            return result.ReturnValue as T;
        }

        return this.handleError(response);
    }

    handleError(errorResponse: any): Observable<any> {
        if (errorResponse.status === 401 || errorResponse.status === 403) {
            // if not authenticated
            this.store$.dispatch(new AuthFeature.UserLogout());
            this.router.navigate(['/login']);
            throw { message: errorResponse, ignoreError: true };
        }

        if (errorResponse.status === 404) {
            //TODO: separate 404 page for API requests should be added and used here
            this.router.navigate(['404']);
            throw { message: errorResponse, ignoreError: true };
        }

        if (errorResponse.status === 503) {
            this.alertSvc.error('Service is not available. Please try again in a few seconds.');
            throw { message: errorResponse, ignoreError: true };
        }

        let result = errorResponse.error ? errorResponse.error : errorResponse;
        throw new ErrorApiResult(result);
    }

    private replaceStringToDate(obj: any) {
        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === 'object') {
                    this.replaceStringToDate(obj[property]);
                } else if (obj[property] !== null && obj[property] !== undefined && typeof obj[property] === 'string' && DateHelpers.isISODateString(obj[property])) {
                    const parsed = this.intlService.parseDate(obj[property]);
                    if (parsed) {
                        delete obj[property];
                        obj[property] = parsed;
                    }
                }
            }
        }
    }
}
