import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { NotificationService, Type } from '@progress/kendo-angular-notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ModalService } from '@shared/services';
import { NotificationType } from '@shared/models';

import { API_DATE_FORMAT, DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

@UntilDestroy()
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
    readonly NotificationType = NotificationType;

    readonly date = new Date();
    apiDate: any;

    constructor(
        private readonly alertService: AlertService,
        private readonly modalService: ModalService,
        @Inject(DATE_FORMATTER) private readonly formatter: DateFormatterService
    ) { }

    //#region HANDLERS

    ngOnInit() {
        // INIT FIELDS
        this.apiDate = this.formatter.format(this.date, API_DATE_FORMAT);
    }

    onClickNotification(type: NotificationType) {
        switch (type) {
            case NotificationType.Info: this.alertService.info('Info Notification'); break;
            case NotificationType.Error: this.alertService.error('Error Notification'); break;
            case NotificationType.Warning: this.alertService.warning('Warning Notification'); break;
            case NotificationType.Success: this.alertService.success('Success Notification'); break;
            case NotificationType.Default:
            default: this.alertService.default('Default Notification');
        }
    }

    onClickOpenModal(type: 'alert' | 'unsaved changes' | 'confirm') {
        switch (type) {
            case 'alert': const [result1$] = this.modalService.alert('Alert Title', 'Alert Message');
                result1$.pipe(untilDestroyed(this)).subscribe(() => this.alertService.default('Dialog Dismissed'));
                break;
            case 'unsaved changes':
                const [result2$] = this.modalService.unsavedChanges('Unsaved Changes Title', 'Unsaved Changes <b>Message</b>');
                result2$.pipe(untilDestroyed(this)).subscribe(isDiscard => isDiscard ? this.alertService.warning('Discarded') : this.alertService.info('Canceled'));
                break;
            case 'confirm':
                const [result3$] = this.modalService.confirm('Confirm Title', 'Confirm Message');
                result3$.pipe(untilDestroyed(this)).subscribe(isConfirmed => isConfirmed ? this.alertService.success('Confirmed') : this.alertService.error('Canceled'));
                break;
        }
    }

    //#endregion
}
