import { Inject, Injectable, Optional, TemplateRef } from '@angular/core';

import { DialogAction, DialogCloseResult, DialogRef, DialogResult, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { finalize, map, mapTo } from 'rxjs/operators';

import { DIALOG_SETTINGS_BASE } from '../constants';
import { DialogContentComponent } from '../components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ModalService implements Pick<DialogService, 'open'> {

    private _settings: DialogSettings = {
        minWidth: 400,
        minHeight: 200,
        actionsLayout: 'normal',
        autoFocusedElement: 'button'
    };

    private _unsavedChangesDialogRef: DialogRef;

    constructor(
        private readonly dialogService: DialogService,
        @Optional() @Inject(DIALOG_SETTINGS_BASE) settings: DialogSettings,
    ) {
        this._settings = { ...this._settings, ...(settings || {}) };
    }

    alert(title: string, message: string, okBtnName: string = 'OK', settings?: DialogSettings): [Observable<true>, DialogRef] {
        const dialogRef = this.createDialog(title, [{ text: okBtnName, primary: true }], settings);
        const instance = dialogRef.content.instance as DialogContentComponent;
        instance.message = message;

        return [dialogRef.result.pipe(mapTo(true)), dialogRef];
    }

    confirm(title: string, message: string, okBtnName: string = 'OK', cancelBtnName: string = 'Cancel', settings?: DialogSettings): [Observable<boolean>, DialogRef] {
        const dialogRef = this.createDialog(title, [{ text: cancelBtnName }, { text: okBtnName, primary: true }], settings);
        const instance = dialogRef.content.instance as DialogContentComponent;
        instance.message = message;

        return [
            dialogRef.result.pipe(map((x => 'primary' in x && !!x.primary))),
            dialogRef
        ];
    }

    unsavedChanges(
        title: string = 'Unsaved changes',
        message: string = 'If you have entered new data on this page and navigate away without saving, your changes will be lost.',
        okBtnName: string = 'Continue',
        cancelBtnName: string = 'Cancel',
        settings?: DialogSettings
    ): [Observable<boolean>, DialogRef] {
        if (this._unsavedChangesDialogRef) {
            this._unsavedChangesDialogRef.close({});
            this._unsavedChangesDialogRef = null;
        }

        const [result$, dialogRef] = this.confirm(title, message, okBtnName, cancelBtnName, settings);

        this._unsavedChangesDialogRef = dialogRef;

        return [
            result$.pipe(finalize(() => {
                this._unsavedChangesDialogRef?.close({});
                this._unsavedChangesDialogRef = null;
            })),
            dialogRef
        ];
    }

    //#region DialogService Implementation

    open(settings: DialogSettings): DialogRef {
        let _settings = { ...settings };

        if (!('preventAction' in _settings)) {
            _settings.preventAction = this.preventAction;
        }

        return this.createDialog(_settings.title, _settings.actions, _settings);
    }

    //#endregion

    //#region HELPERS

    private createDialog(
        title: string,
        actions: DialogAction[] | any[] | TemplateRef<any>,
        settings?: DialogSettings): DialogRef {
        return this.dialogService.open({
            ...this._settings,
            title: title,
            content: DialogContentComponent,
            actions,
            ...(settings || {})
        });

    }

    private preventAction = (ev: DialogResult, dialogRef?: DialogRef) => {
        const hasPendingChanges: boolean =
            ('hasPendingChanges' in dialogRef.content.instance) &&
            dialogRef.content.instance.hasPendingChanges() &&
            (ev instanceof DialogCloseResult || !ev?.primary);

        if (hasPendingChanges) {
            const [result$] = this.unsavedChanges();

            result$.pipe(
                untilDestroyed(this)
            ).subscribe(isDiscard => {
                if (isDiscard) {
                    dialogRef.close({ primary: true });
                }
            });
        }

        return false;
    }

    //#endregion


}
