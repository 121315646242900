export class ValidationError {
    errors: ValidationErrorItem[];    

    constructor(messages: string[]) {
        this.errors = messages.map(m => new ValidationErrorItem(m));
    }
}

export class ValidationErrorItem {   
    constructor(public message: string) {
        
    }
}