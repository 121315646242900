import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EmbeddedViewRef, Input, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { delay, distinctUntilChanged, switchMap } from "rxjs/operators";
import { of, ReplaySubject } from "rxjs";

import { PanelLoaderComponent } from '../components/panel-loader/panel-loader.component';

@UntilDestroy()
@Directive({
    selector: '[blurredLoader]'
})
export class PanelLoaderDirective {
    @Input() blurredLoaderText: string | null = 'Loading...';

    @Input() templateRef: TemplateRef<any>;

    @Input() delay: number | null;

    @Input() set blurredLoader(state: boolean) {
        this._blurredLoaderSource.next(!!state);
    }

    private get hasTemplate() {
        return !!this.templateRef;
    }


    private _componentRef: ComponentRef<PanelLoaderComponent>;

    private _embeddedViewRef: EmbeddedViewRef<any>;
    private _blurredLoaderSource = new ReplaySubject<boolean>(1);

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly _viewContainer: ViewContainerRef,
        private readonly resolver: ComponentFactoryResolver
    ) {
        this._blurredLoaderSource.pipe(
            distinctUntilChanged(),
            switchMap(show => of(show).pipe(
                delay(show ? this.delay || 0 : 0)
            )),
            untilDestroyed(this)
        ).subscribe(show => {
            if (show) {
                this.showLoader();
            } else {
                this.hideLoader();
            }
            this.changeDetector.markForCheck();
        });
    }

    private showLoader() {
        if (this.hasTemplate) {
            if (this._embeddedViewRef) return;
            this._embeddedViewRef = this._viewContainer.createEmbeddedView(this.templateRef);
        } else {
            if (this._componentRef) return;
            this._componentRef = this._viewContainer.createComponent(this.resolver.resolveComponentFactory(PanelLoaderComponent));
        }
    }

    private hideLoader() {
        if (this._componentRef) {
            this._componentRef.destroy();
            this._componentRef = null;
        }

        if (this._embeddedViewRef) {
            this._embeddedViewRef.destroy();
            this._embeddedViewRef = null;
        }
    }
}
