import { Injectable } from "@angular/core";

import { $PropertyType } from "pspdfkit/dist/types/utility-types/dist";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { InstantJSON } from "pspdfkit/dist/types/typescript/lib/InstantJSON";
import { IntlService } from "@progress/kendo-angular-intl";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { tap } from "rxjs/operators";

import { AnnotationJsonEntry, GetPatientInformationModelItem, LoadAnnotationsModel, SaveAnnotationsModel } from "@api/models/annotation";
import { ExecuteArgsModel, ExecutePubMedResultModel, ExecuteResultModel, FetchPubMedDocumentArgsModel, FetchPubMedDocumentResultModel, GetPagesModelDocumentItem, GetPagesModelSubDocumentItem } from "@api/models/new-search";
import { TimelineItemModel } from "@api/models/timeline";
import {
    AnnotationApiService,
    NewSearchApiService,
    TimelineApiService
} from "@api/services";

import { AlertService } from "@shared/services";
import { ApiActionBase, LoggedUser } from "@shared/models";
import { AuthStateService, SharedStateService } from "@shared/store";
import { compare } from "@shared/helpers/dates/compare";
import { createUUID } from "@shared/helpers/UUID";

import { CognitiveSearchDataHelper } from "../../helpers";
import { CognitiveSearchSearchPageName } from "../../constants";
import { DocumentCacheService } from "../../services/document-cache.service";
import { FilterFormValueModel } from "../../components";
import { FormsFeature, FormsStateService } from "./forms-state.service";

export namespace SearchesFeature {
    export const FeatureKey = 'searches';

    //#region TYPES

    export enum CognitiveSearchTabType {
        Search = 1,
        Timeline = 2,
        Notes = 3,
        DepoBuilder = 4
    }

    export interface DepoItemModel {
        Id: string;
        Text: string;
        CreatedDate: Date;
        SelectedText: string;
        DocumentSubId: string;
        GlobalPage: number;
        UserLogin: string;
    }

    export type DateRange<T = Date> = [T, T];

    export interface DocumentPageInfo {
        globalPage: number;
        chunkPage: number;
        chunkSize: number;
        totalPages: number;
    }

    export interface TimelineFilterModel extends Omit<FilterFormValueModel, 'fromDateVal' | 'toDateVal'> {
        fromDate: Date;
        toDate: Date;
    }

    export interface StateModel {
        caseRes: ExecuteResultModel;
        loadedDepos: Array<DepoItemModel>;
        pubMedRes: ExecutePubMedResultModel;
        caseRes2: any;
        documents: Array<GetPagesModelDocumentItem>;
        cases: any[];
        loadedDocument: string;
        loadedDocChunkPage: number;
        loadedDocChunk: number;
        loadedDocPageCount: number;
        loadedDocChunkSize: number;
        loadedDocumentId: string;
        loadedDocTerm: string;
        loadedDocChunkCount: number;
        loadedDirty: any[];
        colWidth: number;
        loadedPatientInformation: Array<GetPatientInformationModelItem>;
        pubMedDocRes: FetchPubMedDocumentResultModel & { documentId: string };
        loadedDocAnnotationMap: Array<AnnotationJsonEntry>;
        loadedDocAnnotation: any;
        isForceReloadAnnotation?: boolean;
        loadedDocumentRecordOffset: number;
        documentOffsets: { [key: string]: number };
        selectedText: [string, number, number];
        loadedTimeline: Array<TimelineItemModel>;
        loadedTimelineDateRange: DateRange<Date>;
        filtersIsOpen: boolean;
        activeCognitiveSearchTab: SearchesFeature.CognitiveSearchTabType;
        forms: FormsFeature.StateModel;
    }

    //#endregion

    //#region ASYNC ACTIONS

    export class GetSearchAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetSearchAsync`;
        constructor(
            public readonly caseName: string,
            public readonly searchTerm: string
        ) { super(); }
    }

    export class GetSearchPubMedAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetSearchPubMedAsync`;
        constructor(
            public readonly searchTerm: string
        ) {
            super();
        }
    }

    export class GetPatientInformationAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetPatientInformationAsync`;
        constructor(
            public readonly caseNum: string
        ) {
            super();
        }
    }

    // TODO: #remove
    // export class GetNotesAsync extends ApiActionBase {
    //     public static readonly type = `@${FeatureKey}/GetNotesAsync`;
    //     constructor() {
    //         super();
    //     }
    // }

    export class GetAnnotationsAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetAnnotationsAsync`;
        constructor(
            public readonly forceReload: boolean = false
        ) {
            super();
        }
    }

    export class GetTimelineAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetTimelineAsync`;
        constructor() {
            super();
        }
    }

    export class GetPubMedDocumentAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetPubMedDocumentAsync`;
        constructor(
            public readonly documentId: string
        ) {
            super();
        }
    }

    export class SaveAnnotation extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/SaveAnnotation`;
        constructor(
            public readonly instantJSON: InstantJSON | string,
            public readonly forceReload: boolean = false
        ) {
            super();
        }
    }

    export class GetDocumentAsync extends ApiActionBase {
        public static readonly type = `@${FeatureKey}/GetDocumentAsync`;
        constructor(
            public readonly document: string,
            public readonly documentChunk: number
        ) {
            super();
        }
    }


    //#endregion

    //#region ACTIONS


    export class SetChunkPage {
        public static readonly type = `@${FeatureKey}/SetChunkPage`;
        constructor(
            public readonly page: number
        ) { }
    }

    export class GoToPageAsync {
        public static readonly type = `@${FeatureKey}/GoToPage`;
        constructor(
            public readonly globalPage: number
        ) { }
    }

    export class SetLoadedDepos {
        public static readonly type = `@${FeatureKey}/SetLoadedDepos`;
        constructor(
            public readonly depos: Array<DepoItemModel>
        ) { }
    }

    export class AddToDepos {
        public static readonly type = `@${FeatureKey}/AddToDepos`;
        constructor(
            public readonly text: string,
            public readonly pageIndex: number,
            public readonly documentSubId: string,
            public readonly selectedText: string,
            public readonly documentChunk: number,
            public readonly userLogin: string
        ) { }
    }

    export class SetLoadedDocChunk {
        public static readonly type = `@${FeatureKey}/SetLoadedDocChunk`;

        constructor(
            public readonly loadedDocChunk: number
        ) { }
    }

    // export class SetLoadedDocTerm {
    //     public static readonly type = `@${FeatureKey}/SetLoadedDocTerm`;

    //     constructor(
    //         public readonly loadedDocTerm: string
    //     ) { }
    // }

    export class SetSelectedText {
        public static readonly type = `@${FeatureKey}/SetSelectedText`;

        constructor(
            public readonly selectedText: [string, number, number]
        ) { }
    }

    export class SetLoadedDocument {
        public static readonly type = `@${FeatureKey}/SetLoadedDocument`;
        constructor(
            public readonly documentSubId: string,
            public readonly page: number,
            public readonly displayTerm: string,
        ) { }

    }

    export class SetLoadedPubMedDocument {
        public static readonly type = `@${FeatureKey}/SetLoadedPubMedDocument`;
        constructor(
            public readonly result: FetchPubMedDocumentResultModel & { documentId: string }
        ) { }

    }

    export class SetInternalNav {
        public static readonly type = `@${FeatureKey}/SetInternalNav`;
        constructor(
            public readonly thePage: number
        ) {
        }

    }

    export class IncrementLoadedChunkAsync {
        public static readonly type = `@${FeatureKey}/IncrementLoadedChunk`;

    }

    export class DecrementLoadedChunk {
        public static readonly type = `@${FeatureKey}/DecrementLoadedChunk`;
    }

    export class IncrementPageAsync {
        public static readonly type = `@${FeatureKey}/IncrementPage`;
        constructor(
            public readonly pageStep: number = 1
        ) { }
    }

    export class DecrementPageAsync {
        public static readonly type = `@${FeatureKey}/DecrementPage`;
        constructor(
            public readonly pageStep: number = 1
        ) { }
    }

    export class SetLoadedTimelineDateRange {
        public static readonly type = `@${FeatureKey}/setLoadedTimelineDateRange`;
        constructor(
            public readonly range: DateRange<Date>
        ) { }

    }

    export class SetColWidth {
        public static readonly type = `@${FeatureKey}/SetColWidth`;
        constructor(
            public readonly colWidth: number
        ) { }

    }

    export class ResetSearch {
        public static readonly type = `@${FeatureKey}/ResetSearch`;
        constructor(
        ) { }
    }

    export class SetFilterIsOpen {
        public static readonly type = `@${FeatureKey}/SetFilterIsOpen`;
        constructor(
            public readonly isOpen: boolean
        ) { }
    }

    export class ToggleFilterIsOpen {
        public static readonly type = `@${FeatureKey}/ToggleFilterIsOpen`;
        constructor() { }
    }

    export class SetActiveCognitiveSearchTab {
        public static readonly type = `@${FeatureKey}/SetActiveCognitiveSearchTab`;
        constructor(
            public readonly tab: CognitiveSearchTabType
        ) { }
    }

    export class SetSelectedNotesIds {
        public static readonly type = `@${FeatureKey}/SetSelectedNotesIds`;
        constructor(
            public readonly ids: Array<any>
        ) { }
    }

    //#endregion

}

@UntilDestroy()
@State<SearchesFeature.StateModel>({
    name: SearchesFeature.FeatureKey,
    defaults: {
        caseRes: null,
        loadedDepos: [],
        pubMedRes: null,
        pubMedDocRes: null,
        caseRes2: {},
        documents: [],
        cases: [],
        loadedDocument: "",
        loadedDocChunkPage: 1,
        loadedDocChunk: 0,
        loadedDocPageCount: 0,
        loadedDocChunkSize: 0,
        loadedDocumentId: "",
        loadedDocTerm: "zzz",
        loadedDocChunkCount: 0,
        loadedDirty: [],
        loadedPatientInformation: [],
        loadedDocAnnotationMap: [],
        loadedDocAnnotation: {},
        loadedDocumentRecordOffset: 0,
        documentOffsets: null,
        selectedText: ["Hello World", 60, 90],
        loadedTimeline: [],
        colWidth: 9,
        loadedTimelineDateRange: null,
        filtersIsOpen: false,
        activeCognitiveSearchTab: SearchesFeature.CognitiveSearchTabType.Search,
        // selectedNotesIds: [],
        forms: null
    },
    children: [FormsStateService]
})
@Injectable()
export class SearchesStateService {

    //#region SELECTORS

    @Selector([SearchesStateService])
    public static filtersIsOpen(state: SearchesFeature.StateModel) {
        return state.filtersIsOpen;
    }

    @Selector([SearchesStateService])
    public static selectedText(state: SearchesFeature.StateModel) {
        return state.selectedText;
    }

    @Selector([SearchesStateService])
    public static caseRes(state: SearchesFeature.StateModel) {
        return state.caseRes;
    }

    @Selector([SearchesStateService])
    public static loadedDepos(state: SearchesFeature.StateModel) {
        return state.loadedDepos;
    }

    @Selector([SearchesStateService.loadedDepos, SearchesStateService.loadedDocument, AuthStateService.user])
    public static filteredDepos(depos: Array<SearchesFeature.DepoItemModel>, loadedDocumentSubId: string, userInfo: LoggedUser) {
        if (userInfo?.Login && loadedDocumentSubId) {
            return depos.filter(x => x.UserLogin === userInfo.Login && x.DocumentSubId === loadedDocumentSubId).reverse();
        }

        return [];
    }

    @Selector([SearchesStateService])
    public static pubMedRes(state: SearchesFeature.StateModel) {
        return state.pubMedRes;
    }

    @Selector([SearchesStateService])
    public static loadedDocument(state: SearchesFeature.StateModel) {
        return state.loadedDocument;
    }

    @Selector([SearchesStateService])
    public static loadedDocumentId(state: SearchesFeature.StateModel) {
        return state.loadedDocumentId;
    }

    @Selector([SearchesStateService])
    public static loadedDocPageCount(state: SearchesFeature.StateModel) {
        return state.loadedDocPageCount;
    }

    @Selector([SearchesStateService])
    public static loadedDocChunkSize(state: SearchesFeature.StateModel) {
        return state.loadedDocChunkSize;
    }

    @Selector([SearchesStateService])
    public static loadedDocChunk(state: SearchesFeature.StateModel) {
        return state.loadedDocChunk;
    }

    @Selector([SearchesStateService])
    public static loadedDocChunkPage(state: SearchesFeature.StateModel) {
        return state.loadedDocChunkPage;
    }

    @Selector([SearchesStateService.loadedDocChunk, SearchesStateService.loadedDocChunkSize, SearchesStateService.loadedDocChunkPage])
    public static globalPage(loadedDocChunk: number, loadedDocChunkSize: number, loadedDocChunkPage: number) {
        return CognitiveSearchDataHelper.getGlobalPage(loadedDocChunk, loadedDocChunkSize, loadedDocChunkPage);
    }

    @Selector([AuthStateService.isDemoMode])
    public static isDemoMode(isDemo: boolean) {
        return isDemo;
    }

    // @Selector([SearchesStateService.loadedDocChunk, SearchesStateService.loadedDocChunkSize, SearchesStateService.loadedDocChunkPage])
    // public static documentPageInfo(state: SearchesFeature.StateModel) {
    //     const result: SearchesFeature.DocumentPageInfo = {
    //         globalPage: SearchesStateService.getGlobalPage(state.loadedDocChunk, state.loadedDocChunkSize, state.loadedDocChunkPage),
    //         chunkSize: state.loadedDocChunkSize,
    //         chunkPage: state.loadedDocChunkPage,
    //         totalPages: state.loadedDocPageCount
    //     };
    //     return result;
    // }

    // TODO: add filtering

    @Selector([SearchesStateService])
    public static isForceReloadAnnotation(state: SearchesFeature.StateModel) {
        return state.isForceReloadAnnotation;
    }

    @Selector([SearchesStateService.loadedDocAnnotationMap, SearchesStateService.isForceReloadAnnotation])
    public static loadedDocumentInstantJSON(annotationMap: Array<AnnotationJsonEntry>, isForce: boolean) {
        return (documentSubId: string, documentChunk: number): [InstantJSON, boolean] => {
            if (!documentChunk || !documentSubId || !annotationMap) {
                return [null, !!isForce];
            }

            const key = documentSubId + '_' + documentChunk;

            const value = annotationMap.find(x => x.Key === key);
            if (!value) {
                return [null, !!isForce];
            }

            const instantJSON: InstantJSON = JSON.parse(value.InstantJSON);

            return [instantJSON, !!isForce] as [InstantJSON, boolean];
        };
    }

    @Selector([SearchesStateService])
    public static loadedDocTerm(state: SearchesFeature.StateModel) {
        return state.loadedDocTerm;
    }

    @Selector([SearchesStateService])
    public static documents(state: SearchesFeature.StateModel) {
        return state.documents;
    }

    @Selector([SearchesStateService])
    public static loadedPatientInformation(state: SearchesFeature.StateModel) {
        return state.loadedPatientInformation;
    }

    @Selector([SearchesStateService])
    public static documentsOffset(state: SearchesFeature.StateModel) {
        return state.documentOffsets;
    }

    @Selector([AuthStateService.user])
    public static cases(user: LoggedUser) {
        return user?.Cases || [];

    }

    @Selector([SearchesStateService])
    public static loadedDocAnnotationMap(state: SearchesFeature.StateModel) {
        return state.loadedDocAnnotationMap;
    }

    // @Selector([SearchesStateService])
    // public static loadedDocAnnotation(state: SearchesFeature.StateModel) {
    //     return state.loadedDocAnnotation;
    // }

    @Selector([SearchesStateService])
    public static pubMedDocRes(state: SearchesFeature.StateModel) {
        return state.pubMedDocRes;
    }

    @Selector([SearchesStateService])
    public static loadedDocumentRecordOffset(state: SearchesFeature.StateModel) {
        return state.loadedDocumentRecordOffset;
    }

    @Selector([SearchesStateService])
    public static colWidth(state: SearchesFeature.StateModel) {
        return state.colWidth;
    }

    @Selector([SearchesStateService])
    public static loadedTimelineDateRange(state: SearchesFeature.StateModel) {
        return state.loadedTimelineDateRange;
    }

    @Selector([SearchesStateService])
    public static loadedTimeline(state: SearchesFeature.StateModel) {
        return state.loadedTimeline;
    }

    @Selector([SearchesStateService.timelineFilter, SearchesStateService.loadedTimeline])
    public static filteredTimeline(filter: SearchesFeature.TimelineFilterModel, items: Array<TimelineItemModel>) {
        let result = items || [];

        const typeMap: {
            [key in $PropertyType<TimelineItemModel, 'theType'>]: boolean
        } = {
            'General Administration Record': filter.generalRecord,
            'Laboratory Reports': filter.laboratoryReports,
            'Medical Reports': filter.medicalReports,
            'Miscellaneous': filter.miscellaneous
        };

        if (!filter.includeMissingDates) {
            result = result.filter(x => !!x.startDate);
        }

        if (filter.fromDate) {
            result = result.filter(x => !x.startDate || compare(x.startDate, filter.fromDate) >= 0);
        }


        if (filter.toDate) {
            result = result.filter(x => !x.endDate || compare(x.endDate, filter.toDate) <= 0);
        }

        result = result.filter(x => typeMap[x.theType]);

        result.sort((a, b) => (filter.sortDescending ? -1 : 1) * compare(a.startDate, b.startDate, true));

        return result;
    }

    @Selector([
        FormsStateService.fromDateVal,
        FormsStateService.toDateVal,
        FormsStateService.generalRecord,
        FormsStateService.medicalReports,
        FormsStateService.laboratoryReports,
        FormsStateService.miscellaneous,
        FormsStateService.sortDescending,
        FormsStateService.includeMissingDates,
    ])
    public static timelineFilter(
        fromDateVal: Date,
        toDateVal: Date,
        generalRecord: boolean,
        medicalReports: boolean,
        laboratoryReports: boolean,
        miscellaneous: boolean,
        sortDescending: boolean,
        includeMissingDates: boolean
    ): SearchesFeature.TimelineFilterModel {
        const isDateRangeValid = SearchesStateService.isDateRangeValid(fromDateVal, toDateVal);

        return {
            fromDate: isDateRangeValid ? fromDateVal : null,
            toDate: isDateRangeValid ? toDateVal : null,
            generalRecord,
            medicalReports,
            laboratoryReports,
            miscellaneous,
            sortDescending,
            includeMissingDates,
        };
    }

    @Selector([SearchesStateService])
    public static documentOffsets(state: SearchesFeature.StateModel) {
        return state.documentOffsets;
    }

    @Selector([SearchesStateService.loadedDocAnnotationMap, SearchesStateService.documentOffsets])
    public static documentNotes(map: any, offset: any) {
        return CognitiveSearchDataHelper.mapInputIntoTreeNode(map, offset)[0];
    }

    @Selector([SearchesStateService.activeCognitiveSearchTabInternal, SharedStateService.isPageActive(CognitiveSearchSearchPageName)])
    public static activeCognitiveSearchTab(tab: SearchesFeature.CognitiveSearchTabType, isPage: boolean) {
        return isPage ? tab : null;
    }

    // @Selector([SearchesStateService])
    // public static selectedNotesIds(state: SearchesFeature.StateModel) {
    //     return state.selectedNotesIds;
    // }

    @Selector([SearchesStateService])
    private static activeCognitiveSearchTabInternal(state: SearchesFeature.StateModel) {
        return state.activeCognitiveSearchTab;
    }

    //#endregion

    constructor(
        private readonly alertService: AlertService,
        private readonly annotationApiService: AnnotationApiService,
        private readonly newSearchApiService: NewSearchApiService,
        private readonly timelineApiService: TimelineApiService,
        private readonly documentCacheService: DocumentCacheService,
        private readonly intlService: IntlService
    ) {

    }

    //#region EFFECTS

    @Action(SearchesFeature.GetSearchAsync)
    public getSearchEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetSearchAsync) {
        const args: ExecuteArgsModel = {
            AuthTicket: null,
            CaseName: action.caseName,
            DocumentType: "Case",
            Conditions: [
                {
                    Terms: action.searchTerm,
                    MatchType: "",
                    SearchType: "",
                    WordsApartCount: 10
                }
            ]
        };
        return this.newSearchApiService.execute(args).pipe(
            tap(response => {

                if (response.ResultsCount === 0) {
                    this.alertService.info('The search returned 0 results');
                    ctx.setState({ ...ctx.getState(), caseRes: response });
                } else {

                    const theLoadedDoc = response.Documents[0].SubDocuments[0];
                    const page = theLoadedDoc.Pages[0];
                    const pageRef = CognitiveSearchDataHelper.getChunkAndChunkPage(page.Page, theLoadedDoc.ChunkSize);

                    ctx.setState({
                        ...ctx.getState(),
                        caseRes: response,
                        documents: response.Documents,
                        //TODO: merge with setLoadedDocument
                        loadedDocument: theLoadedDoc.DocumentSubId,
                        loadedDocumentId: response.Documents[0].DocumentId,
                        loadedDocPageCount: theLoadedDoc.DocumentSubId === "AI2523-01" ? 3477 : theLoadedDoc.PageCount,
                        loadedDocChunkCount: CognitiveSearchDataHelper.getChunkCount(theLoadedDoc.ChunkSize, theLoadedDoc.PageCount),
                        loadedDocTerm: page.Terms[0].DisplayTerm,
                        loadedDocChunk: pageRef[0],
                        loadedDocChunkSize: theLoadedDoc.ChunkSize,
                        loadedDocChunkPage: pageRef[1],
                        documentOffsets: this.getDocumentOffsets(response.Documents),
                        loadedDocumentRecordOffset: theLoadedDoc.RecordOffset,
                    });
                }
            })
        );
    }

    @Action(SearchesFeature.GetPatientInformationAsync)
    public getPatientInformationEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetPatientInformationAsync) {
        ctx.setState({ ...ctx.getState(), loadedPatientInformation: [] });

        return this.annotationApiService.getPatientInformationNew(action.caseNum).pipe(
            tap(response => {
                ctx.setState({ ...ctx.getState(), loadedPatientInformation: response.Items });
            })
        );
    }

    @Action(SearchesFeature.GetAnnotationsAsync)
    public getAnnotationsEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetAnnotationsAsync) {
        const stateSnapshot1 = ctx.getState();
        const args: LoadAnnotationsModel = {
            DocumentId: stateSnapshot1.loadedDocumentId,
            DocumentSubId: stateSnapshot1.loadedDocument,
            DocumentChunk: stateSnapshot1.loadedDocChunk,
            DocumentType: 'Case',
            AuthTicket: null
        };
        return this.annotationApiService.loadAll(args).pipe(
            tap(response => {
                const stateSnapshot2 = ctx.getState();
                const key = stateSnapshot2.loadedDocument + "_" + stateSnapshot2.loadedDocChunk;
                const annotation = stateSnapshot2.loadedDocAnnotationMap[key];

                ctx.setState({
                    ...stateSnapshot2,
                    loadedDocAnnotationMap: response,
                    loadedDocAnnotation: annotation ? JSON.parse(annotation) : {},
                    isForceReloadAnnotation: action.forceReload
                });
            })
        );
    }


    // TODO: #remove
    // @Action(SearchesFeature.GetNotesAsync)
    // public getNotesEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetNotesAsync) {
    //     const args: CreateNotesFileArgs = {
    //         Tree: this.processNotes(ctx.getState().loadedDocAnnotationMap),
    //         DocumentId: ctx.getState().loadedDocumentId,
    //         InjuredWorker: "Darth Vader",
    //         DateOfBirth: "12/10/1969"
    //     };
    //     const fileName = `${ctx.getState().loadedDocumentId}.docx`;

    //     return this.annotationApiService.createNotesFileNew(args);
    // }

    @Action(SearchesFeature.GetTimelineAsync)
    public getTimelineEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetTimelineAsync) {
        const theTimeline = ctx.getState().loadedDocumentId;
        return this.timelineApiService.getAll(theTimeline).pipe(
            tap(response => {
                const [startDate, endDate] = CognitiveSearchDataHelper.getDateRange(response);

                ctx.setState({ ...ctx.getState(), loadedTimelineDateRange: [startDate, endDate], loadedTimeline: response });

                ctx.dispatch(new UpdateFormValue({
                    value: { fromDateVal: startDate || null, toDateVal: endDate || null },
                    path: `${SearchesFeature.FeatureKey}.${FormsFeature.FeatureKey}.timelineFilterForm`
                }));
            })
        );
    }

    @Action(SearchesFeature.GetSearchPubMedAsync)
    public getSearchPubMedEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetSearchPubMedAsync) {
        return this.newSearchApiService.executePubMed({ Terms: action.searchTerm }).pipe(
            tap(response => {
                ctx.setState({ ...ctx.getState(), pubMedRes: response });
            })
        );
    }

    @Action(SearchesFeature.GetPubMedDocumentAsync)
    public getPubMedDocumentEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetPubMedDocumentAsync) {
        const args: FetchPubMedDocumentArgsModel = {
            DocumentId: action.documentId,
            WebEnv: 'NCID_1_1181128_130.14.22.76_9001_1595545154_499521162_0MetA0_S_MegaStore',
            QueryKey: "1",
        };
        return this.newSearchApiService.fetchPubMedDocument(args).pipe(
            tap(response => {
                ctx.setState({ ...ctx.getState(), pubMedDocRes: { ...response, documentId: action.documentId } });
            })
        );
    }

    @Action(SearchesFeature.SaveAnnotation)
    public saveAnnotationEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SaveAnnotation) {
        const state = ctx.getState();
        const args: SaveAnnotationsModel = {
            Json: typeof action.instantJSON === 'string' ? action.instantJSON : JSON.stringify(action.instantJSON),
            DocumentId: SearchesStateService.loadedDocumentId(state),
            DocumentChunk: SearchesStateService.loadedDocChunk(state),
            DocumentRecordOffset: SearchesStateService.loadedDocumentRecordOffset(state),
            DocumentPageCount: SearchesStateService.loadedDocPageCount(state),
            DocumentType: 'Case',
            DocumentSubId: SearchesStateService.loadedDocument(state),
            AuthTicket: null
        };

        return this.annotationApiService.save(args).pipe(
            tap(response => {
                ctx.dispatch(new SearchesFeature.GetAnnotationsAsync(action.forceReload));
            })
        );
    }

    @Action(SearchesFeature.GetDocumentAsync)
    public getDocumentEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GetDocumentAsync): Observable<Blob> {
        return this.documentCacheService.retrieveFromWebOrCache(action.document, null, action.documentChunk, null);
    }

    @Action(SearchesFeature.GoToPageAsync)
    public goToPageEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.GoToPageAsync) {
        const state = ctx.getState();

        if (action.globalPage <= 0 || action.globalPage > state.loadedDocPageCount) {
            return;
        }

        const [chunk, chunkPage] = CognitiveSearchDataHelper.getChunkAndChunkPage(action.globalPage, state.loadedDocChunkSize);

        return ctx.dispatch(new SearchesFeature.GetDocumentAsync(state.loadedDocument, chunk)).pipe(
            tap(() => ctx.setState({ ...state, loadedDocChunkPage: chunkPage, loadedDocChunk: chunk }))
        );
    }

    @Action(SearchesFeature.IncrementPageAsync)
    public incrementPageEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.IncrementPageAsync) {
        const state = ctx.getState();
        const nextChunkPage = state.loadedDocChunkPage + action.pageStep;
        const nextGlobalPage = CognitiveSearchDataHelper.getGlobalPage(state.loadedDocChunk, state.loadedDocChunkSize, nextChunkPage);

        if (CognitiveSearchDataHelper.isInChunkBounds(state.loadedDocChunk, state.loadedDocChunkSize, nextGlobalPage)) {
            ctx.setState({ ...state, loadedDocChunkPage: nextChunkPage });
        } else if (state.loadedDocChunk < state.loadedDocChunkCount) {
            return ctx.dispatch(new SearchesFeature.GetDocumentAsync(state.loadedDocument, state.loadedDocChunk + 1)).pipe(
                tap(() => ctx.setState({ ...state, loadedDocChunk: state.loadedDocChunk + 1, loadedDocChunkPage: 1 }))
            );
        }
    }

    @Action(SearchesFeature.DecrementPageAsync)
    public decrementPageEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.DecrementPageAsync) {
        const state = ctx.getState();
        const nextChunkPage = state.loadedDocChunkPage - action.pageStep;
        const nextGlobalPage = CognitiveSearchDataHelper.getGlobalPage(state.loadedDocChunk, state.loadedDocChunkSize, nextChunkPage);

        if (CognitiveSearchDataHelper.isInChunkBounds(state.loadedDocChunk, state.loadedDocChunkSize, nextGlobalPage)) {
            ctx.setState({ ...state, loadedDocChunkPage: nextChunkPage });
        } else if (state.loadedDocChunk > 1) {
            return ctx.dispatch(new SearchesFeature.GetDocumentAsync(state.loadedDocument, state.loadedDocChunk - 1)).pipe(
                tap(() => ctx.setState({ ...state, loadedDocChunk: state.loadedDocChunk - 1, loadedDocChunkPage: state.loadedDocChunkSize }))
            );
        }
    }

    @Action(SearchesFeature.IncrementLoadedChunkAsync)
    public incrementLoadedChunkEffect(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.IncrementLoadedChunkAsync) {
        const state = ctx.getState();
        if (state.loadedDocChunk < state.loadedDocChunkCount) {
            return ctx.dispatch(new SearchesFeature.GetDocumentAsync(state.loadedDocument, state.loadedDocChunk + 1)).pipe(
                tap(() => ctx.setState({ ...state, loadedDocChunk: state.loadedDocChunk + 1, loadedDocChunkPage: 1 }))
            );
        }
    }

    @Action(SearchesFeature.DecrementLoadedChunk)
    public decrementLoadedChunk(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.DecrementLoadedChunk) {
        const state = ctx.getState();
        if (state.loadedDocChunk > 1) {
            return ctx.dispatch(new SearchesFeature.GetDocumentAsync(state.loadedDocument, state.loadedDocChunk - 1)).pipe(
                tap(() => ctx.setState({ ...state, loadedDocChunk: state.loadedDocChunk - 1, loadedDocChunkPage: state.loadedDocChunkSize }))
            );
        }
    }

    @Action(SearchesFeature.SetLoadedDocument)
    public setLoadedDocument(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetLoadedDocument) {
        const state = ctx.getState();

        if (!state.documents?.length) return;

        let theLoadedDoc: GetPagesModelSubDocumentItem = null;
        for (let docItem of state.documents) {
            for (let subDocItem of docItem.SubDocuments) {
                if (subDocItem.DocumentSubId === action.documentSubId) {
                    theLoadedDoc = subDocItem;
                    break;
                }
            }
            if (theLoadedDoc) {
                break;
            }
        }

        const [chunk, page] = CognitiveSearchDataHelper.getChunkAndChunkPage(action.page, theLoadedDoc.ChunkSize);

        return ctx.dispatch(new SearchesFeature.GetDocumentAsync(theLoadedDoc.DocumentSubId, chunk)).pipe(
            tap(() => ctx.setState({
                ...state,
                loadedDocument: theLoadedDoc.DocumentSubId,
                loadedDocumentId: theLoadedDoc.DocumentId,
                loadedDocPageCount: theLoadedDoc.DocumentSubId === "AI2523-01" ? 3477 : theLoadedDoc.PageCount,
                loadedDocChunkCount: CognitiveSearchDataHelper.getChunkCount(theLoadedDoc.ChunkSize, theLoadedDoc.PageCount),
                loadedDocChunkSize: theLoadedDoc.ChunkSize,
                loadedDocTerm: action.displayTerm,
                loadedDocChunkPage: page,
                loadedDocChunk: chunk
            }))
        );
    }



    //#endregion

    //#region REDUCERS

    @Action(SearchesFeature.ResetSearch)
    public resetSearch(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.ResetSearch) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            caseRes: null,
            documents: [],
            loadedDocument: null,
            loadedDocumentId: null,
            loadedDocPageCount: 0,
            loadedDocChunkCount: 0,
            loadedDocTerm: '',
            loadedDocChunk: 0,
            loadedDocChunkSize: 0,
            loadedDocChunkPage: 1,
            documentOffsets: {},
            loadedDocumentRecordOffset: 0,
            loadedPatientInformation: [],
            loadedDocAnnotationMap: [],
            loadedDocAnnotation: {},
            pubMedRes: null,
            cases: [],
            loadedTimeline: [],
            loadedTimelineDateRange: null,
            filtersIsOpen: false,
            activeCognitiveSearchTab: SearchesFeature.CognitiveSearchTabType.Search,
        });
    }

    @Action(SearchesFeature.SetFilterIsOpen)
    public setFilterIsOpen(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetFilterIsOpen) {
        ctx.setState({ ...ctx.getState(), filtersIsOpen: action.isOpen });
    }

    @Action(SearchesFeature.ToggleFilterIsOpen)
    public toggleFilterIsOpen(ctx: StateContext<SearchesFeature.StateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, filtersIsOpen: !state.filtersIsOpen });
    }

    @Action(SearchesFeature.SetLoadedDepos)
    public setLoadedDepos(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetLoadedDepos) {
        ctx.setState({ ...ctx.getState(), loadedDepos: action.depos || [] });
    }

    @Action(SearchesFeature.SetChunkPage)
    public setChunkPage(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetChunkPage) {
        const state = ctx.getState();
        if (SearchesStateService.loadedDocChunkPage(state) === action.page) {
            return;
        }

        ctx.setState({ ...state, loadedDocChunkPage: action.page });
    }

    @Action(SearchesFeature.AddToDepos)
    public addToDepos(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.AddToDepos) {
        const state = ctx.getState();
        let target: GetPagesModelSubDocumentItem;
        for (let document of state.documents) {
            for (let subDocument of document.SubDocuments) {
                if (subDocument.DocumentSubId === action.documentSubId) {
                    target = subDocument;
                    break;
                }
            }
            if (target) {
                break;
            }
        }

        if (!target) {
            return;
        }

        ctx.setState({
            ...state,
            loadedDepos:
                [...state.loadedDepos, {
                    Id: createUUID(),
                    Text: action.text,
                    SelectedText: action.selectedText,
                    DocumentSubId: action.documentSubId,
                    UserLogin: action.userLogin,
                    GlobalPage: CognitiveSearchDataHelper.getGlobalPage(action.documentChunk, target.ChunkSize, action.pageIndex + 1),
                    CreatedDate: new Date(),
                }]
        });
    }

    // @Action(SearchesFeature.SetLoadedDocChunk)
    // public setLoadedDocChunk(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetLoadedDocChunk) {
    //     ctx.setState({ ...ctx.getState(), loadedDocChunk: action.loadedDocChunk });
    // }

    // @Action(SearchesFeature.SetLoadedDocTerm)
    // public setLoadedDocTerm(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetLoadedDocTerm) {
    //     ctx.setState({ ...ctx.getState(), loadedDocTerm: action.loadedDocTerm });
    // }

    @Action(SearchesFeature.SetSelectedText)
    public setSelectedText(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetSelectedText) {
        ctx.setState({ ...ctx.getState(), selectedText: action.selectedText });
    }

    @Action(SearchesFeature.SetLoadedTimelineDateRange)
    public setLoadedTimelineDateRange(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetLoadedTimelineDateRange) {
        ctx.setState({ ...ctx.getState(), loadedTimelineDateRange: action.range });
    }


    // TODO: #remove
    @Action(SearchesFeature.SetInternalNav)
    public setInternalNav(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetInternalNav) {
        const thePageRef = CognitiveSearchDataHelper.getChunkAndChunkPage(action.thePage, ctx.getState().loadedDocChunkSize);
        ctx.setState({
            ...ctx.getState(),
            loadedDocChunk: thePageRef[0],
            loadedDocChunkPage: thePageRef[1]
        });
    }

    @Action(SearchesFeature.SetLoadedPubMedDocument)
    public setLoadedPubMedDocument(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetLoadedPubMedDocument) {
        ctx.setState({ ...ctx.getState(), pubMedDocRes: action.result });
    }

    @Action(SearchesFeature.SetColWidth)
    public setColWidth(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetColWidth) {
        ctx.setState({ ...ctx.getState(), colWidth: action.colWidth });
    }

    @Action(SearchesFeature.SetActiveCognitiveSearchTab)
    public setActiveCognitiveSearchTab(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetActiveCognitiveSearchTab) {
        ctx.setState({ ...ctx.getState(), activeCognitiveSearchTab: action.tab });
    }

    // @Action(SearchesFeature.SetSelectedNotesIds)
    // public setSelectedNotesIds(ctx: StateContext<SearchesFeature.StateModel>, action: SearchesFeature.SetSelectedNotesIds) {
    //     ctx.setState({ ...ctx.getState(), selectedNotesIds: action.ids || [] });
    // }

    //#endregion

    //#region HELPERS

    private getDocumentOffsets(documents: Array<GetPagesModelDocumentItem>): { [key in string]: number } {
        return [].concat(...documents.map(x => x.SubDocuments)).reduce((agg, next) => {
            agg[next.DocumentSubId] = next.RecordOffset;
            return agg;
        }, {} as any);
    }

    //#region HELPERS

    // tslint:disable-next-line: member-ordering
    private static isDateRangeValid(start: Date, end: Date): boolean {
        return start && end && compare(start, end, true) < 0;
    }

    //#endregion

}
