import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as mime from 'mime';

import { FileValidationHelper } from '@shared/helpers';
import { AlertService, ModalService } from '@shared/services';

import { FileUploaded, UserSaveAvatarResultModel } from '@api/models/user';
import { UserApiService } from '@api/services';

import { CropImageModalComponent } from '../crop-image-modal/crop-image-modal.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'user-avatar-editor',
    templateUrl: './user-avatar-editor.component.html',
    styleUrls: ['./user-avatar-editor.component.scss']
})
export class UserAvatarEditorComponent {
    @Input()
    private userId: number;

    @Input()
    firstName: string;

    @Input()
    lastName: string;

    @Input()
    userIconPath: string;

    @Input() disabled: boolean = false;


    @Output()
    avatarUpdated: EventEmitter<number> = new EventEmitter(null);

    @Output()
    avatarPathUpdated: EventEmitter<string> = new EventEmitter(null);

    @Output()
    fileChange: EventEmitter<FileUploaded> = new EventEmitter();

    @Output()
    clearImage: EventEmitter<string> = new EventEmitter();

    clearAvatarLoader: boolean = false;

    private userAvatarImageFileModel: FileUploaded = null;

    @ViewChild('imageUpload')
    private imageUploadCtrl: ElementRef;

    constructor(
        private readonly userApiSvc: UserApiService,
        private readonly modalService: ModalService,
        private readonly alertService: AlertService
    ) { }

    userImageUploadEvent(evt: any) {
        if (!evt.target) { return null; }
        if (!evt.target.files) { return null; }
        if (evt.target.files.length !== 1) { return null; }

        const file: File = evt.target.files[0];

        const errors = FileValidationHelper.validate(file);

        if (errors.format) {
            this.alertService.info(errors.format);
            return null;
        }

        if (errors.size) {
            this.alertService.info(errors.size);
            return null;
        }

        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
            if (file.type === 'image/svg+xml') {
                this.buildImageFileModelAndSave(fr.result);
                return;
            }

            const modalRef = this.modalService.open({ content: CropImageModalComponent });
            modalRef.content.instance.cropOnly = true;
            modalRef.content.instance.imageUrl = fr.result;
            modalRef.content.instance.init();
            modalRef.content.instance.onCrop = (data) => {
                this.buildImageFileModelAndSave(data, () => modalRef.content.instance.onCancelClick());
            };
            modalRef.content.instance.onClose = () => {
                this.imageUploadCtrl.nativeElement.value = null;
            };
        };
    }

    private buildImageFileModelAndSave(data, callback = null) {
        const base64 = data.split(',')[1];
        const mediaType = data.split(';')[0].split(':')[1];

        const binary = atob(base64);
        const buffer: number[] = [];
        for (let i = 0; i < binary.length; i++) {
            buffer.push(binary.charCodeAt(i));
        }

        this.userAvatarImageFileModel = new FileUploaded();
        this.userAvatarImageFileModel.FileName = `image.${mime.getExtension(mediaType)}`;
        this.userAvatarImageFileModel.MediaType = mediaType;
        this.userAvatarImageFileModel.Buffer = buffer;
        this.fileChange.emit(this.userAvatarImageFileModel);
        //this.userAvatarImageFileModel.Base64 = data.split(',')[1];

        this.saveUserAvatar(this.userId)
            .subscribe(() => {
                this.userIconPath = data;
                if (callback) callback();
            });
    }

    saveUserAvatar(userId: number): Observable<boolean> {
        let resultObservable = of<boolean>(false);

        if (userId && this.userAvatarImageFileModel) {
            resultObservable = this.userApiSvc
                .saveAvatar(userId, this.userAvatarImageFileModel).pipe(
                    map((data: UserSaveAvatarResultModel) => {
                        this.userAvatarImageFileModel = null;

                        this.avatarPathUpdated.emit(data.IconPath);

                        if (this.avatarUpdated && this.avatarUpdated.observers.length) {
                            this.avatarUpdated.emit(userId);
                        }

                        return true;
                    })
                );
        }

        return resultObservable;
    }

    private clearUserImageEditor() {
        this.userIconPath = null;
        this.imageUploadCtrl.nativeElement.value = null;
        this.userAvatarImageFileModel = null;
    }

    onClearUserImageClick() {
        let resultObservable = of<boolean>(false);
        this.clearImage.emit(this.userIconPath);
        this.clearAvatarLoader = true;

        if (this.userId) {
            resultObservable = this.userApiSvc
                .saveAvatar(this.userId, null, true).pipe(
                    map(() => {
                        this.avatarPathUpdated.emit('');

                        if (this.avatarUpdated && this.avatarUpdated.observers.length) {
                            this.avatarUpdated.emit(this.userId);
                        }

                        return true;
                    })
                );
        }

        resultObservable.subscribe(() => {
            this.clearUserImageEditor();
            this.clearAvatarLoader = false;
        });
    }
}
