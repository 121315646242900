import { AbstractControl, ValidatorFn } from '@angular/forms';

export function typeofValidator(type: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {      
        if (control.value && typeof(control.value) !== type) {
            return {'typeofValidator': {}};
        }
        return null;
    }
}
