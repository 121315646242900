import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

import MoqData from 'src/moq';

@Injectable({ providedIn: 'root' })
export class BuildInfoApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    getBuildInfoString() {
        return of(MoqData['GetBuildInfoString']).pipe(
            delay(400)
        );
        return this.http.get<string>('api/BuildInfo/GetBuildInfoString ');
    }
}
