import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthStateService } from '@shared/store';

import { API_DATE_FORMAT, DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor(
        private readonly store$: Store,
        @Inject(DATE_FORMATTER) private readonly dateFormatter: DateFormatterService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.toUpperCase().startsWith('HTTP')/* && !request.url.toUpperCase().startsWith(ENV_CONFIG.API_HOST.toUpperCase())*/)
            return next.handle(request);

        if (request.method === 'POST' && request.body && request.body !== undefined) {
            request = request.clone({
                body: this.processRequestBody(request.body)
            });
        }

        if (!request.headers.has('Content-Type') && (!request.headers.has('Type') || request.headers.get('Type') !== 'multipart/form-data')) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            });
        }

        const token = this.store$.selectSnapshot(AuthStateService.token);
        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }

    private processRequestBody(obj: any): void {
        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === 'object') {
                    if (obj[property] instanceof Date) {
                        let date: Date = obj[property];
                        delete obj[property];
                        obj[property] = this.dateFormatter.format(date, API_DATE_FORMAT);
                    } else {
                        this.processRequestBody(obj[property]);
                    }
                }
            }
        }
    }
}
