import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { NotificationType } from '@shared/models';
import { AlertService, ModalService } from '@shared/services';

import { WebApiResultCodes } from '@api/models';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error: any) {
        const alertService: AlertService = this.injector.get(AlertService);
        const modalService: ModalService = this.injector.get(ModalService);
        const zone: NgZone = this.injector.get(NgZone);

        let message = error.message || error.toString();

        if (error.ErrorMessage)
            message = error.ErrorMessage;

        let displayErrorInModal = false;
        if (error.ResultCode && error.ResultCode === WebApiResultCodes.ArgumentValidationFailed)
            displayErrorInModal = true;

        zone.run(() => {
            console.error(error);
            // TODO: trace error & remove temp solution
            const isIgnoreError = !!error['ignoreError'] || error?.toString() === 'PSPDFKitError: `Configuration#container` must either be a valid element or a CSS selector';
            if (!isIgnoreError) {
                if (displayErrorInModal) {
                    modalService.alert('An error occurred', message, 'Close');
                } else {
                    alertService.error(`An error occurred ${message}`);
                }
            }
        })
    }
}
