import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as NewLogin from '../models/new-login';
import { BaseApiService } from './base-api.service';
import { DATE_FORMATTER } from 'src/app/constants';
import { DateFormatterService } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class NewLoginApiService extends BaseApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(DATE_FORMATTER) formatter: DateFormatterService) {
        super(formatter);
    }

    vueLogin(args: NewLogin.NewLoginModel) {
        return this.http.post<NewLogin.NewLoginReturnModel>('api/NewLogin/VueLogin', args);
    }

    vueLoginPassed(args: NewLogin.NewLoginPassedModel) {
        return this.http.post<NewLogin.NewLoginReturnModel>('api/NewLogin/VueLogin', args);
    }

    edexLogin(args: NewLogin.EdexLoginArgs) {
        return this.http.post<NewLogin.EdexLoginResultModel>('api/NewLogin/EdexLogin', args);
    }

    msLogin(args: NewLogin.MSLoginArgs) {
        return this.http.post<NewLogin.NewLoginReturnModel>('api/NewLogin/MSLogin', args);
    }

    getLoginInfo() {
        return this.http.get<NewLogin.NewLoginReturnModel>('api/NewLogin/getLoginInfo');
    }
}
