import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NotificationModule } from '@progress/kendo-angular-notification';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

import {
    CropperComponent,
    CropImageModalComponent,
    UserAvatarEditorComponent,
    SearchComponent,
    TextItemComponent,
    ImageSelectComponent,
    ChangePasswordDialogComponent,
    ReduxSearchComponent,
    PanelLoaderComponent
} from './components';
import { GUIDHelper } from './helpers';
import { DirectivesModule, PipesModule, FormValidationModule } from './modules';
import { SharedHandlers } from './store/handlers';
import { PanelLoaderDirective } from './directives';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        // NgbModule,
        RouterModule,
        DirectivesModule,
        PipesModule,
        FormValidationModule,
        NotificationModule,
        LabelModule,
        LayoutModule,
        DialogModule,
        ButtonsModule,
        IndicatorsModule,
        InputsModule
    ],
    declarations: [
        CropperComponent,
        CropImageModalComponent,
        UserAvatarEditorComponent,
        SearchComponent,
        TextItemComponent,
        ImageSelectComponent,
        ChangePasswordDialogComponent,
        ReduxSearchComponent,
        PanelLoaderDirective,
        PanelLoaderComponent
    ],
    providers: [
        GUIDHelper,

    ],
    exports: [
        // NgbModule,
        DirectivesModule,
        PipesModule,
        CropImageModalComponent,
        UserAvatarEditorComponent,
        SearchComponent,
        TextItemComponent,
        ChangePasswordDialogComponent,
        ImageSelectComponent,
        FormValidationModule,
        ReduxSearchComponent,
        LayoutModule,
        LabelModule,
        IntlModule,
        DateInputsModule,
        InputsModule,
        DropDownsModule,
        DialogModule,
        ButtonsModule,
        IndicatorsModule,
        PanelLoaderDirective
    ],
    entryComponents: [
        CropImageModalComponent
    ]
})

export class SharedModule {
    constructor(
        injector: Injector,
        @Optional() @SkipSelf() parentModule?: SharedModule
    ) {

        if (!parentModule) {
            for (let handler of SharedHandlers) {
                injector.get(handler);
            }
        }
    }

}
