
export class SortHelper {
	public static ensureSort<T>(items: Array<T>, compareFn: (a: T, b: T) => number): void {
		let isSorted = true;
		for (let i = 1; i < items.length; i++) {
			if (compareFn(items[i - 1], items[i]) < 0) {
				isSorted = false;
				break;
			}
		}
		items.sort(compareFn);
	}
}
