import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-no-content',
    templateUrl: './no-content.component.html',
    styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent {
    constructor(private location: Location) {

    }

    onBackClick(): void {
        this.location.back();
    }
}
