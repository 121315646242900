import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';

import { LocalStorageService } from '@shared/services';

import { PDFPreferencesFeature, PDFViewerPreferencesStateService } from '../states';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PDFPreferencesStorageHandlerService {
    public static readonly StorageKey: string = 'PDF_VIEWER_PREFERENCES';

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly localStorage: LocalStorageService
    ) {
        if (this.localStorage.has(PDFPreferencesStorageHandlerService.StorageKey)) {
            const preferences = this.localStorage.get<PDFPreferencesFeature.SettableStateModel>(PDFPreferencesStorageHandlerService.StorageKey);
            this.store$.dispatch(new PDFPreferencesFeature.SetPreferences(preferences));
        }

        this.actions$.pipe(
            ofActionCompleted(
                PDFPreferencesFeature.Reset,
                PDFPreferencesFeature.SetPreferences,
                PDFPreferencesFeature.SetLayoutMode),
            untilDestroyed(this)
        ).subscribe(() => {
            const preferences = this.store$.selectSnapshot(PDFViewerPreferencesStateService.settablePreferences);
            this.localStorage.set(PDFPreferencesStorageHandlerService.StorageKey, preferences);
        });
    }
}
