import { Injectable, NgModule } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthFeature, AuthStateService } from '@shared/store';

@NgModule()
@Injectable()
export class AuthenticationGuard implements CanActivate {

    @Select(AuthStateService.status) status$: Observable<AuthFeature.UserLoggedStatus>;

    constructor(
        private readonly router: Router,
        private readonly store$: Store) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.status$.pipe(
            filter(x => x !== AuthFeature.UserLoggedStatus.Logging),
            map(status => {
                if (status !== AuthFeature.UserLoggedStatus.LoggedIn) {
                    this.store$.dispatch(new AuthFeature.SetRedirectUrl(state.url?.indexOf('logout') >= 0 ? null : state.url));
                    return this.router.parseUrl('login');
                }
                return true;
            })
        );
    }
}
