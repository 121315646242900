
import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { Actions, getActionTypeFromInstance, Store } from '@ngxs/store';
import { ActionStatus } from '@ngxs/store/src/actions-stream';

import { ApiActionBase } from '../../models/api-action-base';
import { SharedFeature } from '../states/shared-state.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ApiActionHandlerService {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store) {

        this.actions$.pipe(
            filter(({ action }: { action: Object }) => action instanceof ApiActionBase),
            untilDestroyed(this)
        ).subscribe(({ action, status }: { action: Object, status: ActionStatus }) => {
            this.store.dispatch(new SharedFeature.SetApiLoading({ actionTypes: [getActionTypeFromInstance(action)], isLoading: status === ActionStatus.Dispatched }));
        });
    }
}
