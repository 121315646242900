import { NgModule } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { DialogContentComponent } from './components';

@NgModule({
    imports: [
        DialogModule
    ],
    declarations: [
        DialogContentComponent,
    ]
})
export class ModalModule { }
